import 'leaflet-draw';
import "leaflet-draw/dist/leaflet.draw.css";
import html2canvas from "html2canvas";

export function captureMap(map: L.Map, points: L.LatLng[]): Promise<string> {
    return new Promise(resolve => {
        html2canvas(map.getContainer(), {
            useCORS: true, // 底图跨域 必须！！
            // allowTaint:false
        }).then((canvas) => {
            let bounds: any = map.getBounds(),
                zero: any = [bounds._northEast.lat, bounds._southWest.lng],
                // 计算当前 视窗内的 原点经纬度 ==> 对应的屏幕坐标 （地图位移及缩放时计算 startPoint的偏移量）必须！！！
                zeroPoint = map.latLngToLayerPoint(zero)
            let startPoint = map.latLngToLayerPoint(points[1]), // latlng 转 屏幕坐标 计算 起点及宽高
                endPoint = map.latLngToLayerPoint(points[3]),
                width = Math.abs(startPoint.x - endPoint.x),
                height = Math.abs(startPoint.y - endPoint.y);
            let capture_width = width, capture_height = height;
            let capture_x = startPoint.x - zeroPoint.x;
            let capture_y = startPoint.y - zeroPoint.y;
            //加上自定义的WMS地图



            // 创建一个用于截取的canvas
            var clipCanvas = document.createElement('canvas')
            clipCanvas.width = capture_width
            clipCanvas.height = capture_height
            // 截取图片
            clipCanvas.getContext('2d').drawImage(canvas, capture_x, capture_y, capture_width, capture_height, 0, 0, capture_width, capture_height)
            var clipImgBase64 = clipCanvas.toDataURL() // 生成图片url
            resolve(clipImgBase64);
        });
    });
}

export function drawRectangle(map: L.Map) {
    return new Promise((resolve, reject) => {
        let rectangle = new L.Draw.Rectangle(map as any, {
            shapeOptions: {
                stroke: true, color: 'red', weight: 2, opacity: 0.9, fill: true, fillColor: null, /*same as color by default*/
                fillOpacity: 0.1
            }
        });
        rectangle.enable(); //绘制矩形
        map.once(L.Draw.Event.CREATED, (e: any) => {
            if (e.layerType == 'rectangle') {
                map.addLayer(e.layer);
                if (window.confirm('是否下载本次截图')) {
                    let latlngs = e.layer._latlngs[0];
                    map.removeLayer(e.layer); // 移除框选的矩形
                    resolve(latlngs);
                }
                else
                    reject();
            }
        });
        map.once(L.Draw.Event.DRAWSTOP, (e) => {
            rectangle.disable()
        });
    });
}