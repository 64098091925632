import { Component, createRef } from 'react';
import backendMethod from '../API/backend';
import Highcharts from "highcharts";
import { Modal } from "bootstrap";
import { fruitDanger2  } from "../API/disarter";
import * as ReactDOM from 'react-dom';
import Enumerable from 'linq';
//植物生育期气候监测
export default class reproduction extends Component<{ map: L.Map, mobile?: boolean, packInstance?: { instance?: reproduction } }>{
    private selectDays = 3;
    private fruitDanger = fruitDanger2();
    private selectFruit = Enumerable.from(this.fruitDanger).first(t => t.code == "lichee");
    private reprodutionLat = createRef<HTMLElement>();
    private reprodutionLng = createRef<HTMLElement>();
    private reproductionRefStationSpan = createRef<HTMLElement>();
    private reprodutionChartDiv = createRef<HTMLDivElement>();
    private cardbody = createRef<HTMLDivElement>();
    private weatherConceive = createRef<HTMLDivElement>();
    private popModal: Modal;
    private popModalContainerId = "modals4popcontent";

    public componentDidMount(): void {   
        this.props.map.on("click", (evt) => {
            this.popMessage(evt.latlng.lng, evt.latlng.lat);
        });
        if (this.cardbody.current) this.cardbody.current.onclick = e => {
            this.popMessage(114.009771, 22.549519);
        }
        if (this.props.packInstance)
            this.props.packInstance.instance = this;
    }
    public popMessage(lng: number, lat: number) {
        backendMethod.getNeighborhoodStation(lng, lat).then(obtcode => {
            if (obtcode) {
                this.close();
                let ppp = document.querySelector("#" + this.popModalContainerId);
                if (ppp) ppp.parentElement.removeChild(ppp);
                var popcontent = document.createElement("div") as HTMLDivElement;
                popcontent.id = this.popModalContainerId;
                ReactDOM.render(this.getRanderDom(), popcontent, () => {

                    if (this.props.mobile) {
                        popcontent.style.marginTop = "35px";
                        popcontent.style.height = (window.innerHeight - 35) + "px";
                        popcontent.className = "modal";
                        this.popModal = new Modal(popcontent, { backdrop: false });
                        this.popModal.show();
                    }
                    else {
                        (this.props.map as any).fixedPopwindow = true;
                        L.popup({ keepInView: true, maxWidth: this.props.mobile ? window.innerWidth : 1000, className: "customDarkPopup" }).setLatLng(L.latLng(lat, lng))
                            .setContent(popcontent).openOn(this.props.map).on("remove", (e) => { (this.props.map as any).fixedPopwindow = false; });
                    }
                    this.fillPopContent(obtcode);
                });
            }
        });
    }
    private swiftForm() {
        let carbody = this.weatherConceive.current.querySelector(".card-body") as HTMLDivElement;
        let dash = this.weatherConceive.current.querySelector(".card-header .bi-dash") as HTMLSpanElement;
        if (dash) {
            carbody.style.display = "none";
            dash.className = "bi bi-app";
        } else {
            dash = this.weatherConceive.current.querySelector(".card-header .bi-app") as HTMLSpanElement;
            carbody.style.display = "";
            dash.className = "bi bi-dash";
        }
    }
    public render() {
        return <>
        </>
        if (this.props.mobile) {
            return <>
            </>
        }
        else return <><div ref={this.weatherConceive} className="card" style={{ color: "#fff", width: 275, position: 'absolute', bottom: 10, left: 280, zIndex: 520, background: "#0E77D3" }}>
            <div className="card-header">
                农作物生育期与气候影响
                <span style={{ float: "right", fontSize: 18 }} onClick={e => this.swiftForm()} className='bi bi-dash'></span>
            </div>
            <div className="card-body" ref={this.cardbody} style={{ background: "#001A35", cursor: "pointer" }}>
                <p className="card-text">
                    <label style={{ cursor: '' }}><img src='images/lichee.png'></img>荔枝</label> &nbsp;&nbsp;
                    <label style={{ cursor: '' }}><img src='images/rice.png'></img>水稻</label> &nbsp;&nbsp;
                    <label style={{ cursor: '' }}><img src='images/longan.png'></img>龙眼</label> &nbsp;&nbsp;
                    <br />
                    <label style={{ cursor: '' }}><img src='images/potato.png'></img>油菜</label>
                    <label style={{ cursor: '' }}><img src='images/potato.png'></img>柑桔</label>
                    &nbsp;&nbsp;<span style={{ fontStyle: "italic", fontSize: 15 }}>地图点击查看</span> <i className="bi bi-signpost-fill"></i>
                </p>
            </div>
        </div>
        </>
    }
    private fillPopContent(obt: iOBTCODE) {
        this.reprodutionLat.current.innerText = obt.LAT.toFixed(5);
        this.reprodutionLng.current.innerText = obt.LON.toFixed(5);
        this.reproductionRefStationSpan.current.innerText = obt.OBTNAME + "(" + obt.OBTID + ")";
        var start = new Date(), end = new Date();
        start.setDate(start.getDate() - this.selectDays);
        document.getElementsByName("reproductionTime").forEach(t => {
            let e = t as HTMLInputElement;
            if (+e.value == this.selectDays) e.checked = true;
            e.onchange = ce => {
                this.selectDays = +(ce.target as HTMLInputElement).value;
                start = new Date();
                start.setDate(start.getDate() - this.selectDays);
                this.showChart(obt.OBTID, start, end);
            }
        });
        document.getElementsByName("reproductionFruit").forEach(t => {
            let e = t as HTMLInputElement;
            if (e.value == this.selectFruit.code) e.checked = true;
            e.onchange = ce => {
                let scode = (ce.target as HTMLInputElement).value;
                this.selectFruit = Enumerable.from(this.fruitDanger).first(t => t.code == scode || t.code1 == scode);
                this.showChart(obt.OBTID, start, end);
            }
        });
        this.showChart(obt.OBTID, start, end);
    }
    private close(mouseevent?: any) {
        this.props.map.closePopup();
        if (this.popModal) this.popModal.hide();
    }
    public getRanderDom() {
        return <div className="modal-dialog" style={{ width: this.props.mobile ? window.innerWidth : 800, backgroundColor: "#132948", margin: 0, height: this.props.mobile ? (window.innerHeight - 35) : "" }}>
            <div className="modal-content" style={{ color: "#fff" }}>
                <div className="modal-header" style={{ padding: 5, backgroundColor: "#144373", borderBottomColor: "gray", textAlign: 'center' }}>
                    <div style={{ display: 'inline-block' }}><i className="bi bi-geo-alt-fill" style={{ color: "#fff" }}></i>
                        经度：<b style={{ color: "#FECD06" }} ref={this.reprodutionLat}></b> 纬度：<b ref={this.reprodutionLng} style={{ color: "#FECD06", marginRight: 10 }}></b>
                        <div style={{ display: 'inline-block' }}>关联站点：<span ref={this.reproductionRefStationSpan}></span></div>
                        <div style={{ display: 'inline-block', fontSize: 12, color: "#A0A4A7" }}>&nbsp;&nbsp;点击地图更换站点</div>
                    </div>
                </div>
                <div className="modal-body" style={{ background: "#132A49" }}>
                    <div style={{ zIndex: 2, margin: 10, display: 'inline-block' }}>时间：
                        <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="reproductionTime" value={3} id="btnradioreproduction1" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="btnradioreproduction1">过去3天</label>
                            <input type="radio" className="btn-check" name="reproductionTime" value={5} id="btnradioreproduction2" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="btnradioreproduction2">过去一候(5天)</label>
                        </div></div>
                    <div style={{ zIndex: 2, margin: 10, display: 'inline-block' }}>
                        作物：{this.getFruitDanger() }
                    </div>
                    <div ref={this.reprodutionChartDiv} style={{ marginTop: 10, height: this.props.mobile ? "" : 350 }}>
                    </div>
                    <input type='button' value="关闭" onClick={this.close.bind(this)} className='btn btn-primary' style={{ width: "100%", margin: 3 }}></input>
                </div>
            </div>
        </div>
    }

    private getFruitDanger() {
        return <div className="btn-group  btn-group-sm" role="group" aria-label="Basic radio toggle button group">
            {
                this.fruitDanger.map((fruit, idx) => (
                    <>
                        <input
                            id={(fruit.vegetable ? 'btnradio' + fruit.code1 : 'btnradio' + fruit.code)}
                            className='btn-check'
                            type="radio"
                            value={(fruit.vegetable ? fruit.code1 : fruit.code)}
                            name='reproductionFruit'
                            autoComplete='off'
                        />
                        <label className="btn btn-outline-primary"
                            htmlFor={(fruit.vegetable ? 'btnradio' + fruit.code1 : 'btnradio' + fruit.code)}>
                            {fruit.name}</label>
                    </>
                ))
            }</div>
    }
    private showChart(obtId: string, start: Date, end: Date) {
        backendMethod.getSZObtHourd(obtId, start, end).then(items => {
            let chartOptions: any = this.getSearial(this.selectFruit, start, end, items);
            Highcharts.chart(this.reprodutionChartDiv.current, chartOptions);
        });
    }

    private getSearial(fruit: typeof this.selectFruit, start: Date, endt: Date, items: iLOCALOBTHOURD[]) {
        let r01hseries: [number, number][] = [];
        let tempSeries: [number, number][] = [];
        Enumerable.from(items).orderBy(t => t.DDATETIME).forEach((t) => {
            r01hseries.push([t.DDATETIME.getTime(), t.R01H > -100 ? t.R01H : NaN]);
            tempSeries.push([t.DDATETIME.getTime(), t.T > -100 ? t.T : NaN]);
        });
        let myPlotLines: { value: number, dashStyle: string, color?: string, label: any, width?: number }[] = [];
        var warnText = "";
        if (fruit && fruit.temperature)
            for (var w of fruit.temperature) {
                myPlotLines.push({ value: w.value, dashStyle: "DashDot", color: "#f00", width: 1, label: { text: w.text } });
                warnText += w.vegatable ? w.name + w.warnMsg : w.warnMsg + "<br/>";
            }
        return {
            credits: { enabled: false },
            title: {
                style: {
                    color: '#fff',
                    font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
                }, text: fruit.name + "生育期气候监测"
            },
            subtitle: {
                style: {
                    color: '#fff',
                    font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
                }, text: start.toStr("yyyy-MM-dd HH:mm至") + endt.toStr("yyyy-MM-dd HH:mm")
            },
            chart: {
                type: 'spline', spacing: 1, backgroundColor: "#122A46",
                style: { color: "#fff" }
            },
            colorAxis: {
                tickColor: "#f00"
            },
            yAxis: [
                {
                    title: { text: this.props.mobile ? "" : "小时累计雨量mm" }, labels: { style: { color: "#fff" } }, lineWidth: 0, lineColor: "gray", gridLineColor: "#6E6D6C", opposite: true
                }, {
                    title: { text: this.props.mobile ? "" : "实时温度℃" }, labels: { style: { color: "#fff" } }, lineWidth: 0, lineColor: "gray", gridLineColor: "#6E6D6C",
                    plotLines: myPlotLines, min: 0
                }
            ],
            xAxis: {
                title: { text: '' },
                labels: { style: { color: "#fff" }, format: '{value:%I}' },
                type: "datetime",
                tickInterval: 60 * 60 * 1000
            },
            plotOptions: {
                spline: {
                    lineWidth: 3,
                    states: {
                        hover: {
                            lineWidth: 4
                        }
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            legend: {
                enabled: true,
                layout: 'horizontal', title: { style: { color: "#fff" } }, itemStyle: { color: "#fff" }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y}%\r\n{point.x}',
                pointFormatter: function (this: any) { return `${this.y + this.series.options.unit}<br/>` + new Date(this.x).toStr("dd日HH时mm分") + "<br/><br/>" + warnText }
            },
            series: [{
                name: '小时累计雨量', unit: "mm",
                type: "column",
                data: r01hseries
            }, {
                name: '实时温度', unit: "℃",
                type: "spline", yAxis: 1,
                data: tempSeries
            }]
        };
    }
}