import { Component, createRef } from 'react';
import backendMethod from './API/backend';
import query from "./util/myquery";
import { Modal } from "bootstrap";
import * as ReactDOM from 'react-dom';
import ReactClient from 'react-dom/client';
import Highcharts from "highcharts";
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import { Tooltip } from 'react-tooltip'
import leafletMap from "./comonent/leafletMap";
import { fruitDanger2  } from "./API/disarter";
import Slider from "bootstrap-slider"
import SZWarnning from "./comonent/szWarnning";
import Advice from "./comonent/Advice";
import Enumerable from 'linq';
import Toshenshan from "./comonent/toShenshan";
import { ColorBarMobile, ColorBarPC } from './comonent/wstationColorbar';
import * as captureFunc from "./util/captruemap";
import DdatePicker from "./comonent/ddatePicker";


//农气预报
export default class forecast extends Component {
    private T2MTemperature = createRef<HTMLInputElement>();
    private highTemperature = createRef<HTMLInputElement>();
    private lowTemperature = createRef<HTMLInputElement>();
    private rainRadio = createRef<HTMLInputElement>();
    private windRadio = createRef<HTMLInputElement>();
    private humRadio = createRef<HTMLInputElement>();
    private preRadio = createRef<HTMLInputElement>();
    private mapContent = createRef<HTMLDivElement>();
    private datetimeInput: HTMLInputElement;
    private feedForecastControlDiv = createRef<HTMLDivElement>();
    private fruitFeedForecastDiv = createRef<HTMLDivElement>();
    private feedForecastChartDiv = createRef<HTMLDivElement>();
    private forecastTimeLabel = createRef<HTMLLabelElement>();
    private numerForecastPanel = createRef<HTMLDivElement>();
    private playControlDiv = createRef<HTMLDivElement>();
    private captureBut = createRef<HTMLInputElement>();
    private advice = createRef<Advice>();
    
    private map: L.Map;
    private forecastStartTime: Date;
    private dataImageOverlaySZ: L.ImageOverlay;
    private dataImageOverlaySS: L.ImageOverlay;
    private circleLayerGroup: L.FeatureGroup;

    private fruitDanger = fruitDanger2();
    private selectFruit = Enumerable.from(this.fruitDanger).first(t => t.code == "lichee");
    private forecastFeed = {
        T2M: { field: "T2M", startHour: 1, maxHour: 48, step: 1 },
        MAXT: { field: "MAXT", startHour: 1, maxHour: 48, step: 1 },
        MINT: { field: "MINT", startHour: 1, maxHour: 48, step: 1 },
        TP: { field: "TP", startHour: 1, maxHour: 48, step: 1 },
        GUST: { field: "GUST", startHour: 1, maxHour: 48, step: 1 },
        RH2M: { field: "RH2M", startHour: 1, maxHour: 48, step: 1 },
        MSL: { field: "MSL", startHour: 1, maxHour: 48, step: 1 }
    }
    private selectFeed = this.forecastFeed.T2M;
    private playInterval: number;
    public constructor(props: {}) {
        super(props);
        this.forecastStartTime = new Date();
        this.forecastStartTime.setDate(this.forecastStartTime.getDate() - 1);
        this.forecastStartTime.setHours(20, 0, 0, 0);
    }
    public componentDidMount() {
        this.map = leafletMap(this.mapContent.current, true, 11, "bottomright", "bottomright");
        backendMethod.getShenzhenOBTCODE().then(this.drawWarnCircleInMap.bind(this));
        this.buildSlider(this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step);
        this.showImageOnMap(this.selectFeed.field, this.forecastStartTime, this.selectFeed.startHour);
        this.feedForecastControlDiv.current.querySelectorAll("input[type='radio']").forEach(t => {
            var input = t as HTMLInputElement;
            if (input.value == this.selectFeed.field)
                input.checked = true;
            input.onchange = (e) => {
                this.selectFeed = (this.forecastFeed as any)[(e.target as HTMLInputElement).value];
                this.buildSlider(this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step);
                this.showImageOnMap(this.selectFeed.field, this.forecastStartTime, this.selectFeed.startHour);
            }
        });



        this.captureBut.current.onclick = (e) => {
            captureFunc.drawRectangle(this.map).then((points: any) => {
                captureFunc.captureMap(this.map, points).then(clipImgBase64 => {
                    let link: HTMLAnchorElement = document.createElement("a");
                    link.href = clipImgBase64;
                    link.setAttribute("download", new Date().toLocaleString() + "_截图.png");
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
            })
        }
    }
    private buildSlider(startTime: Date, startHours: number, maxHour: number, step: number) {
        let ticks: number[] = [];
        for (var i = startHours; i <= maxHour; i += step)
            ticks.push(i);
        if (ticks.length > 2) {
            var labels: string[] = [];
            for (var hour of ticks) {
                let t = new Date(startTime.getTime() + hour * 3600 * 1000);
                if (ticks.length > 20)
                    labels.push(t.getHours() == 0 ? "<b style='color:#f0f'>" + t.toStr("d日") + "</b>" : t.toStr("H"));
                else
                    labels.push(t.toStr("dd日HH时"));
            }
            for (var b = 0; b < labels.length; b++) {
                if (b % 2 == 0)
                    labels[b] = "";
            }
            this.forecastTimeLabel.current.style.display = "";
            query("#forecastSliderInput").show();
            query("#forecastRidioGroup").hide();
            let slider = new Slider("#forecastSliderInput", {
                ticks: ticks, id: "forecastSliderInput",
                step: step, tooltip: "hide",
                ticks_labels: labels, value: 0
            }).on("change", (changeEvt: any) => {
                this.showImageOnMap(this.selectFeed.field, startTime, +changeEvt.newValue);
            });
            let allTickPointer = document.querySelectorAll(".slider-tick-container div");
            for (var h = 0; h < ticks.length; h++) {
                let t = new Date(startTime.getTime() + ticks[h] * 3600 * 1000);
                if (t.getHours() == 0)
                    (allTickPointer[h] as HTMLDivElement).style.backgroundColor = "#f0f";
            }
            let btns = this.playControlDiv.current.querySelectorAll("i");
            var stop = () => {
                window.clearInterval(this.playInterval);
                btns[1].className = "bi bi-caret-right";
            }
            stop();
            btns[0].onclick = () => {
                stop();
                let v = slider.getValue() - 1;
                if (v >= 0) slider.setValue(v, false, true);
            }
            btns[2].onclick = () => {
                stop();
                let v = slider.getValue() + 1;
                if (v <= ticks.length) slider.setValue(v, false, true);
            }
            btns[1].onclick = () => {
                if (btns[1].className == "bi bi-caret-right") {
                    btns[1].className = "bi bi-pause"
                    this.playInterval = window.setInterval(() => {
                        let v = slider.getValue() + 1;
                        if (v > ticks.length)
                            v = 0;
                        slider.setValue(v, false, true);
                    }, 500);
                }
                else stop();
            }
            let forecastSliderInput = this.numerForecastPanel.current.querySelector("div#forecastSliderInput") as HTMLDivElement;
            let marginBottom = parseFloat(forecastSliderInput.style.marginBottom)
            if (marginBottom > 22) forecastSliderInput.style.marginBottom="22px";
        }
    }
    private async showFeedForcastChart(latlng: L.LatLng, obtcode: iOBTCODE) {
        var t2m = await backendMethod.getFeedForecastValue("T2M", latlng.lng, latlng.lat, this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step);
        var gust = await backendMethod.getFeedForecastValue("GUST", latlng.lng, latlng.lat, this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step);

        let popcontent = document.createElement("div") as HTMLElement;
        ReactDOM.render(this.getForecastChartDom(), popcontent, () => {            
            L.popup({ keepInView: true, maxWidth: 1000, className: "customDarkPopup" })
                .setLatLng(latlng).setContent(popcontent).openOn(this.map).on("remove", () => { (this.map as any).fixedPopwindow = false; })
            this.map.setView(latlng)
            let chartOptions: any = this.getSearial(obtcode, this.selectFruit, this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step, t2m, gust);
            Highcharts.chart(this.feedForecastChartDiv.current, chartOptions);
            document.getElementsByName("forecastFruit").forEach(obj => {
                let input = obj as HTMLInputElement;
                if (input.value == this.selectFruit.code) input.checked = true;
                input.onchange = ce => {
                    let scode = (ce.target as HTMLInputElement).value;
                    this.selectFruit = Enumerable.from(this.fruitDanger).first(t => t.code == scode || t.code1 == scode);
                    chartOptions = this.getSearial(obtcode, this.selectFruit, this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step, t2m, gust);
                    Highcharts.chart(this.feedForecastChartDiv.current, chartOptions);
                }
            });
        });
    }

    private getFruitDanger() {
        return <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
            {
                this.fruitDanger.map((fruit, idx) => (
                    <>
                        <input
                            id={(fruit.vegetable ? 'btnradio' + fruit.code1 : 'btnradio' + fruit.code)}
                            className='btn-check'
                            type="radio"
                            value={(fruit.vegetable ? fruit.code1 : fruit.code)}
                            name='forecastFruit'
                            autoComplete='off'
                        />
                        <label className="btn btn-outline-primary"
                            htmlFor={(fruit.vegetable ? 'btnradio' + fruit.code1 : 'btnradio' + fruit.code)}>
                        {fruit.name}</label>
                    </>
                ))
            }</div>
    }
    private getForecastChartDom() {
        return <div>
            <div className="modal-dialog" >
                <div className="modal-content" style={{ width: 900, color: "#fff" }}>
                    <div className="modal-header" style={{ padding: 5, backgroundColor: "#144373", textAlign: 'left', borderBottom: "none" }}>
                        植物生育期气候 48小时预报
                        <div style={{ display: 'inline-block', marginLeft: "10%" }}></div>
                        <button type="button" className="btn btn-sm btn-primary" style={{ float: "right" }} onClick={() => { this.map.closePopup() }}>关闭</button>
                    </div>
                    <div className="modal-body" style={{ background: "#132A49" }}>
                        <div style={{ zIndex: 2, margin: 10 }}>
                            作物选择： {this.getFruitDanger()}
                        </div>
                        <div ref={this.feedForecastChartDiv} style={{ marginTop: 10, height: 300 }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    private showImageOnMap(field?: string, start?: Date, forhours?: number) {
        if (field) {
            var start_ = start.toStr('yyyyMMddHHmm');
            var urlsz = `/FeedProduct/${start_}/${field}_${start_}_${forhours}_SZ.png`;
            var img = new Image()
            img.src = urlsz
            var proto = this
            img.onload = function () {
                proto.loadImageOver(urlsz);
            }

            img.onerror = function () {
                backendMethod.getFeedForecastPicUrl(field, start, forhours, true).then(resp => {
                    if (resp) {
                        proto.loadImageOver(urlsz);
                    }
                });
                backendMethod.getFeedForecastPicUrl(field, start, forhours, false).then(resp => {
                    if (resp) {
                        proto.loadImageOver(urlsz);
                    }
                });
            }

            this.forecastTimeLabel.current.innerText = "预报时间：" + (new Date(start.getTime() + forhours * 3600000)).toStr("yyyy-MM-dd HH:mm");
            document.querySelectorAll("#colorBar table").forEach((t: any) => t.style.display = "none");
            let tmp = (field == "GUST" ? "wind" : field == "TP" ? "rain" : field == "RH2M" ? "h" : field == "MSL" ? "p" : "t")
            query("#rasterColorTable_" + tmp).show();


        } else if (this.dataImageOverlaySZ) {
            this.map.removeLayer(this.dataImageOverlaySZ);
            delete this.dataImageOverlaySZ;
            this.map.removeLayer(this.dataImageOverlaySS);
            delete this.dataImageOverlaySS;
        }
    }
    private loadImageOver(urlsz: string) {
        let urlss = urlsz.replace('SZ.png', 'SS.png')
        if (this.dataImageOverlaySZ) {
            this.dataImageOverlaySZ.setUrl(urlsz);
            this.dataImageOverlaySS.setUrl(urlss);
        }
        else {
            this.dataImageOverlaySZ = L.imageOverlay(urlsz, L.latLngBounds(L.latLng(22.39, 113.74), L.latLng(22.88377, 114.63))).addTo(this.map);
            this.dataImageOverlaySS = L.imageOverlay(urlss, L.latLngBounds(L.latLng(22.66598, 114.88129), L.latLng(23.04056, 115.17105))).addTo(this.map);
        }
    }
    private close() {
        this.showImageOnMap();
        this.drawWarnCircleInMap();
        this.numerForecastPanel.current.style.display = "none";
    }
    private swiftForm() {
        let cardbody = this.numerForecastPanel.current.querySelector(".card-body") as HTMLDivElement;
        let dash = this.numerForecastPanel.current.querySelector(".card-header .bi-dash") as HTMLSpanElement;
        if (dash) {
            cardbody.style.display = "none";
            dash.className = "bi bi-app";
        } else {
            dash = this.numerForecastPanel.current.querySelector(".card-header .bi-app") as HTMLSpanElement;
            cardbody.style.display = "";
            dash.className = "bi bi-dash";
        }
    }
    private swiftForm2(span: HTMLSpanElement) {
        let card = document.querySelector("#forecastElement") as HTMLDivElement;
        let cardbody = card.querySelector(".card-body") as HTMLDivElement;
        let cardheader = card.querySelector(".card-header") as HTMLDivElement;
        let xbtn = card.querySelector(".bi.bi-x") as HTMLSpanElement;
        if (span.className == "bi bi-dash") {
            cardbody.style.display = "none";
            span.className = "bi bi-app";
            xbtn.style.display = "none";
            card.style.width = "95px";
        } else if (span.className == "bi bi-app") {
            cardbody.style.display = "";
            span.className = "bi bi-dash";
            xbtn.style.display = "";
            card.style.width = "180px";
        }
    }
    private changePickerDate(date: Date) {
        this.forecastStartTime = date;
        this.buildSlider(this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step);
        this.showImageOnMap(this.selectFeed.field, this.forecastStartTime, this.selectFeed.startHour);
        if (this.advice) {
            this.advice.current.loadForecast(date);
        }
    }

    public render() {
        return <>
            <div ref={this.mapContent} style={{ height: window.innerHeight - 41 }}></div>
            <Advice ref={this.advice} />
            <SZWarnning stylePosition={{ position: "absolute", left: 6, top: 50 }} />

            <div id="forecastElement" className="card mb-3" style={{ position: "absolute", left: 5, top: 160, color: "#000", background: "#ffffffb4", zIndex: 520, width: 180, border: "2px solid #0E77D3" }} >
                <div className="card-header" style={{ padding: "3px", color: "#fff", backgroundColor: "#0E77D3" }}>预报要素
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => query("#forecastElement").hide()}></span>
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-dash' onClick={(e) => { this.swiftForm2(e.target as any) }}></span>
                </div>
                <div className="card-body" style={{}}>
                    <div ref={this.feedForecastControlDiv}>
                        <input type="radio" ref={this.T2MTemperature} value="T2M" name="btnWaringRadio" id="btnradio0WaringType" autoComplete="off" />
                        <label htmlFor="btnradio0WaringType" style={{ margin: 5 }}><i className='bi bi-thermometer-half'></i> 小时温度</label>
                        <br />
                        <input type="radio" ref={this.highTemperature} value="MAXT" name="btnWaringRadio" id="btnradio1WaringType" autoComplete="off" />
                        <label htmlFor="btnradio1WaringType" style={{ margin: 5 }}><i className='bi bi-thermometer-half'></i> 24小时最高温</label>
                        <br />
                        <input type="radio" ref={this.lowTemperature} value="MINT" name="btnWaringRadio" id="btnradio2WaringType" autoComplete="off" />
                        <label htmlFor="btnradio2WaringType" style={{ margin: 5 }}><i className='bi bi-thermometer-half'></i> 24小时最低温</label>
                        <br />
                        <input type="radio" ref={this.rainRadio} value="TP" name="btnWaringRadio" id="btnradio3WaringType" autoComplete="off" />
                        <label htmlFor="btnradio3WaringType" style={{ margin: 5 }}><i className='bi bi-umbrella'></i> 小时降雨</label>
                        <br />
                        <input type="radio" ref={this.windRadio} value="GUST" name="btnWaringRadio" id="btnradio4WaringType" autoComplete="off" />
                        <label htmlFor="btnradio4WaringType" style={{ margin: 5 }}> <i className='bi bi-wind'></i> 阵风</label>
                        <br />
                        <input type="radio" ref={this.humRadio} value="RH2M" name="btnWaringRadio" id="btnradio5WaringType" autoComplete="off" />
                        <label htmlFor="btnradio5WaringType" style={{ margin: 5 }}> <i className='bi bi-moisture'></i> 湿度</label>
                        <br />
                        <input type="radio" ref={this.preRadio} value="MSL" name="btnWaringRadio" id="btnradio6WaringType" autoComplete="off" />
                        <label htmlFor="btnradio6WaringType" style={{ margin: 5 }}> <i className='bi bi-speedometer'></i> 气压</label>
                    </div>
                </div>
            </div>

            <div ref={this.numerForecastPanel} className="card" style={{ position: 'absolute', borderColor: "ffffffb2", left: 5, bottom: 4, zIndex: 520 }}>
                <div className="card-header" style={{ background: "#549BDA", color: "#fff", padding: "0 3px" }}>农业气象数值预报
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={this.close.bind(this)}></span>
                    <span style={{ float: "right", fontSize: 18, margin: "0 5px" }} className='bi bi-dash' onClick={this.swiftForm.bind(this)}></span>
                </div>
                <div className="card-body" style={{ padding: "10px 3px", background: "", color: "" }}>
                    <div>
                        <div>
                            <label style={{ marginLeft: 30, userSelect: "none" }}>起报时间</label>：
                            <DdatePicker startDate={this.forecastStartTime} onChange={this.changePickerDate.bind(this)} />
                            <label ref={this.forecastTimeLabel} style={{ marginLeft: 30, userSelect: "none" }}></label>
                            <input type="button" style={{ marginLeft: 20 }} ref={this.captureBut} value="截图"></input>
                        </div>
                        <div style={{ padding: 10, overflow: 'hidden' }}>
                            <table>
                                <tr>
                                    <td style={{ width: 100 }}>
                                        <div ref={this.playControlDiv} style={{ fontSize: 25, margin: "0 10px 0 0", cursor: "pointer", display: 'inline-block' }}>
                                            <i className="bi bi-chevron-left"></i>
                                            <i className="bi bi-caret-right"></i>
                                            <i className="bi bi-chevron-right"></i>
                                        </div>
                                    </td>
                                    <td>
                                        <input id="forecastSliderInput" type="text" style={{ width: window.innerWidth - 800 }} />
                                    </td>
                                </tr>
                            </table>


                            <div id="forecastRidioGroup" style={{ display: "none", lineHeight: "30px" }} className="btn-group" role="group">
                                预报时间：
                                <input type="radio" className="btn-check" name="forecastRidioGroup" id="btnradio2" />
                                <label className="btn btn-outline-primary" htmlFor="btnradio2"></label>

                                <input type="radio" className="btn-check" name="forecastRidioGroup" id="btnradio3" />
                                <label className="btn btn-outline-primary" htmlFor="btnradio3"></label>
                            </div>
                        </div>
                    </div>
                    <div id="colorBar">
                        <ColorBarPC />
                    </div>
                </div>
            </div>
            <Toshenshan getMap={() => this.map} position={{ right: 80, bottom: 10 }} />
        </>
    }
    private getSearial(obtcode: iOBTCODE, fruit: typeof this.selectFruit, issuedate: Date, startHours: number, maxHours: number, stepHours: number, t2m: number[], gust: number[]) {
        let end = new Date(issuedate.getTime() + maxHours * 3600000);
        let tempSeries: [number, number][] = [];
        let gustseries: [number, number][] = [];
        for (var i = 0; i < t2m.length; i++) {
            var ddt = issuedate.getTime() + (i * stepHours + stepHours) * 3600000;
            tempSeries.push([ddt, t2m[i]]);
            gustseries.push([ddt, gust[i]]);
        }
        let myPlotLines: { value: number, dashStyle: string, color?: string, label: any, width?: number }[] = [];
        var warnText = "";
        if (fruit && fruit.temperature)
            for (var w of fruit.temperature) {
                myPlotLines.push({ value: w.value, dashStyle: "DashDot", color: "#f00", width: 1, label: { text: w.text } });
                warnText += w.vegatable ? w.name + w.warnMsg : w.warnMsg + "<br />";
            }
        return {
            credits: { enabled: false },
            title: {
                style: {
                    color: '#fff',
                    font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
                }, text: obtcode.OBTNAME
            },
            subtitle: {
                style: {
                    color: '#fff',
                    font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
                }, text: obtcode.OBTADDRESS
            },
            chart: {
                type: 'spline', spacing: 1, backgroundColor: "#122A46",
                style: { color: "#fff" }
            },
            colorAxis: {
                tickColor: "#f00"
            },
            yAxis: [
                {
                    title: { text: "温度℃", style: { color: "#fff" } }, labels: { style: { color: "#fff" } }, lineWidth: 0, lineColor: "gray", gridLineColor: "#6E6D6C",
                    plotLines: myPlotLines, min: -5, tickInterval: 3
                }, {
                    title: { text: "风速风向", style: { color: "#fff" } }, labels: { style: { color: "#fff" } }, lineWidth: 0, lineColor: "gray", gridLineColor: "#6E6D6C",
                    plotLines: myPlotLines
                }
            ],
            xAxis: {
                title: { text: issuedate.toStr("yyyy-MM-dd HH:mm至") + end.toStr("yyyy-MM-dd HH:mm"), style: { color: "#fff" } },
                labels: { style: { color: "#fff" }, format: '{value:%I}' },
                type: "datetime",
                tickInterval: 60 * 2 * 60 * 1000
            },
            plotOptions: {
                spline: {
                    lineWidth: 3,
                    states: {
                        hover: {
                            lineWidth: 4
                        }
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                title: { style: { color: "#fff" } },
                itemStyle: { color: "#fff" },
                itemHoverStyle: {
                    color: 'inherit' // 设置悬停时的颜色为继承父元素颜色
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br />',
                pointFormat: '{point.y}%\r\n{point.x}',
                pointFormatter: function (this: any) {
                    return `${this.y.toFixed(1) + this.series.options.unit}<br />` + new Date(this.x).toStr("dd日HH时mm分") + "<br /><br />"
                        + (this.series.options.unit == "℃" ? warnText : "")
                }
            },
            series: [{
                name: '预报温度', unit: "℃",
                type: "spline", yAxis: 0, data: tempSeries
            }, {
                name: '风力风向', unit: "m/s",
                type: "column", yAxis: 1, data: gustseries
            }]
        };
    }
    private markerFilterMash(markerOptions: any) {
        var render = () => {
            this.circleLayerGroup.clearLayers();
            let aleady = [];
            for (var op of markerOptions) {
                let pointxy = this.map.latLngToContainerPoint(op.latlng);
                let pass = true;
                for (var p of aleady) {
                    var distance = Math.abs(p.x - pointxy.x) + Math.abs(p.y - pointxy.y);
                    if (distance < 30) {
                        pass = false;
                        break;
                    }
                }
                if (pass) {
                    L.circleMarker(op.latlng, op).addTo(this.circleLayerGroup);
                    aleady.push(pointxy);
                }
            }
        }
        if (!this.circleLayerGroup) {
            this.circleLayerGroup = L.featureGroup().addTo(this.map);
            this.circleLayerGroup.on("mouseover", (evt) => {
                if (!(this.map as any).fixedPopwindow) {
                    var obt: iOBTCODE = evt.layer.options.obtcode;
                    L.popup({ offset: [0, -5] }).setLatLng(evt.latlng)
                        .setContent(obt.OBTNAME + obt.OBTID).openOn(this.map);
                }
            }).on("click", (evt) => {
                (this.map as any).fixedPopwindow = true;
                let obt = evt.layer.options.obtcode;
                this.showFeedForcastChart(evt.latlng, obt);
            });
            this.map.off("zoomend");
            this.map.on("zoomend", () => {
                render();
            });
        }
        render();
    }
    private drawWarnCircleInMap(obtCodeList?: iOBTCODE[]) {
        if (1) {
            this.map.on("click", evt => {
                backendMethod.getNeighborhoodStation(evt.latlng.lng, evt.latlng.lat).then(obtcode => {
                    if (obtcode)
                        this.showFeedForcastChart(evt.latlng, obtcode);
                    else console.log(evt.latlng +" getNeighborhoodStation:null")
                });
            });
        } else {
            if (obtCodeList) {
                let markers = [];
                for (var obt of obtCodeList) {
                    let options = { latlng: L.latLng(obt.LAT, obt.LON), fill: true, color: "#000", fillColor: "#fff", radius: 5, weight: 1, fillOpacity: 1, obtcode: obt };
                    markers.push(options);
                }
                this.markerFilterMash(markers);
            } else if (this.circleLayerGroup) {
                this.map.closePopup();
                this.map.removeLayer(this.circleLayerGroup);
                delete this.circleLayerGroup;
            }
        }
    }
}
