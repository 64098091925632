import { createRef, useEffect } from 'react';
import backendMethod from '../API/backend';
import Enumerable from 'linq';
import { fruitDanger  } from "../API/disarter";
import Highcharts from "highcharts";


export default function WeekWeather(props: { mobile?: boolean }) {
    var cardBody = createRef<HTMLDivElement>();
    var cardDiv = createRef<HTMLDivElement>();
    var getSearial = function (title: string, seriesData: [number, number, number, string][][], fname: string, unit: string, seriesType: string) {
        var series = seriesData.map(t => {
            return { name: fname, type: seriesType, data: t }
        });
        return {
            credits: { enabled: false },
            title: {
                style: {
                    color: '#000',
                    font: 'normal 13px "Trebuchet MS", Verdana, sans-serif'
                }, text: title
            },
            subtitle: {
                style: {
                    color: '#000',
                    font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
                }, text: ""
            },
            chart: {
                spacingTop: 20,
                type: 'spline', spacing: 1, backgroundColor: "#ffffffb4",
                style: { color: "#000" }
            },
            yAxis: [
                {
                    title: { text: "" }, labels: { style: { color: "#000" } }, gridLineColor: "#8F9CA8"
                }
            ],
            xAxis: {
                labels: { style: { color: "#000" } },
                tickInterval: 1
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y}%\r\n{point.x}',
                pointFormatter: function (this: any) {
                    let data: any = Enumerable.from(this.series.options.data).first((t: any) => t[0] == this.x);
                    let html = ""
                    if (this.series.name == "最大日雨量")
                        html = `${this.y}${unit}<br/>` + data[3] + '站<br/>' + new Date(data[2]).toStr("yyyy年MM月dd日")
                    else
                        html = `${this.y}${unit}<br/>` + data[3] + '站<br/>' + new Date(data[2]).toStr("yyyy年MM月dd日HH时mm分")
                    return html //`${this.y}${unit}<br/>` + obt[2] + "<br/>" + obt[3].toStr("yyyy-MM-dd");
                }
            },
            series: series
        };
    }
    useEffect(() => {
        backendMethod.getLastWeekObtDataByD().then(t => {
            var maxtWeek = Enumerable.from(t).where((t: any) => t.T_STAT == "1").orderByDescending(t => t.MAXT).first();
            var mintWeek = Enumerable.from(t).where((t: any) => t.T_STAT == "1").orderBy(t => t.MINT).first();
            var r24hWeek = Enumerable.from(t).where((t: any) => t.R_STAT == "1").orderByDescending(t => t.R24H).first();
            var windWeek = Enumerable.from(t).where((t: any) => t.W_STAT == "1").orderByDescending(t => t.WD3SMAXDF).first();
            var content = `<ul>
             <li>最大日雨量是${r24hWeek.R24H}mm，位于${r24hWeek.OBTNAME}</li>
             <li>最大瞬时风力是${windWeek.WD3SMAXDF}m/s，位于${windWeek.OBTNAME}</li>
             <li>最高温是${maxtWeek.MAXT}℃，位于${maxtWeek.OBTNAME}，对各农作物影响：
             <ul><li>${maxtWeek.MAXT >= 35 ? "<b style='color:red'>植物在大于或等于35℃时，大部份会停止生长</b>" : "<b style='color:green'>没有高温热害</b>"}</li></ul></li>
             <li>最低温是${mintWeek.MINT}℃，位于${mintWeek.OBTNAME}
            `

            var fruitDanger_ = fruitDanger()
            if (fruitDanger_.length > 0) {
                let content_ = "";

                for (var f in fruitDanger_) {
                    var frult = (fruitDanger_ as any)[f];
                    if (frult.temperature)
                        for (var w of frult.temperature) {
                            if (w.code == "cold") {
                                if (mintWeek.MINT <= w.value)
                                    content_ += "<li style='color:#D8681B'>" + frult.name + w.warnMsg + "</li>";
                            } 
                            //else if (w.code == "hot") {
                            //    if (mintWeek.MAXT >= w.value)
                            //        content_ += "<li style='color:#D8681B'>" + frult.name + w.warnMsg + "</li>";
                            //}
                        }
                }
                if (content_ == "")
                    content_ = "<li><b style='color:green'>没有低温冷害</b></li>";

                content += "，对各农作物影响：<ul>" + content_ + "</ul>";
            }
        
            content += "</li></ul>";
            cardBody.current.innerHTML = content;

            let seriesData: [number, number, number, string][] = [];
            let seriesData2: [number, number, number, string][] = [];
            let seriesData3: [number, number, number, string][] = [];
            let seriesData4: [number, number, number, string][] = [];
            Enumerable.from(t).groupBy(t => t.DDATETIME.getDate()).orderBy(t => t.key())
                .forEach((t: any) => {
                    let key = t.key()
                    let maxt = Enumerable.from(t).where((t: any) => t.T_STAT == "1").orderByDescending((t: any) => t.MAXT).first() as iLOCALOBTDAYD;
                    seriesData.push([key, maxt.MAXT, maxt.MAXTTIME.getTime(), maxt.OBTNAME]);
                    let mint = Enumerable.from(t).where((t: any) => t.T_STAT == "1").orderBy((t: any) => t.MINT).first() as iLOCALOBTDAYD;
                    seriesData2.push([key, mint.MINT, maxt.MINTTIME.getTime(), mint.OBTNAME]);
                    let r24h = Enumerable.from(t).where((t: any) => t.R_STAT == "1").orderByDescending((t: any) => t.R24H).first() as iLOCALOBTDAYD;
                    seriesData3.push([key, r24h.R24H, maxt.DDATETIME.getTime(), r24h.OBTNAME]);
                    let wd3smaxdf = Enumerable.from(t).where((t: any) => t.W_STAT == "1").orderByDescending((t: any) => t.WD3SMAXDF).first() as iLOCALOBTDAYD;
                    seriesData4.push([key, wd3smaxdf.WD3SMAXDF, maxt.WD3SMAXTIME.getTime(), wd3smaxdf.OBTNAME]);
                });
            var temperatureCharts = cardBody.current.appendElement("div", { height: "100px" });
            var chartSearial: any = getSearial("过去一周全市最高温", [seriesData], "日最高温", "℃", "spline");
            Highcharts.chart(temperatureCharts, chartSearial);

            temperatureCharts = cardBody.current.appendElement("div", { height: "100px" });
            chartSearial = getSearial("过去一周全市最低温", [seriesData2], "日最低温", "℃", "spline");
            Highcharts.chart(temperatureCharts, chartSearial);

            temperatureCharts = cardBody.current.appendElement("div", { height: "100px" });
            chartSearial = getSearial("过去一周全市最大日雨量", [seriesData3], "最大日雨量", "mm", "column");
            Highcharts.chart(temperatureCharts, chartSearial);

            temperatureCharts = cardBody.current.appendElement("div", { height: "100px" });
            chartSearial = getSearial("过去一周全市最大瞬时风速", [seriesData4], "最大瞬时风速", "m/s", "spline");
            Highcharts.chart(temperatureCharts, chartSearial);
        });
    });
    function swiftForm(span: HTMLSpanElement) {
        let card = document.querySelector("#weekWeather") as HTMLDivElement;
        let cardheader = card.querySelector(".card-header") as HTMLDivElement;
        let carbody = card.querySelector(".card-body") as HTMLDivElement;
        let xbtn = card.querySelector(".bi.bi-x") as HTMLSpanElement;
        if (span.className == "bi bi-dash") {
            carbody.style.display = "none";
            span.className = "bi bi-app";
            span.style.display =
                xbtn.style.display = "none";
            card.style.width = "100px";
            cardheader.style.fontSize = "13px";
            cardheader.onclick = function () {
                carbody.style.display = "";
                span.className = "bi bi-dash";
                span.style.display =
                    xbtn.style.display = "";
                card.style.width = "350px";
                cardheader.style.fontSize = "16px";
                cardheader.onclick = function () { };
            }
        } else if (span.className == "bi bi-app") {
            carbody.style.display = "";
            span.className = "bi bi-dash";
            span.style.display =
                xbtn.style.display = "";
            card.style.width = "350px";
            cardheader.style.fontSize = "16px";
        }
    }
    if (props.mobile)
        return <>
            <div className="card" id="weekWeather" ref={cardDiv} style={{ position: "absolute", zIndex: 520, top: 36, minHeight: window.innerHeight - 40, width: "100%", background: "#1F334A", color: "#fff" }}>
                <div className="card-header" style={{ fontWeight: "bold", background: "#144373" }}>
                    过去一周天气概况
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => cardDiv.current.style.display = "none"}></span>
                </div>
                <div className="card-body" ref={cardBody} style={{ padding: 10, fontSize: 13 }}>
                </div>
                <div className="card-foot">
                    <input type='button' onClick={e => cardDiv.current.style.display = "none"} value="关闭" className='btn btn-primary' style={{ width: "98%", margin: "0 5px" }}></input>
                </div>
            </div>
        </>;
    else return <>
        <div className="card" id="weekWeather" ref={cardDiv} style={{ position: "absolute", backgroundColor: "#ffffffb6", width: 350, zIndex: 520, top: 55, border: "2px solid #0E77D3", right: 5, color: "#000" }}>
            <div className="card-header" style={{ fontWeight: "bold", background: "#0E77D3", color: "#fff" }}>
                过去一周天气概况
                <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => cardDiv.current.style.display = "none"}></span>
                <span style={{ float: "right", fontSize: 18 }} className='bi bi-dash' onClick={function (e) { swiftForm(e.target as any) }}></span>
            </div>
            <div className="card-body" ref={cardBody} style={{ padding: 10, fontSize: 14, maxHeight: window.innerHeight - 100, overflowY: 'auto' }}>

            </div>
        </div>
    </>;
}