//深圳预警信号
import { CSSProperties, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import backend from "../API/backend";
import "../util/objExtend";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import LINQ from "linq";
import myquery from "../util/myquery";

export default function (props: { isMobile?: boolean, stylePosition: { position: string, right?: number, top?: number, left?: number, bottom?: number } }) {
    function showSignal(signal: iSZWarning[]) {
        if (signal && signal.length) {
            var sz = LINQ.from(signal).where(t => t.Area.indexOf("深汕") == -1).toArray();
            var ss = LINQ.from(signal).where(t => t.Area.indexOf("深汕") != -1).toArray();
            var showPic = function (areaSignal: iSZWarning[], id: string) {
                if (areaSignal.length) {
                    let div = document.getElementById(id);
                    while (div.lastChild) div.removeChild(div.lastChild);
                    div.parentElement.style.display = "inline-block";
                    for (var s of areaSignal) {
                        let a = div.appendElement("a");
                        if (s.Message) {
                            a.setAttribute("data-tooltip-id", "my-tooltip");
                            a.setAttribute("data-tooltip-content", s.Message);
                        }
                        a.appendImage(s.PicUrl, { margin: "3px", width: props.isMobile ? "45px" : "61px" });
                    }
                }
            }
            showPic(sz, "shenzhenSignalDiv");
            showPic(ss, "shenshanSignalDiv");
        } else
            myquery("#shenzhenSignalDiv").text("当前无数据").parent().style.display = "";
    }
    useEffect(() => {
        backend.getSZWarnnig().then(showSignal);
    }, []);
    var cstyle: CSSProperties = { color: "#000", zIndex: 500 };
    cstyle = Object.assign(cstyle, props.stylePosition);
    return <div style={cstyle}>
        <div className="card mb-3" style={{ display: "none", background: "#ffffffb4", border: "1px solid #0F74CD" }} >
            <div className="card-header" style={{ padding: "5px", borderBottom: "1px solid #0F74CD" }}>深圳预警信号</div>
            <div className="card-body" id="shenzhenSignalDiv" style={{ padding: "2px", fontSize: 13, textAlign: "center" }}>
            </div>
        </div>
        <div className="card mb-3" style={{ display: "none", marginLeft: "2px", background: "#ffffffb4", border: "1px solid #0F74CD" }} >
            <div className="card-header" style={{ padding: "5px", borderBottom: "1px solid #0F74CD" }}>深汕区</div>
            <div className="card-body" id="shenshanSignalDiv" style={{ padding: "2px", textAlign: "center" }}>
            </div>
        </div>
        <Tooltip id="my-tooltip" style={{ backgroundColor: "#FAE6B1", color: "#000", width: "200px" }} />
    </div>;
}
