import * as ReactDOM from 'react-dom';
import { Modal } from "bootstrap";
import { createRef } from 'react';
import Enumerable from 'linq';
import backendMethod from '../API/backend';
import Highcharts from "highcharts";
import { fruitDanger2 } from "../API/disarter";
export default class StreetForecast {
    private feedForecastChartDiv = createRef<HTMLDivElement>();
    private streetSelect = createRef<HTMLSelectElement>();
    private fruitFeedForecastDivClose = createRef<HTMLButtonElement>();
    private streetList: iShenzhenStreet[];
    private streetId: number;
    private fruitDanger = fruitDanger2(true);
    private dialogSelectFruit = Enumerable.from(this.fruitDanger).first(t => t.code == "lichee");
    private forecastStartTime: Date;
    private mobile: boolean;
    private forecastFeed = {
        T2M: { field: "T2M", startHour: 1, maxHour: 48, step: 1 },
        MAXT: { field: "MAXT", startHour: 24, maxHour: 48, step: 24 },
        MINT: { field: "MINT", startHour: 24, maxHour: 48, step: 24 },
        TP: { field: "TP", startHour: 1, maxHour: 48, step: 1 },
        GUST: { field: "GUST", startHour: 1, maxHour: 48, step: 1 },
    }
    private selectFeed = this.forecastFeed.T2M;
    public constructor(streetId: number, streetList: iShenzhenStreet[], mobile?: boolean) {
        this.mobile = mobile;
        this.streetList = streetList;
        this.streetId = streetId;
        this.forecastStartTime = new Date();
        this.forecastStartTime.setDate(this.forecastStartTime.getDate() - 1);
        this.forecastStartTime.setHours(20, 0, 0, 0);

    }
    public showContainer(contaner: HTMLElement, closeCallback: () => void) {
        ReactDOM.render(this.getStreetForecatDom(), contaner, () => {
            this.initModalContent(this.streetId, closeCallback);
            this.showChart();
        });
    }

    public showModal() {
        let popcontent = document.createElement("div") as HTMLElement;
        popcontent.className = "modal"
        ReactDOM.render(this.getStreetForecatDom(), popcontent, () => {
            let m = new Modal(popcontent, { backdrop: true });
            m.show();
            this.initModalContent(this.streetId, () => { m.hide(); });
            this.showChart();
            popcontent.addEventListener('hidden.bs.modal', event => {
                popcontent.parentElement.removeChild(popcontent);
            });
        });
    }
    private showChart() {
        var street = Enumerable.from(this.streetList).first(t => t.ID == this.streetId);
        backendMethod.getFeedForecastValue(this.selectFeed.field, street.Longitude, street.Latitude, this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step).then(items => {
            let chartOptions: any = this.getSearial(this.dialogSelectFruit, this.forecastStartTime, this.selectFeed.startHour, this.selectFeed.maxHour, this.selectFeed.step, items);
            Highcharts.chart(this.feedForecastChartDiv.current, chartOptions);
        });
    }
    private initModalContent(defaultStreetId: number, closefunc: () => void) {
        document.querySelectorAll("#fruitFeedForecastDiv input[type='radio']").forEach(t => {
            var input = t as HTMLInputElement;
            if (input.value == this.dialogSelectFruit.code)
                input.checked = true;
            input.onchange = (e) => {
                var v = (e.target as HTMLInputElement).value
                this.dialogSelectFruit = Enumerable.from(this.fruitDanger).first(t => t.code == v || t.code1 == v);
                this.showChart();
            }
        });
        for (var area of Enumerable.from(this.streetList).groupBy(t => t.Area)) {
            var optgroup = this.streetSelect.current.appendElement("optgroup") as HTMLOptGroupElement;
            optgroup.label = area.key();
            for (var street of area)
                this.streetSelect.current.options.add(new Option(street.Name, street.ID.toString()));
        }
        if (defaultStreetId) {
            this.streetId = defaultStreetId;
            this.streetSelect.current.value = this.streetId.toString();
        } else
            this.streetId = +this.streetSelect.current.value;
        this.streetSelect.current.onchange = (evt) => {
            this.streetId = +(evt.target as HTMLSelectElement).value;
            this.showChart();
        }
        if (closefunc) this.fruitFeedForecastDivClose.current.onclick = evt => {
            closefunc();
        }
    }
    private getSearial(fruit: typeof this.dialogSelectFruit, issuedate: Date, startHours: number, maxHours: number, stepHours: number, items: number[]) {
        let end = new Date(issuedate.getTime() + maxHours * 3600000);
        let r01hseries: [number, number][] = [];
        let tempSeries: [number, number][] = [];
        for (var i = 0; i < items.length; i++) {
            var ddt = issuedate.getTime() + (i * stepHours + stepHours) * 3600000;
            r01hseries.push([ddt, 0]);
            tempSeries.push([ddt, items[i]]);
        }
        let myPlotLines: { value: number, dashStyle: string, color?: string, label: any, width?: number }[] = [];
        var warnText = "";
        if (fruit && fruit.temperature)
            for (var w of fruit.temperature) {
                if (w.warnMsg) {
                    myPlotLines.push({ value: w.value, dashStyle: "DashDot", color: "#f00", width: 1, label: { text: w.text } });
                    warnText += w.vegatable ? w.name + w.warnMsg : w.warnMsg + "<br/>";
                }
            }
        return {
            credits: { enabled: false },
            title: {
                style: {
                    color: '#fff',
                    font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
                }, text: fruit.name + "生育期气候监测"
            },
            subtitle: {
                style: {
                    color: '#fff',
                    font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
                },
                text: issuedate.toStr("yyyy-MM-dd HH:mm至") + end.toStr("yyyy-MM-dd HH:mm")
            },
            chart: {
                type: 'spline', spacing: 1, backgroundColor: "#122A46",
                style: { color: "#fff" }
            },
            colorAxis: {
                tickColor: "#f00"
            },
            yAxis: [
                {
                    title: { text: "预报温度℃", style: { color: "#fff" } },
                    labels: { style: { color: "#fff" } },
                    lineWidth: 0, lineColor: "gray", gridLineColor: "#6E6D6C",
                    plotLines: myPlotLines, min: 0
                }
            ],
            xAxis: {
                title: { text: '' },
                labels: { style: { color: "#fff" }, format: '{value:%I}' },
                type: "datetime",
                tickInterval: 60 * 60 * 1000
            },
            plotOptions: {
                spline: {
                    lineWidth: 3,
                    states: {
                        hover: {
                            lineWidth: 4
                        }
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                title: { style: { color: "#fff" } },
                itemStyle: { color: "#fff" },
                itemHoverStyle: {
                    color: 'inherit' // 设置悬停时的颜色为继承父元素颜色
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y}%\r\n{point.x}',
                pointFormatter: function (this: any) { return `${this.y.toFixed(1) + this.series.options.unit}<br/>` + new Date(this.x).toStr("dd日HH时mm分") + "<br/><br/>" + warnText }
            },
            series: [{
                name: '预报温度', unit: "℃",
                type: "spline", yAxis: 0,
                data: tempSeries
            }]
        };
    }
    private getStreetForecatDom() {
        return <div className="modal-dialog">
            <div className="modal-content" style={{ width: this.mobile ? "100%" : 700, color: "#fff" }}>
                <div className="modal-header" style={{ padding: 5, backgroundColor: "#144373", textAlign: 'left', borderBottom: "none" }}>
                    植物生育期气候预报
                    <div style={{ display: 'inline-block', marginLeft: "10%" }}></div>
                </div>
                <div className="modal-body" style={{ background: "#132A49" }}>
                    <div style={{ zIndex: 2, margin: "10px 5px" }}>
                        <div id="fruitFeedForecastDiv" className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" className="btn-check" name="reproductionFruit" value="lichee" id="radioLechee" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioLechee">荔枝</label>
                            <input type="radio" className="btn-check" name="reproductionFruit" value="rice" id="radioRich" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioRich">水稻</label>
                            <input type="radio" className="btn-check" name="reproductionFruit" value="longan" id="radioLongan" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioLongan">龙眼</label>
                            <input type="radio" className="btn-check" name="reproductionFruit" value="canola" id="radioCanola" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioCanola">油菜</label>
                            <input type="radio" className="btn-check" style={{ display: 'none' }} name="reproductionFruit" value="vegetable" id="radioVegetable" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="vegetable" style={{ display: 'none' }}>蔬菜</label>
                            <input type="radio" className="btn-check" style={{ display: 'none' }} name="reproductionFruit" value="orange" id="radioOrange" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioOrange" style={{ display: 'none' }}>柑桔</label>

                            <input className="btn-check" name='reproductionFruit' type="radio" id="radioTomato" value="tomato" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioTomato">番茄</label>
                            <input className="btn-check" name='reproductionFruit' type="radio" id="radioCucuber" value="cucumber" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioCucuber">黄瓜</label>
                            <input className="btn-check" name='reproductionFruit' type="radio" id="radioSweetPeppr" value="sweetpepper" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioSweetPeppr"> 甜椒</label>
                            <input className="btn-check" name='reproductionFruit' type="radio" id="radioEggPlant" value="eggplant" autoComplete="off" />
                            <label className="btn btn-outline-primary" htmlFor="radioEggPlant">茄子</label>


                        </div>
                        <div style={{ display: "inline-block" }}>
                            &nbsp;&nbsp;<span style={{ display: this.mobile ? "none" : "" }}>街道：</span>
                            <select ref={this.streetSelect} style={{ width: 100, color: "black", padding: 5 }}></select>
                        </div>
                    </div>
                    <div ref={this.feedForecastChartDiv} style={{ marginTop: 10, height: 300, paddingRight: 2 }}>
                    </div>
                </div>
                <div className="modal-footer" style={{ padding: 0, backgroundColor: "#144373", borderTop: "none" }}>
                    <button type="button" style={{ width: "100%" }} ref={this.fruitFeedForecastDivClose} className="btn btn-primary btn-sm">关闭</button>
                </div>
            </div>
        </div >
    }
}