//高度通量塔
import { createRef, useEffect } from 'react';
import backendMethod from '../API/backend';
import query from "../util/myquery";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.min.css"
import { Modal } from "bootstrap";
import "../css/index.css";
import LINQ from "linq";
import Highcharts from "highcharts";
//import windbarb from "highcharts/modules/windbarb.src";
import wdb from "../util/wdb"
import weatherUtil from "../util/weatherUtil"
import DdatePicker from "./ddatePicker";

var Props: { map: L.Map, mobile?: boolean };
export default function MetTower(props: typeof Props) {
    Props = props;
    var towerLnglat = L.latLng(22.59512, 114.4944);
    var Lmap: L.Map = props.map;
    var towerDataDiv = createRef<HTMLDivElement>();
    var highchartModalDiv = createRef<HTMLDivElement>();
    var highchartModalMomentDiv = createRef<HTMLDivElement>();
    Highcharts.setOptions({ global: { useUTC: false } });
    //windbarb(Highcharts);
    var towerMarker = L.marker(towerLnglat, { icon: L.icon({ iconAnchor: [14, 32], iconUrl: 'images/mttower.png' }) }).addTo(Lmap);
    towerMarker.on("click", showMoment);
    let starttime = new Date(); 
    let mm = starttime.getMinutes() - 10;
    mm = mm - mm % 5;
    starttime.setMinutes(mm, 0, 0);
    let meter = [-0.4, -0.05, 0.5, 1, 2, 3, 4, 6, 8, 10, 15, 20, 25, 30];
    var setFunc: { set?: (d: Date) => void } = {};
    function showMoment(e: any) {
        Lmap.closePopup();
        let checkbox = document.querySelectorAll("#towerdiv input[type=checkbox]")[0] as HTMLInputElement;
        if (checkbox.checked || e.latlng) {
            fillGradientTower(starttime, () => {
                new Modal(towerDataDiv.current).show();
                checkbox.checked = false;
            });
        }
    }
    function fillGradientTower(ddatetime: Date, callback?: () => void) {
        backendMethod.getGradientTower(ddatetime, ddatetime).then(tInfo => {
            var gbody = document.querySelector("#gradientTowerTbody") as HTMLElement;
            while (gbody.lastChild) gbody.removeChild(gbody.lastChild);
            if (tInfo.length) {
                if (setFunc.set) setFunc.set(tInfo[0].DDATETIME);
                let temperature: [string, number][] = [];
                let humidity: [string, number][] = [];
                let wind: [string, number][] = [];
                var tdstyle = { "border-bottom": 'solid 1px gray' };
                LINQ.from(tInfo).orderByDescending(t => +t.LAYERS).forEach(t => {
                    var row = gbody.appendElement("tr");
                    var index = t.LAYERS + 1;
                    if (t.LAYERS < 0) index += 1;
                    row.appendTd(tdstyle).appendText(meter[index] + "m");
                    row.appendTd(tdstyle).appendText(isNaN(t.TEMPERATURE) ? "-" : t.TEMPERATURE);
                    row.appendTd(tdstyle).appendText(isNaN(t.SOIL_TEM) ? "-" : t.SOIL_TEM);
                    row.appendTd(tdstyle).appendText(isNaN(t.HUMIDITY) ? "-" : t.HUMIDITY);
                    row.appendTd(tdstyle).appendText(isNaN(t.WIND_SPEED) ? "-" : (t.WIND_SPEED + "(" + t.WIND_SPEED_LEVEL + "级)"));
                    row.appendTd(tdstyle).appendText(isNaN(t.WIND_DIRECTION) ? "-" : t.WIND_DIRECTION + "(" + t.WIND_DIRECT_NAME + ")");

                    temperature.push([meter[index] + "m", t.TEMPERATURE || t.SOIL_TEM]);
                    humidity.push([meter[index] + "m", t.HUMIDITY]);
                    wind.push([meter[index] + "m", t.WIND_SPEED]);
                });
                let choption: any = {
                    credits: { enabled: false },
                    title: { text: '' },
                    subtitle: { text: '' },
                    chart: { inverted: true, type: 'spline', spacing: 1, backgroundColor: "#37495Fb5", style: { color: "#fff" } },
                    yAxis: [
                        { title: { y: -20, x: -60, align: 'low', text: "温度°C", style: { color: "#2CAFFE" } }, margin: 0, height: 350, gridLineWidth: 0, gridLineColor: "#6E6D6C", labels: { style: { color: "#2CAFFE" } } },
                        { title: { y: -20, x: -60, align: 'low', text: "湿度%", style: { color: "orange" } }, tickInterval: 0.5, margin: 0, height: 330, gridLineWidth: 0, gridLineColor: "#6E6D6C", labels: { style: { color: "orange" } } },
                        { title: { y: -20, x: -60, align: 'low', text: "风力m/s", style: { color: "#00E172" } }, margin: 0, height: 310, gridLineWidth: 0, gridLineColor: "#6E6D6C", labels: { style: { color: "#00E172" } } }
                    ],
                    xAxis: {
                        reversed: true,
                        title: { text: props.mobile ? "" : '高度层', style: { color: "#fff" } },
                        labels: {
                            style: { color: "#fff" }
                        }, gridLineWidth: 1,
                        tooltip: { valueSuffix: ' °C' },
                        gridLineColor: "#6E6D6C",
                        type: 'category'
                    },
                    legend: {
                        enabled: !Props.mobile,
                        layout: 'horizontal',
                        align: 'center', floating: true,
                        verticalAlign: 'bottom',
                        itemStyle: { color: "#fff" },
                        itemHoverStyle: {
                            color: 'inherit' // 设置悬停时的颜色为继承父元素颜色
                        },
                        labelFormatter: function (this: any) {
                            return `<b style='color:${this.name == "相对湿度" ? "orange" : this.name == "风力大小" ? "#00E172" : "#2CAFFE"}'>${this.name}</b>`
                        }
                    },
                    tooltip: {
                        style: { fontSize: "16px" }
                    },
                    series: [{
                        name: '地上/土壤温度',
                        data: temperature, color: "#2CAFFE",
                        tooltip: {
                            valueSuffix: '  °C'
                        }
                    },
                    {
                        name: '相对湿度', yAxis: 1,
                        data: humidity, color: "orange",
                        tooltip: {
                            valueSuffix: '  %'
                        }
                    }, {
                        name: '风力大小', yAxis: 2,
                        data: wind, color: "#00E172",
                        tooltip: {
                            valueSuffix: '  m/s'
                        }
                    }]
                }
                Highcharts.chart(highchartModalMomentDiv.current, choption);
            }
            if (callback) callback();
        });
    }
    useEffect(() => {
        var container = highchartModalDiv.current;
        container.addEventListener('hidden.bs.modal', evt => {
            document.querySelectorAll("#towerdiv input[type=checkbox]").forEach(e => { (e as HTMLInputElement).checked = false });
        });
    });
    function changeStarttime(date: Date) {
        fillGradientTower(date);
        starttime = date;
    }
    async function showTowerWind() {
        return new Promise((resolve: (x: string) => void, reject) => {
            let startd = new Date(), endtd = new Date(starttime);
            startd.setTime(endtd.getTime() - 30 * 60 * 1000);
            backendMethod.getGradientTower(startd, endtd).then(tInfo => {
                var container = highchartModalDiv.current;
                query(".btn-group").hide()
                var modalbody = container.querySelector(".modal-body") as HTMLElement;
                var modalheader = container.querySelector(".modal-title") as HTMLElement;
                modalheader.innerText = "通道塔最近三十分钟风速" + startd.toStr("yyyy年MM月dd日HH时mm分至") + endtd.toStr("HH时mm分");
                while (modalbody.lastChild) modalbody.removeChild(modalbody.lastChild);
                var maxtime = LINQ.from(tInfo).max(t => t.DDATETIME.getTime());
                for (var item of LINQ.from(tInfo).where(t => t.LAYERS >= 0).groupBy(t => t.LAYERS).orderByDescending(t => t.key())) {
                    var itemDiv = modalbody.appendElement("div", { with: "800px", height: "190px" });
                    let windSeries: { x: number, y: number, wd: number, marker?: any }[] = [];
                    item.orderBy(t => t.DDATETIME).forEach(t => {
                        windSeries.push({ x: t.DDATETIME.getTime(), y: +t.WIND_SPEED.toFixed(1), wd: t.WIND_DIRECTION });
                    });
                    while (windSeries[windSeries.length - 1].x < maxtime)
                        windSeries.push({ x: windSeries[windSeries.length - 1].x + 60000, y: NaN, wd: NaN });
                    let windbSeries = windSeries.map(t => [t.x, t.y, t.wd]);
                    var chartOpent: any = {
                        credits: { enabled: false },
                        title: { text: '' },
                        subtitle: { text: '' },
                        chart: { type: 'spline', spacing: 1, backgroundColor: "#122A46", style: { color: "#fff" } },
                        yAxis: [
                            { title: { text: getTowenLayerAndHeight(item.key()) }, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } } },
                            {
                                title: { text: Props.mobile ? '' : "风速m/s" }, gridLineColor: "#6E6D6C",
                                opposite: true, labels: { style: { color: "#fff" } }
                            }
                        ],
                        xAxis: {
                            title: { text: '' },
                            type: "datetime", labels: { style: { color: "#fff" } },
                            tickInterval: 5 * 60 * 1000
                        },
                        legend: {
                            enabled: !Props.mobile,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle'
                        },
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br/>',
                            pointFormat: '{point.y}%\r\n{point.x}',
                            pointFormatter: function (this: any) {
                                return `${this.y}m/s 风向${this.wd}度<br/>` + new Date(this.x).toStr("dd日HH时mm分")
                            }
                        },
                        series: [{
                            yAxis: 1, name: '风速',
                            data: windSeries
                        }
                            //, {
                            //    type: 'windbarb',
                            //    data: windbSeries,
                            //    name: 'Wind',
                            //    color: Highcharts.getOptions().colors[1],
                            //    showInLegend: false,
                            //    tooltip: {
                            //        valueSuffix: ' m/s'
                            //    }
                            //}
                        ]
                    };
                    Highcharts.chart(itemDiv, chartOpent);
                }
                new Modal(container, { backdrop: true }).show();
            });
        })
    }
    async function showTowerHumidity() {
        return new Promise((resolve: (x: string) => void, reject) => {
            let startd = new Date(), endtd = new Date(starttime);
            startd.setTime(endtd.getTime() - 30 * 60 * 1000);
            backendMethod.getGradientTower(startd, endtd).then(tInfo => {
                var container = highchartModalDiv.current;
                query(".btn-group").hide()
                var modalbody = container.querySelector(".modal-body") as HTMLElement;
                var modalheader = container.querySelector(".modal-title") as HTMLElement;
                modalheader.innerText = "通道塔最近三十分钟湿度" + startd.toStr("yyyy年MM月dd日HH时mm分至") + endtd.toStr("HH时mm分");
                while (modalbody.lastChild) modalbody.removeChild(modalbody.lastChild);
                var maxtime = LINQ.from(tInfo).max(t => t.DDATETIME.getTime());
                for (var item of LINQ.from(tInfo).where(t => t.LAYERS >= 0).groupBy(t => t.LAYERS).orderByDescending(t => t.key())) {
                    var itemDiv = modalbody.appendElement("div", { with: "800px", height: "190px" });
                    let buildseries: [number, number][] = [];
                    let min = 99999, max = 0, tickInterval = 10
                    item.orderBy(t => t.DDATETIME).forEach(t => {
                        var hum = +t.HUMIDITY.toFixed(1)
                        buildseries.push([t.DDATETIME.getTime(), hum]);
                        min = hum < min ? hum : min;
                        max = hum > max ? hum : max;
                    });
                    while (buildseries[buildseries.length - 1][0] < maxtime)
                        buildseries.push([buildseries[buildseries.length - 1][0] + 60000, NaN]);
                    min = min - min % 10;
                    max = max - max % 10 + 10;
                    var span = max - min;
                    if (span < 20) tickInterval = 5;
                    if (span > 60) tickInterval = 20;
                    var chartOpent: any = {
                        credits: { enabled: false },
                        title: { text: '' },
                        subtitle: { text: '' },
                        chart: { type: 'spline', spacing: 1, backgroundColor: "#122A46", style: { color: "#fff" } },
                        yAxis: [
                            {
                                title: { text: getTowenLayerAndHeight(item.key()) },
                                gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } }
                            },
                            {
                                title: { text: '湿度%' },
                                opposite: true, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } },
                                min: min,
                                max: max,
                                tickInterval: tickInterval
                            }
                        ],
                        xAxis: {
                            title: { text: '' },
                            type: "datetime", labels: { style: { color: "#fff" } },
                            tickInterval: 5 * 60 * 1000
                        },
                        legend: {
                            enabled: !Props.mobile,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle'
                        },
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br/>',
                            pointFormat: '{point.y}%\r\n{point.x}',
                            pointFormatter: function (this: any) { return `${this.y}%<br/>` + new Date(this.x).toStr("dd日HH时mm分") }
                        },
                        series: [{
                            type: 'column',
                            yAxis: 1, name: '湿度',
                            data: buildseries
                        }]
                    };
                    Highcharts.chart(itemDiv, chartOpent);
                }
                new Modal(container, { backdrop: true }).show();
            });
        })
    }
    async function showLandTemperature() {
        return new Promise((resolve: (x: string) => void, reject) => {
            let startd = new Date(), endtd = new Date(starttime);
            startd.setTime(endtd.getTime() - 30 * 60 * 1000);
            backendMethod.getGradientTower(startd, endtd).then(tInfo => {
                var container = highchartModalDiv.current;
                query(".btn-group").hide()
                var modalbody = container.querySelector(".modal-body") as HTMLElement;
                var modalheader = container.querySelector(".modal-title") as HTMLElement;
                modalheader.innerText = "通量塔最近三十分钟土壤温度" + startd.toStr("yyyy年MM月dd日HH时mm分至") + endtd.toStr("HH时mm分");
                while (modalbody.lastChild) modalbody.removeChild(modalbody.lastChild);
                var maxtime = LINQ.from(tInfo).max(t => t.DDATETIME.getTime());
                for (var item of LINQ.from(tInfo).where(t => t.LAYERS <= 0).groupBy(t => t.LAYERS).orderByDescending(t => t.key())) {
                    var itemDiv = modalbody.appendElement("div", { with: "800px", height: "190px" });
                    let buildseries: [number, number][] = [];
                    item.orderBy(t => t.DDATETIME).forEach(t => { buildseries.push([t.DDATETIME.getTime(), +t.SOIL_TEM.toFixed(2)]); });
                    while (buildseries[buildseries.length - 1][0] < maxtime)
                        buildseries.push([buildseries[buildseries.length - 1][0] + 60000, NaN]);
                    var chartOpent: any = {
                        credits: { enabled: false },
                        title: { text: '' },
                        subtitle: { text: '' },
                        chart: { type: 'spline', spacing: 1, backgroundColor: "#122A46", style: { color: "#fff" } },
                        yAxis: [
                            { title: { text: getTowenLayerAndHeight(item.key()), style: { color: "#fff" } }, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } } },
                            {
                                title: { text: '温度°C' }, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } },
                                opposite: true
                            }
                        ],
                        xAxis: {
                            title: { text: '' },
                            type: "datetime", labels: { style: { color: "#fff" } },
                            tickInterval: 5 * 60 * 1000
                        },
                        legend: {
                            enabled: !Props.mobile,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle'
                        },
                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br/>',
                            pointFormat: '{point.y}°C\r\n{point.x}',
                            pointFormatter: function (this: any) { return `${this.y}°C<br/>` + new Date(this.x).toStr("dd日HH时mm分") }
                        },
                        series: [{
                            yAxis: 1, name: '温度',
                            data: buildseries
                        }]
                    };
                    Highcharts.chart(itemDiv, chartOpent);
                }
                new Modal(container, { backdrop: true }).show();
            });
        })
    }
    async function showTowerTemperature() {
        return new Promise((resolve: (x: string) => void, reject) => {
            let startd = new Date(), endtd = new Date(starttime);
            startd.setTime(endtd.getTime() - 30 * 60 * 1000);
            backendMethod.getGradientTower(startd, endtd).then(tInfo => {
                var container = highchartModalDiv.current;
                query(".btn-group").show()
                var modalheader = container.querySelector(".modal-title") as HTMLElement;
                modalheader.innerText = "通量塔最近三十分钟温度、湿度、风速风向" + startd.toStr("(yyyy年MM月dd日HH时mm分至") + endtd.toStr("HH时mm分)");
                var modalbody = container.querySelector(".modal-body") as HTMLElement;
                while (modalbody.lastChild) modalbody.removeChild(modalbody.lastChild);
                var maxtime = LINQ.from(tInfo).max(t => t.DDATETIME.getTime());
                var allcharts: Highcharts.Chart[] = [];
                for (var item of LINQ.from(tInfo).where(t => t.LAYERS >= 0).groupBy(t => t.LAYERS).orderByDescending(t => t.key())) {
                    var itemDiv = modalbody.appendElement("div", { with: "800px", height: "190px" });
                    let temperatureseries: [number, number][] = [];
                    let humidityseries: [number, number][] = [];
                    let windSeries: { x: number, y: number, wd: number, marker?: any }[] = [];
                    let min = 99999, max = 0, tickInterval = 10
                    item.orderBy(t => t.DDATETIME).forEach(t => {

                        var hum = +t.HUMIDITY.toFixed(1)
                        var wd = t.WIND_DIRECTION
                        wd = wd > 360 ? wd - 360 : wd
                        min = hum < min ? hum : min;
                        max = hum > max ? hum : max;

                        temperatureseries.push([t.DDATETIME.getTime(), +t.TEMPERATURE.toFixed(1)]);
                        humidityseries.push([t.DDATETIME.getTime(), hum]);
                        windSeries.push({
                            x: t.DDATETIME.getTime(), y: +t.WIND_SPEED.toFixed(1), wd: wd,
                            marker: { symbol: "url(" + getArrowImgUrl(t.WIND_SPEED, wd) + ")" }
                        });
                    });
                    min = min < 0 ? 0 : min;
                    min = min - min % 10;
                    max = max - max % 10 + 10;
                    var span = max - min;
                    if (span < 20) tickInterval = 5;
                    if (span > 60) tickInterval = 20;
                    while (temperatureseries[temperatureseries.length - 1][0] < maxtime)
                        temperatureseries.push([temperatureseries[temperatureseries.length - 1][0] + 60000, NaN]);
                    while (humidityseries[humidityseries.length - 1][0] < maxtime)
                        humidityseries.push([humidityseries[humidityseries.length - 1][0] + 60000, NaN]);
                    while (windSeries[windSeries.length - 1].x < maxtime)
                        windSeries.push({ x: windSeries[windSeries.length - 1].x + 60000, y: NaN, wd: NaN });
                    let windbSeries = windSeries.map(t => [t.x, t.y, t.wd]);
                    var chartOpent: any = {
                        credits: { enabled: false },
                        title: { text: '' },
                        subtitle: { text: '' },
                        chart: { type: 'spline', spacing: 1, backgroundColor: "#122A46", style: { color: "#fff" } },
                        yAxis: [
                            { title: { text: getTowenLayerAndHeight(item.key()), style: { color: "#fff" } }, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } } },
                            {
                                title: { text: '温度°C', style: { color: "#2CAFFE" } }, gridLineColor: "#6E6D6C", labels: { style: { color: "#2CAFFE" } },
                                opposite: true, //tickInterval: 0.2
                            },
                            {
                                title: { text: '相对湿度', style: { color: "#544FC5" } }, gridLineColor: "#6E6D6C", labels: { style: { color: "#544FC5" } },
                                opposite: true,
                                min: min,
                                max: max,
                                tickInterval: tickInterval
                            },
                            {
                                title: { text: Props.mobile ? '' : "风速m/s", style: { color: "#00E272" } }, gridLineColor: "#6E6D6C",
                                opposite: true, labels: { style: { color: "#00E272" } }
                            }
                        ],
                        xAxis: {
                            title: { text: '' },
                            type: "datetime", labels: { style: { color: "#fff" } },
                            tickInterval: 5 * 60 * 1000
                        },
                        legend: {
                            enabled: !Props.mobile,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle',
                            // itemStyle: { color: "#fff" },
                            labelFormatter: function (this: any) {
                                return `<b style='color:${this.name == "相对湿度" ? "#544FC5" : this.name == "风速" ? "#00E272" : "#2CAFFE"}'>${this.name}</b>`
                            }
                        },
                        tooltip: {
                            shared: true,
                            useHTML: true,
                            dateTimeLabelFormats: {
                                second: '%Y-%m-%d %H:%M:%S',
                                minute: '%Y-%m-%d %H:%M',
                                hour: '%Y-%m-%d %H:%M',
                                day: '%Y-%m-%d',
                                week: '%Y-%m-%d',
                                month: '%Y-%m',
                                year: '%Y'
                            },

                            headerFormat: '<b>{point.key}</b><br/>',
                            pointFormatter: function (this: any) {
                                var unit = this.series.userOptions.tooltip.valueSuffix
                                if (this.series.name == "风速") {
                                    var wd = this.wd
                                    var level = weatherUtil.WindLevel(this.y);
                                    var wd_ = ""
                                    if (!isNaN(wd))
                                        wd_ = "(" + weatherUtil.WindDirectName(wd) +")";
                                    var html = `<span style="color:${this.color}">\u25CF</span> &nbsp;&nbsp;${this.series.name}： \t<b>${this.y}${unit}(${level}级)</b><br/>`
                                    html += `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;风向： <b>${wd.toFixed(0)}${wd_}`
                                    
                                    return html
                                }
                                else
                                    return `<span style="color:${this.color}">\u25CF</span> &nbsp;&nbsp;${this.series.name}： <b>${this.y}${unit}</b><br/>` 


                            }
                        },
                        series: [{
                            yAxis: 1, name: '温度', color: "#2CAFFE",
                            data: temperatureseries,
                            tooltip: { valueSuffix: ' °C' }
                        }, {
                            yAxis: 2, name: '相对湿度', color: "#544FC5",
                            data: humidityseries,
                            tooltip: { valueSuffix: ' %' }
                        }, {
                            yAxis: 3, name: '风速', color: "#00E272",
                            data: windSeries,
                            tooltip: { valueSuffix: ' m/s' }
                        }
                            //, {
                            //type: 'windbarb',
                            //data: windbSeries,
                            //name: '风向',
                            //color: Highcharts.getOptions().colors[1],
                            //showInLegend: false,
                            //tooltip: { valueSuffix: ' m/s' }
                            //}
                        ]
                    };
                    var c = Highcharts.chart(itemDiv, chartOpent);
                    allcharts.push(c);
                }
                container.querySelectorAll<HTMLInputElement>(".modal-header input").forEach(input => {
                    input.checked = true;
                    input.onchange = (e) => {
                        let ip = (e.target as HTMLInputElement);
                        for (var c of allcharts) {
                            let s = c.series.find(t => t.name == ip.value);
                            if (ip.checked) s.show();
                            else s.hide();
                            if (ip.value == "风速") {
                                let s = c.series.find(t => t.name == "风向");
                                if (ip.checked) s.show();
                                else s.hide();
                            }
                        }
                    }
                });
                new Modal(container, { backdrop: true }).show();
            });
        })
    }
    async function showTowerTemperature2() {
        return new Promise((resolve: (x: string) => void, reject) => {
            let startd = new Date(), endtd = new Date(starttime);
            startd.setTime(endtd.getTime() - 30 * 60 * 1000);
            backendMethod.getGradientTower(startd, endtd).then(tInfo => {
                var container = highchartModalDiv.current;
                query(".btn-group").hide()
                var modalheader = container.querySelector(".modal-header") as HTMLElement;
                var modalbody = container.querySelector(".modal-body") as HTMLElement;
                modalheader.innerText = "通道塔最近三十分钟温度（" + startd.toStr("yyyy年MM月dd日HH时mm分至") + endtd.toStr("HH时mm分") + ")";
                while (modalbody.lastChild) modalbody.removeChild(modalbody.lastChild);
                var maxtime = LINQ.from(tInfo).max(t => t.DDATETIME.getTime());
                for (var item of LINQ.from(tInfo).where(t => t.LAYERS >= 0).groupBy(t => t.LAYERS).orderByDescending(t => t.key())) {
                    var itemDiv = modalbody.appendElement("div", { with: "800px", height: "190px" });
                    let temperatureseries: [number, number][] = [];
                    item.orderBy(t => t.DDATETIME).forEach(t => {
                        temperatureseries.push([t.DDATETIME.getTime(), +t.TEMPERATURE.toFixed(1)]);
                    });
                    while (temperatureseries[temperatureseries.length - 1][0] < maxtime)
                        temperatureseries.push([temperatureseries[temperatureseries.length - 1][0] + 60000, NaN]);
                    var chartOpent: any = {
                        credits: { enabled: false },
                        title: { text: '' },
                        subtitle: { text: '' },
                        chart: { type: 'spline', spacing: 1, backgroundColor: "#122A46", style: { color: "#fff" } },
                        yAxis: [
                            { title: { text: getTowenLayerAndHeight(item.key()), style: { color: "#fff" } }, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } } },
                            {
                                title: { text: '温度°C' }, gridLineColor: "#6E6D6C", labels: { style: { color: "#fff" } },
                                opposite: true, tickInterval: 0.2
                            }
                        ],
                        xAxis: {
                            title: { text: '' },
                            type: "datetime", labels: { style: { color: "#fff" } },
                            tickInterval: 5 * 60 * 1000
                        },
                        legend: {
                            enabled: !Props.mobile,
                            layout: 'vertical',
                            align: 'right',
                            verticalAlign: 'middle',
                            itemStyle: { color: "#fff" },
                            itemHoverStyle: {
                                color: 'inherit' // 设置悬停时的颜色为继承父元素颜色
                            }
                        },
                        tooltip: {
                            shared: true
                        },
                        series: [{
                            yAxis: 1, name: '温度',
                            data: temperatureseries,
                            tooltip: { valueSuffix: ' °C' }
                        }]
                    };
                    Highcharts.chart(itemDiv, chartOpent);
                }
                new Modal(container, { backdrop: true }).show();
            });
        })
    }
    function getTowenLayerAndHeight(layer: number) {
        return "第" + layer +"层(" + meter[layer] + "m)"
    }

    function swiftForm(span: HTMLSpanElement) {
        let towerdiv = document.querySelector("#towerdiv") as HTMLDivElement;
        let carbody = towerdiv.querySelector(".card-body") as HTMLDivElement;
        let xbtn = towerdiv.querySelector(".bi.bi-x") as HTMLSpanElement;
        if (span.className == "bi bi-dash") {
            carbody.style.display = "none";
            span.className = "bi bi-app";
            towerdiv.style.width = "80px";
            xbtn.style.display = "none";
        } else if (span.className == "bi bi-app") {
            carbody.style.display = "";
            span.className = "bi bi-dash";
            towerdiv.style.width = "242px";
            xbtn.style.display = "";
        }
    }

    //生成风向杆图标
    function getArrowImgUrl(wdf: number, angle: number) {
        let canvas_ = document.createElement("canvas");
        let wh = 60
        canvas_.width = wh;
        canvas_.height = wh;
        let ctx = canvas_.getContext('2d');
        ctx.lineWidth = 1.5
        ctx.strokeStyle = "#00E272"//wdf >= 10.8 ? "#f00" : "#000";
        let canvasPen = new wdb(ctx);
        canvasPen.draw(wh * 0.5, wh * 0.5, wdf, angle, 1);
        ctx.stroke();
        return canvas_.toDataURL();
    }

    if (props.mobile)
        return <>
            <div id="towerdiv" className="card mb-3" style={{ position: 'absolute', color: "#fff", left: 0, bottom: 0, fontSize: 18, zIndex: 521, border: "2px solid #0E77D3" }} >
                <div className="card-header" style={{ padding: "3px", backgroundColor: "#0E77D3" }}>通量塔
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => query("#towerdiv").hide()}></span>
                </div>
                <div className="card-body" style={{ background: "#001A35", padding: 3, textAlign: 'center' }}>
                    <label style={{ margin: 6 }}><input type='checkbox' onChange={showMoment} /> <i style={{ color: "#2AA7FF" }} className="bi bi-clipboard2-data-fill"></i> 实况数据</label>
                    <label style={{ margin: 6 }}><input type='checkbox' onChange={showTowerWind} /> <i style={{ color: "#2AA7FF" }} className="bi bi-wind"></i> 风速风向</label>
                    <label style={{ margin: 6 }}><input type='checkbox' onChange={showTowerTemperature2} /> <i style={{ color: "#2AA7FF" }} className="bi bi-thermometer-sun"></i> 大气温度</label>
                    <label style={{ margin: 6 }}><input type='checkbox' onChange={showLandTemperature} /> <i style={{ color: "#2AA7FF" }} className="bi bi-thermometer-half"></i> 土壤温度</label>
                    <label style={{ margin: 6 }}><input type='checkbox' onChange={showTowerHumidity} /> <i style={{ color: "#2AA7FF" }} className="bi bi-moisture"></i> 空气湿度</label>
                </div>
            </div>
            <div className="modal" ref={towerDataDiv}  >
                <div className="modal-dialog" style={{ margin: 0 }}>
                    <div className="modal-content" style={{ background: "#43515F", minHeight: window.innerHeight - 3 }}>
                        <div className="modal-body" style={{ paddingBottom: 2 }}>
                            <div style={{ fontSize: 15, color: "#fff" }}>
                                <h6 style={{ lineHeight: "25px" }}> 三十米梯度塔观测数据
                                    &nbsp; <DdatePicker startDate={starttime} onChange={changeStarttime} className='reactDatePickerMettower' hourStep={1} minStep={5} />
                                    <i onClick={e => query("#towerDataDiv").hide()} style={{ display: 'none', fontSize: 25, float: 'right', cursor: 'pointer' }} className="bi bi-x-circle"></i></h6>
                                <div ref={highchartModalMomentDiv} style={{ height: 500 }}></div>
                                <table className='table table-bordered table-sm' style={{ fontSize: 13, color: "#fff" }}>
                                    <thead><tr><td>高度</td><td>温度°C</td><td style={{ width: 50 }}>土壤温度°C</td><td>湿度%</td><td>风速m/s</td><td>风向</td></tr></thead>
                                    <tbody id='gradientTowerTbody'></tbody>
                                </table>
                            </div></div>
                        <div className="modal-footer" style={{ padding: 2, backgroundColor: "#43515F", color: "#fff", borderTop: "none" }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ width: "100%", marginRight: 10 }} data-bs-dismiss="modal">关闭</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal modal-xl" ref={highchartModalDiv} >
                <div className="modal-dialog modal-dialog-scrollable" style={{ width: "100%", margin: 0 }}>
                    <div className="modal-content" style={{ width: "100%" }}>
                        <div className="modal-header" style={{ backgroundColor: "#144373", color: "#fff", borderBottom: "none" }}>
                            <h5 className="modal-title">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ background: "#132A49", borderBottom: "none", paddingLeft: 0 }}>
                            <p>Modal body text goes here.</p>
                        </div>
                        <div className="modal-footer" style={{ padding: 2, backgroundColor: "#144373", color: "#fff", borderTop: "none" }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ width: "100%", marginRight: 10 }} data-bs-dismiss="modal">关闭</button>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    else return <>
        <div id="towerdiv" className="card mb-3" style={{ color: "#000", background: "#ffffffb4", zIndex: 521, width: 242, border: "2px solid #0E77D3" }} >
            <div className="card-header" style={{ padding: "3px", color: "#fff", backgroundColor: "#0E77D3" }}>通量塔
                <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => query("#towerdiv").hide()}></span>
                <span style={{ float: "right", fontSize: 18 }} className='bi bi-dash' onClick={function (e) { swiftForm(e.target as any) }}></span>
            </div>
            <div className="card-body" style={{ padding: 0 }}>
                <DdatePicker startDate={starttime} setDateValue={setFunc} onChange={changeStarttime} className='reactDatePicker' hourStep={1} minStep={5} />
                <label style={{ margin: 6 }}><input type='checkbox' onChange={showMoment} /> <i style={{ color: "#2AA7FF" }} className="bi bi-clipboard2-data-fill"></i>  实况数据</label>
                <label style={{ margin: 6 }}><input type='checkbox' onChange={showTowerTemperature} /> <i style={{ color: "#2AA7FF" }} className="bi bi-wind"></i> 风速风向</label>
                <label style={{ margin: 6 }}><input type='checkbox' onChange={showTowerTemperature} /> <i style={{ color: "#2AA7FF" }} className="bi bi-thermometer-sun"></i> 大气温度</label>
                <label style={{ margin: 6 }}><input type='checkbox' onChange={showLandTemperature} /> <i style={{ color: "#2AA7FF" }} className="bi bi-thermometer-half"></i> 土壤温度</label>
                <label style={{ margin: 6 }}><input type='checkbox' onChange={showTowerTemperature} /> <i style={{ color: "#2AA7FF" }} className="bi bi-moisture"></i> 空气湿度</label>
            </div>
        </div>
        <div className="modal" ref={towerDataDiv}>
            <div className="modal-dialog" style={{ maxWidth: 1100 }}>
                <div className="modal-content" style={{ backgroundColor: "#133D64b8", width: 1000 }}>
                    <div className="modal-body" style={{ paddingBottom: 2 }}>
                        <div style={{ fontSize: 15, color: "#fff" }}>
                            <h6 style={{ lineHeight: "25px" }}> 三十米梯度塔观测数据
                                <i data-bs-dismiss="modal" style={{ display: '', fontSize: 25, float: 'right', cursor: 'pointer' }} className="bi bi-x-circle"></i></h6>
                            <table style={{ width: "100%" }}>
                                <tr><td><div ref={highchartModalMomentDiv} style={{ width: 530, height: 500, float: 'left' }}></div></td>
                                    <td><table className='table table-sm' style={{ color: "#fff", width: "100%" }}>
                                        <thead style={{ background: "#87A4AF" }}><tr><td>高度</td><td>温度°C</td><td>土壤温度°C</td><td>湿度%</td><td>风速m/s</td><td>风向</td></tr></thead>
                                        <tbody id='gradientTowerTbody'></tbody>
                                    </table></td></tr>
                            </table>
                        </div></div>
                </div>
            </div>
        </div>

        <div className="modal modal-xl" ref={highchartModalDiv}>
            <div className="modal-dialog modal-dialog-scrollable" style={{ width: "80%" }}>
                <div className="modal-content" style={{}}>
                    <div className="modal-header" style={{ backgroundColor: "#144373", color: "#fff", borderBottom: "none" }}>
                        <h6 className="modal-title" style={{ float: 'left', color: "#fff" }}>
                            通量塔最近三十分钟温度、湿度、风速风向
                        </h6>
                        <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                            <input type="checkbox" value="温度" className="btn-check" id="btncheck1" />
                            <label className="btn btn-outline-primary" style={{ borderColor: "gray" }} htmlFor="btncheck1">温度</label>

                            <input type="checkbox" value="相对湿度" className="btn-check" id="btncheck" />
                            <label className="btn btn-outline-primary" style={{ borderColor: "gray" }} htmlFor="btncheck">相对湿度</label>

                            <input type="checkbox" value="风速" className="btn-check" id="btncheck3" />
                            <label className="btn btn-outline-primary" style={{ borderColor: "gray" }} htmlFor="btncheck3">风速</label>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body" style={{ background: "#132A49", borderBottom: "none" }}>
                        <p>Modal body text goes here.</p>
                    </div>
                    <div className="modal-footer" style={{ padding: 2, backgroundColor: "#144373", color: "#fff", borderTop: "none" }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ width: "100%" }} data-bs-dismiss="modal">关闭</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}