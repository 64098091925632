import { Component, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import backendMethod from './API/backend';
import "./css/index.css";
import "./css/express.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Enumerable from 'linq';


export default class argoNews extends Component {
    private articleDiv = createRef<HTMLDivElement>();
    private breadcrumb = createRef<HTMLOListElement>();
    private allarcticleH5 = createRef<HTMLHRElement>();
    private selectClassify = "气候影响评价";
    private news: iNewsArticle[];
    public componentDidMount(): void {
        this.showArcticleList(this.selectClassify);

        document.querySelectorAll("#arcticleCalotag a").forEach((a: any, i) => {
            a.onclick = (e: any) => {
                this.selectClassify = e.target.innerText
                this.showArcticleList(this.selectClassify);
            }
        });
    }
    private showArcticleList(classify: string) {

        backendMethod.GetSimpleNews(this.selectClassify).then(news => {
            if (news.length) {
                this.news = news;
                this.breadcrumb.current.parentElement.style.display = "none";
                this.allarcticleH5.current.style.display = "";
                this.allarcticleH5.current.querySelector("span").innerText = classify

                var tab = this.articleDiv.current;
                while (tab.lastChild) tab.removeChild(tab.lastChild);

                var accordion = tab.appendElement("div");
                accordion.id = "list-group";

                if (classify == "气候公报") {
                    let self = this
                    var collapse = accordion.appendElement("ul");
                    collapse.id = "collapse" + new Date().getFullYear();
                    collapse.className = "panel-collapse collapse show ";
                    for (var obj of news) {
                        var li = collapse.appendElement("li");

                        var titleDiv = li.appendElement("div");
                        titleDiv.className = "title"
                        titleDiv.appendText(obj.title);

                        var dateDiv = li.appendElement("div");
                        dateDiv.className = "date1"
                        dateDiv.appendText(obj.pubtime.toStr("yyyy年M月d日"));

                        ((a) => {
                            li.onclick = () => {
                                self.showArticle(a.classify, a.id);
                            }
                        })(obj);
                    }
                }
                else {
                    var years = Enumerable.from(news).select(t => t.pubtime.getFullYear()).distinct().orderByDescending(y => y).toArray();
                    let self = this
                    years.forEach(function (year: number, idx: number) {

                        let subnews_ = Enumerable.from(news)
                            .where(t => t.pubtime.getFullYear() == year)
                            .orderByDescending(t => t.pubtime).toArray();
                        var panel = accordion.appendElement("div");
                        panel.id = "card" + year;
                        panel.className = "list-group-item list-group-item-action card-header";
                        panel.setAttribute("data-bs-toggle", "collapse"); 
                        panel.setAttribute("data-bs-target", "#collapse" + year);
                        panel.innerHTML = `<h4><a href="#collapse${year}">${year}年</a></h4>`

                        var collapse = accordion.appendElement("ul");
                        collapse.id = "collapse" + year;
                        collapse.className = "panel-collapse collapse";
                        if (idx == 0) {
                            collapse.className += " show"
                            collapse.setAttribute("active", "true")
                        }

                        for (var obj of subnews_) {
                            var li = collapse.appendElement("li");

                            var titleDiv = li.appendElement("div");
                            titleDiv.className = "title"
                            if (obj.classify == "气候影响评价" || obj.classify == "气候公报")
                                titleDiv.appendText(obj.title);
                            else
                                titleDiv.appendText(obj.filename);
                            var dateDiv = li.appendElement("div");
                            dateDiv.className = "date1"
                            dateDiv.appendText(obj.pubtime.toStr("yyyy年M月d日"));

                            ((a) => {
                                li.onclick = () => {
                                    self.showArticle(a.classify, a.id);
                                }
                            })(obj);
                        }

                        ((b: any) => {
                            panel.onclick = () => {
                                self.showAccordion(year)
                            }
                        })(year);
                    });
                }
            }
        });

    }

    private showAccordion = (year: number) => {
        //query("#accordion .panel-collapse.collapse").hide()
        var collapse = document.querySelector("#collapse" + year);
        var flag = collapse.hasAttribute("active")
        document.querySelectorAll(".panel-collapse.collapse").forEach(div  => {
            div.className = "panel-collapse collapse"
            div.removeAttribute("active")
        });
        if (!flag) {
            collapse.setAttribute("active", "true")
        }
    }

    private showArticle(classify: string, id: string) {
        backendMethod.GetNewsById(classify, id).then(b => {
            while (this.breadcrumb.current.lastChild)
                this.breadcrumb.current.removeChild(this.breadcrumb.current.lastChild);
            this.breadcrumb.current.parentElement.style.display = "";
            this.allarcticleH5.current.style.display = "none";
            let li = this.breadcrumb.current.appendElement("li"), a: HTMLAnchorElement;
            li.className = "breadcrumb-item";
            a = li.appendElement("a") as HTMLAnchorElement;
            a.appendText("为农服务");

            
            if (b.classify == "气候影响评价" || b.classify == "气候公报")
            {
                li = this.breadcrumb.current.appendElement("li");
                li.className = "breadcrumb-item";
                a = li.appendElement("a") as HTMLAnchorElement;
                a.appendText("气候监测预测");
            }

            li = this.breadcrumb.current.appendElement("li");
            li.className = "breadcrumb-item";
            a = li.appendElement("a") as HTMLAnchorElement;
            a.appendText(b.classify);

            li = this.breadcrumb.current.appendElement("li"); li.className = "breadcrumb-item";
            var span = li.appendElement("span", { color: "#000" });
            span.className = "d-inline-block text-truncate";
            span.style.maxWidth = "300px"
            span.appendText(b.title);

            if (b.classify == "气候影响评价" || b.classify == "气候公报") 
                this.articleDiv.current.innerHTML = this.getClimateImpactAssessmentContent(b.content, b.title, b.pubtime);
            else if (b.classify == "农用天气预报" || b.classify == "农气情报预报")
                this.articleDiv.current.innerHTML = this.getAgroWeatherContent(b.filename, b.title, b.content, b.pubtime);
            else //if (b.classify == "关键农事服务" || b.classify == "农气干旱监测")
                this.articleDiv.current.innerHTML = this.getKeyAgriculturalContent(b.filename, b.title, b.content, b.pubtime);
        });
    }

    private getClimateImpactAssessmentContent(content: string, title: string, date: Date) {

        var html = "";
        var json = JSON.parse(content)
        //console.log(json)
        html += "<div id='ClimateImpactAssessment'>"
        html += "<div class='title0'>" + title + "</div>"
        html += "<div class='unit_date'><div class='unit'>广东省气候中心</div><div class='date'>" + date.toStr("yyyy年M月d日") + "</div></div>"
        html += "<div id='summary'>"
        html += "<div class='title0'>摘要</div>"
        json.Summary.forEach(function (line: any, idx: number) {
            html += "<div class='text'>" + line.Text + "</div>"
        });
        html += "</div>"
        json.Paragraphs.forEach(function (para1: any) {
            html += "<div class='paragraph1'>"
            if (para1.Title)
                html += "<div class='title1'>" + para1.Title + "</div>"
            para1.Paragraphs.forEach(function (para2: any) {
                html += "<div class='paragraph2'>"
                if (para2.Title)
                    html += "<div class='title2'>" + para2.Title + "</div>"
                para2.ParagraphLines.forEach(function (line: any) {
                    if (line.Type == "string")
                        html += "<div class='text'>" + line.Text + "</div>"
                    else if (line.Type == "table") {
                        html += "<div class='ql-align-center clear'>"
                        html += "<table class='mytable'>"

                        let arr = line.Text.split('|');
                        html += "<legend>" + arr[0] + "</legend>"
                        for (let r = 1; r < arr.length; r++) {
                            
                            let td = r == 1 ? "th" : "td";
                            html += "<tr>"
                            let tds = arr[r].split(',');
                            tds.forEach(function (td_: any, ii: number) {
                                td = ii == 0 ? "th" : "td";
                                let td__ = td_.split('_');
                                if (td__.length > 1) {
                                    html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                        "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                }
                                else 
                                    html += "<" + td + ">" + td_+"</" + td + ">"
                            })

                            html += "</tr>"
                        }

                        html += "</table><div>&nbsp;</div></div>"
                    }
                    else {
                        html += "<div class='ql-align-center clear'>"

                        let arr = line.Text.split('|');
                        let img = arr[0].split(',');
                        let tip = arr[1].split(',');
                        let imgWidth = 100.0 / img.length
                        let tipWidth = 100.0 / tip.length
                        html += "<div class='clear'>"
                        img.forEach(function (img_: any) {
                            html += "<div class='img' style='width: " + imgWidth + "%'><img src='" + img_ + "' /></div>"
                        })
                        html += "</div><div class='clear'>"
                        tip.forEach(function (tip_: any) {
                            html += "<div class='img-tip' style='width: " + tipWidth +"%'>" + tip_ + "</div>"
                        })

                        html += "</div></div>"
                    }
                });
                para2.Paragraphs.forEach(function (para3: any) {
                    html += "<div class='title3'>" + para3.Title + "</div>"
                    para3.ParagraphLines.forEach(function (line: any) {
                        if (line.Type == "string")
                            html += "<div class='text'>" + line.Text + "</div>"
                        else if (line.Type == "table") {                            
                            html += "<div class='ql-align-center clear'>"
                            html += "<table class='mytable'>"

                            let arr = line.Text.split('|');
                            html += "<legend>" + arr[0] + "</legend>"
                            for (let r = 1; r < arr.length; r++) {

                                let td = r == 1 ? "th" : "td";
                                html += "<tr>"
                                let tds = arr[r].split(',');
                                tds.forEach(function (td_: any, ii: number) {
                                    td = ii == 0 ? "th" : "td";
                                    let td__ = td_.split('_');
                                    if (td__.length > 1) {
                                        html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                            "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                    }
                                    else
                                        html += "<" + td + ">" + td_ + "</" + td + ">"
                                })


                                html += "</tr>"
                            }

                            html += "</table><div>&nbsp;</div></div>"
                        }
                        else {
                            html += "<div class='ql-align-center clear'>"

                            let arr = line.Text.split('|');
                            let img = arr[0].split(',');
                            let tip = arr[1].split(',');
                            let imgWidth = 100.0 / img.length
                            let tipWidth = 100.0 / tip.length
                            html += "<div class='clear'>"
                            img.forEach(function (img_: any) {
                                html += "<div class='img' style='width: " + imgWidth + "%'><img src='" + img_ + "' /></div>"
                            })
                            html += "</div><div class='clear'>"
                            tip.forEach(function (tip_: any) {
                                html += "<div class='img-tip' style='width: " + tipWidth + "%'>" + tip_ + "</div>"
                            })

                            html += "</div></div>"
                        }
                    });
                });

                html += "<div>&nbsp;</div></div>"
            });
            html += "</div>"
        });

        html += "</div>"
        return html;
    }

    private getAgroWeatherContent(filename: string, title: string, content: string, date: Date) {

        var html = "";
        var json = JSON.parse(content)
        var numbers = filename.match(/\d+/g);

        //console.log(json)

        html += "<div id='AgroWeather'>"
        html += "<div class='title0'>" + title + "</div>"
        html += "<div class='no'>" + numbers[0] + "年 第" + numbers[1] + "期</div>"
        html += "<div class='unit_date'><div class='unit'>广东省气候中心</div><div class='date'>" + date.toStr("yyyy年M月d日") + "</div></div>"
        if (json.Summary.length > 0) {
            html += "<div id='summary'>"
            json.Summary.forEach(function (line: any, idx: number) {
                if (line.Type == "title")
                    html += "<div class='title" + (idx + 1) + "'>" + line.Text + "</div>"
                else
                    html += "<div class='text'>" + line.Text + "</div>"
            });
            html += "</div>"
        }
        json.Paragraphs.forEach(function (para1: any) {
            html += "<div class='paragraph1'>"
            if (para1.Title)
                html += "<div class='title1'>" + para1.Title + "</div>"
            para1.Paragraphs.forEach(function (para2: any) {
                html += "<div class='paragraph2'>"
                if (para2.Title)
                    html += "<div class='title2'>" + para2.Title + "</div>"
                para2.ParagraphLines.forEach(function (line: any) {
                    if (line.Type == "string")
                        html += "<div class='text'>" + line.Text + "</div>"
                    else if (line.Type == "table") {
                        html += "<div class='ql-align-center clear'>"
                        html += "<table class='mytable'>"

                        let arr = line.Text.split('|');
                        html += "<legend>" + arr[0] + "</legend>"
                        for (let r = 1; r < arr.length; r++) {

                            let td = r == 1 ? "th" : "td";
                            html += "<tr>"
                            let tds = arr[r].split(',');
                            tds.forEach(function (td_: any, ii: number) {
                                td = ii == 0 ? "th" : "td";
                                let td__ = td_.split('_');
                                if (td__.length > 1) {
                                    html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                        "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                }
                                else
                                    html += "<" + td + ">" + td_ + "</" + td + ">"
                            })

                            html += "</tr>"
                        }

                        html += "</table><div>&nbsp;</div></div>"
                    }
                    else {
                        html += "<div class='ql-align-center clear'>"

                        let arr = line.Text.split('|');
                        let img = arr[0].split(',');
                        let tip = arr[1].split(',');
                        let imgWidth = 100.0 / img.length
                        let tipWidth = 100.0 / tip.length
                        html += "<div class='clear'>"
                        img.forEach(function (img_: any) {
                            html += "<div class='img' style='width: " + imgWidth + "%'><img src='" + img_ + "' /></div>"
                        })
                        html += "</div><div class='clear'>"
                        tip.forEach(function (tip_: any) {
                            html += "<div class='img-tip' style='width: " + tipWidth + "%'>" + tip_ + "</div>"
                        })

                        html += "</div></div>"
                    }
                });
                para2.Paragraphs.forEach(function (para3: any) {
                    html += "<div class='title3'>" + para3.Title + "</div>"
                    para3.ParagraphLines.forEach(function (line: any) {
                        if (line.Type == "string")
                            html += "<div class='text'>" + line.Text + "</div>"
                        else if (line.Type == "table") {
                            html += "<div class='ql-align-center clear'>"
                            html += "<table class='mytable'>"

                            let arr = line.Text.split('|');
                            html += "<legend>" + arr[0] + "</legend>"
                            for (let r = 1; r < arr.length; r++) {

                                let td = r == 1 ? "th" : "td";
                                html += "<tr>"
                                let tds = arr[r].split(',');
                                tds.forEach(function (td_: any, ii: number) {
                                    td = ii == 0 ? "th" : "td";
                                    let td__ = td_.split('_');
                                    if (td__.length > 1) {
                                        html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                            "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                    }
                                    else
                                        html += "<" + td + ">" + td_ + "</" + td + ">"
                                })


                                html += "</tr>"
                            }

                            html += "</table><div>&nbsp;</div></div>"
                        }
                        else {
                            html += "<div class='ql-align-center clear'>"

                            let arr = line.Text.split('|');
                            let img = arr[0].split(',');
                            let tip = arr[1].split(',');
                            let imgWidth = 100.0 / img.length
                            let tipWidth = 100.0 / tip.length
                            html += "<div class='clear'>"
                            img.forEach(function (img_: any) {
                                html += "<div class='img' style='width: " + imgWidth + "%'><img src='" + img_ + "' /></div>"
                            })
                            html += "</div><div class='clear'>"
                            tip.forEach(function (tip_: any) {
                                html += "<div class='img-tip' style='width: " + tipWidth + "%'>" + tip_ + "</div>"
                            })

                            html += "</div></div>"
                        }
                    });
                });

                html += "<div>&nbsp;</div></div>"
            });
            html += "</div>"
        });

        html += "</div>"
        return html;
    }

    private getKeyAgriculturalContent(filename: string, title: string, content: string, date: Date) {

        var html = "";
        var json = JSON.parse(content)
        var numbers = filename.match(/\d+/g);
        
        //console.log(json)

        html += "<div id='KeyAgricultural'>"
        html += "<div class='title0'>" + title + "</div>"
        html += "<div class='no'>" + numbers[0] + "年 第" + numbers[1] + "期</div>"
        html += "<div class='unit_date'><div class='unit'>广东省气候中心</div><div class='date'>" + date.toStr("yyyy年M月d日") + "</div></div>"
        if (json.Summary.length > 0) {
            html += "<div id='summary'>"
            json.Summary.forEach(function (line: any, idx: number) {
                if (line.Type == "title")
                    html += "<div class='title" + (idx + 1) + "'>" + line.Text + "</div>"
                else
                    html += "<div class='text'>" + line.Text + "</div>"
            });
            html += "</div>"
        }
        json.Paragraphs.forEach(function (para1: any) {
            html += "<div class='paragraph1'>"
            if (para1.Title)
                html += "<div class='title1'>" + para1.Title + "</div>"
            para1.Paragraphs.forEach(function (para2: any) {
                html += "<div class='paragraph2'>"
                if (para2.Title)
                    html += "<div class='title2'>" + para2.Title + "</div>"
                para2.ParagraphLines.forEach(function (line: any) {
                    if (line.Type == "string")
                        html += "<div class='text'>" + line.Text + "</div>"
                    else if (line.Type == "table") {
                        html += "<div class='ql-align-center clear'>"
                        html += "<table class='mytable'>"

                        let arr = line.Text.split('|');
                        html += "<legend>" + arr[0] + "</legend>"
                        for (let r = 1; r < arr.length; r++) {

                            let td = r == 1 ? "th" : "td";
                            html += "<tr>"
                            let tds = arr[r].split(',');
                            tds.forEach(function (td_: any, ii: number) {
                                td = ii == 0 ? "th" : "td";
                                let td__ = td_.split('_');
                                if (td__.length > 1) {
                                    html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                        "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                }
                                else
                                    html += "<" + td + ">" + td_ + "</" + td + ">"
                            })

                            html += "</tr>"
                        }

                        html += "</table><div>&nbsp;</div></div>"
                    }
                    else {
                        html += "<div class='ql-align-center clear'>"

                        let arr = line.Text.split('|');
                        let img = arr[0].split(',');
                        let tip = arr[1].split(',');
                        let imgWidth = 100.0 / img.length
                        let tipWidth = 100.0 / tip.length
                        html += "<div class='clear'>"
                        img.forEach(function (img_: any) {
                            html += "<div class='img' style='width: " + imgWidth + "%'><img src='" + img_ + "' /></div>"
                        })
                        html += "</div><div class='clear'>"
                        tip.forEach(function (tip_: any) {
                            html += "<div class='img-tip' style='width: " + tipWidth + "%'>" + tip_ + "</div>"
                        })

                        html += "</div></div>"
                    }
                });
                para2.Paragraphs.forEach(function (para3: any) {
                    html += "<div class='title3'>" + para3.Title + "</div>"
                    para3.ParagraphLines.forEach(function (line: any) {
                        if (line.Type == "string")
                            html += "<div class='text'>" + line.Text + "</div>"
                        else if (line.Type == "table") {
                            html += "<div class='ql-align-center clear'>"
                            html += "<table class='mytable'>"

                            let arr = line.Text.split('|');
                            html += "<legend>" + arr[0] + "</legend>"
                            for (let r = 1; r < arr.length; r++) {

                                let td = r == 1 ? "th" : "td";
                                html += "<tr>"
                                let tds = arr[r].split(',');
                                tds.forEach(function (td_: any, ii: number) {
                                    td = ii == 0 ? "th" : "td";
                                    let td__ = td_.split('_');
                                    if (td__.length > 1) {
                                        html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                            "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                    }
                                    else
                                        html += "<" + td + ">" + td_ + "</" + td + ">"
                                })


                                html += "</tr>"
                            }

                            html += "</table><div>&nbsp;</div></div>"
                        }
                        else {
                            html += "<div class='ql-align-center clear'>"

                            let arr = line.Text.split('|');
                            let img = arr[0].split(',');
                            let tip = arr[1].split(',');
                            let imgWidth = 100.0 / img.length
                            let tipWidth = 100.0 / tip.length
                            html += "<div class='clear'>"
                            img.forEach(function (img_: any) {
                                html += "<div class='img' style='width: " + imgWidth + "%'><img src='" + img_ + "' /></div>"
                            })
                            html += "</div><div class='clear'>"
                            tip.forEach(function (tip_: any) {
                                html += "<div class='img-tip' style='width: " + tipWidth + "%'>" + tip_ + "</div>"
                            })

                            html += "</div></div>"
                        }
                    });
                });

                html += "<div>&nbsp;</div></div>"
            });
            html += "</div>"
        });

        html += "</div>"
        return html;
    }

    public render() {
        return <>
            <table style={{ width: "100%", height: window.innerHeight }}>
                <tr>
                    <td style={{ verticalAlign: "top", width: 250 }}>
                        <h5 style={{ borderBottom: "1px solid #e8f3ec", marginLeft: 20, marginTop: 20, padding: 10 }} className='font-weight-bold'>
                            <span style={{ borderBottom: "1px solid #000", padding: 10 }}>分类</span></h5>
                        <ol className='list-featured' id="arcticleCalotag" style={{ marginTop: 30 }}>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <span id="ClimateMonitorPredict" className="text-dark">气候监测预测</span>
                                    </h4>
                                    <p className="text-muted">
                                        <h5 className="">
                                            <a href="#" id="ClimateMonitorPredict_ClimateImpactAssessment" className="text-muted">气候影响评价</a>
                                        </h5>
                                        <h5 className="">
                                            <a href="#" id="ClimateMonitorPredict_ClimateReport" className="text-muted">气候公报</a>
                                        </h5>
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="arcticle03" className="text-dark">农气情报预报</a>
                                    </h4>
                                    <p className="text-muted">
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="arcticle03" className="text-dark">灾害预警评估</a>
                                    </h4>
                                    <p className="text-muted">
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="arcticle03" className="text-dark">农用天气预报</a>
                                    </h4>
                                    <p className="text-muted">
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="KeyAgricultural" className="text-dark">关键农事服务</a>
                                    </h4>
                                    <p className="text-muted">                                        
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="DroughtMonitor" className="text-dark">农气干旱监测</a>
                                    </h4>
                                    <p className="text-muted">
                                    </p>
                                </span>
                            </li>
                        </ol>
                    </td>
                    <td style={{ verticalAlign: "top" }}>
                        <h5 ref={this.allarcticleH5} style={{ borderBottom: "1px solid #e8f3ec", marginLeft: 0, marginTop: 20, padding: 10 }} className='font-weight-bold'>
                            <span style={{ borderBottom: "1px solid #000", padding: 10 }}>所有文章</span></h5>
                        <nav aria-label="breadcrumb" style={{ fontWeight: 'bold' }}>
                            <ol className="breadcrumb" ref={this.breadcrumb} style={{ marginLeft: 10 }}>
                            </ol>
                        </nav>

                        <div ref={this.articleDiv} className='ql-editor' style={{ padding: 10, overflow: "visible" }}></div>
                    </td></tr>
            </table>
        </>;
    }
}