export default class weatherUtil {
    //风速 m/s
    private static _windScale = [0, 0.3, 1.6, 3.4, 5.5, 8.0, 10.8, 13.9, 17.2, 20.8, 24.5, 28.5, 32.7, 37, 41.5, 46.2, 51, 56.1, 61.2];
    public static WindLevel(windSpeed: number) {
        if (isNaN(windSpeed)) return -1;
        for (var i = 0; i < this._windScale.length; i++) {
            if (windSpeed >= this._windScale[i]) continue;
            else return i - 1;
        }
        return 18;
    }
    public static RainLevel(rain: number) {
        if (rain == 0) return "无雨";
        else if (rain < 10) return "小雨";
        else if (rain < 25) return "中雨";
        else if (rain < 50) return "大雨";
        else if (rain < 100) return "暴雨";
        else if (rain < 250) return "大暴雨";
        return "特大暴雨";
    }
    public static WindDirectName(direct: number) {
        let s = 22.5;
        if (direct < 0 + s) return "北";
        if (direct < 45 + s) return "东北";
        if (direct < 90 + s) return "东";
        if (direct < 135 + s) return "东南";
        if (direct < 180 + s) return "南";
        if (direct < 225 + s) return "西南";
        if (direct < 270 + s) return "西";
        if (direct < 315 + s) return "西北";
        return "北";
    }
}