import Enumerable from 'linq';
import weatherUtil from "../util/weatherUtil"

var wind0 = [
    {
        value: 22.7, color: "red",
        code: "wind",
        text: "",
        warn: "9级以上，重度风灾"
    }, {
        value: 18.9, color: "orangered",
        code: "wind",
        text: "",
        warn: "8-9级风，中度风灾"
    },
    {
        value: 13.9, color: "yellow",
        code: "wind",
        text: "",
        warn: "7-8级风，轻度风灾"
    }, {
        value: 0,
        code: "normal", color: "green",
        text: "",
        warn: "7级风以下，没有影响"
    }
]

var wind = [
    {
        code: "greenhouse0", name: "塑料中棚、单体钢架大棚",
        wind: [
            {
                value: 8, color: "red",
                code: "wind",
                text: "",
                warn: "重度风灾"
            }, {
                value: 6, color: "orangered",
                code: "wind",
                text: "",
                warn: "中度风灾"
            },
            {
                value: 5, color: "yellow",
                code: "wind",
                text: "",
                warn: "轻度风灾"
            }
        ]
    },
    {
        code: "greenhouse1", name: "连栋钢架大棚",
        wind: [
            {
                value: 11, color: "red",
                code: "wind",
                text: "",
                warn: "重度风灾"
            }, {
                value: 9, color: "orangered",
                code: "wind",
                text: "",
                warn: "中度风灾"
            },
            {
                value: 8, color: "yellow",
                code: "wind",
                text: "",
                warn: "轻度风灾"
            }
        ]
    },
    {
        code: "greenhouse2", name: "玻璃温室",
        wind: [
            {
                value: 12, color: "red",
                code: "wind",
                text: "",
                warn: "重度风灾"
            }, {
                value: 10, color: "orangered",
                code: "wind",
                text: "",
                warn: "中度风灾"
            },
            {
                value: 9, color: "yellow",
                code: "wind",
                text: "",
                warn: "轻度风灾"
            }
        ]
    }
]
var coldwind = [
    {
        avgt: 22, avgtdays: 3, mint: 17, mintdays: 0, raindays: 0,
        color: "yellow",
        code: "coldwind",
        text: "",
        warn: "轻度寒露风(干冷型)"
    },
    {
        avgt: 22, avgtdays: 2, mint: 17, mintdays: 1, raindays: 0,
        color: "yellow",
        code: "coldwind",
        text: "",
        warn: "轻度寒露风(干冷型)"
    }, {
        avgt: 20, avgtdays: 3, mint: 17, mintdays: 0, raindays: 0,
        color: "orange",
        code: "coldwind",
        text: "",
        warn: "中度寒露风(干冷型)"
    }, {
        avgt: 20, avgtdays: 2, mint: 17, mintdays: 1, raindays: 0,
        color: "orange",
        code: "coldwind",
        text: "",
        warn: "中度寒露风(干冷型)"
    },
    {
        avgt: 20, avgtdays: 6, mint: 17, mintdays: 0, raindays: 0,
        color: "orangered",
        code: "coldwind",
        text: "",
        warn: "重度寒露风(干冷型)"
    },

    {
        avgt: 23, avgtdays: 3, mint: 17, mintdays: 0, raindays: 1,
        color: "yellow",
        code: "coldwind",
        text: "",
        warn: "轻度寒露风(湿冷型)"
    },
    {
        avgt: 23, avgtdays: 2, mint: 17, mintdays: 1, raindays: 1,
        color: "yellow",
        code: "coldwind",
        text: "",
        warn: "轻度寒露风(湿冷型)"
    }, {
        avgt: 21, avgtdays: 3, mint: 17, mintdays: 0, raindays: 1,
        color: "orange",
        code: "coldwind",
        text: "",
        warn: "中度寒露风(湿冷型)"
    }, {
        avgt: 21, avgtdays: 2, mint: 17, mintdays: 1, raindays: 1,
        color: "orange",
        code: "coldwind",
        text: "",
        warn: "中度寒露风(湿冷型)"
    },
    {
        avgt: 21, avgtdays: 6, mint: 17, mintdays: 0, raindays: 3,
        color: "orangered",
        code: "coldwind",
        text: "",
        warn: "重度寒露风(湿冷型)"
    }
]
var storm = [
    {
        value: 1, color: "darkred",
        code: "storm",
        text: "",
        warn: "特大暴雨"
    }, {
        value: 0.75, color: "orangered",
        code: "storm",
        text: "",
        warn: "重度暴雨"
    },
    {
        value: 0.5, color: "orange",
        code: "storm",
        text: "",
        warn: "中度暴雨"
    }, {
        value: 0.25,
        code: "storm", color: "yellow",
        text: "",
        warn: "轻度暴雨"
    }
]
var drought = [
    {
        value: -0.9, color: "darkred",
        code: "drought",
        text: "",
        warn: "特旱"
    }, {
        value: -0.8, color: "orangered",
        code: "drought",
        text: "",
        warn: "重旱"
    },
    {
        value: -0.6, color: "orange",
        code: "drought",
        text: "",
        warn: "中旱"
    }, {
        value: -0.4,
        code: "drought", color: "yellow",
        text: "",
        warn: "轻旱"
    }
]
var fruitDanger0 = [
    {
        code: "lichee", name: "荔枝",
        wind: wind,
        temperature: [
            {
                code: "cold", color: "red",
                value: -3, text: "花器官、老熟枝条受冻害严重，树冠叶片受害率达80 %~100 %，主枝枯或主干裂皮",
                warn: "≤-3~-4℃冻害致死温度"
            }, {
                code: "cold", color: "red",
                value: -2, text: "枝梢受冻害，幼嫩叶片重害，老熟枝叶受害 (-2~-3℃，树冠叶片受害率可达51 %~81 %)",
                warn: "-2℃冻害的临界温度"
            }, {
                code: "cold", color: "red",
                value: 0, text: "≤0℃晚秋梢或冬梢幼嫩叶片受害",
                warn: "≤0℃生长活动停止"
            }, {
                code: "cold", color: "orangered",
                value: 3, text: "",
                warn: "0-3℃受到不同程度的寒害"
            }, {
                code: "cold", color: "yellow",
                value: 10, text: "0-10℃休眠期适宜温度",
                warn: "10℃以下生长停滞"
            }
        ]
    },
    {
        code: "rice", name: "水稻",
        wind: wind,
        temperature: [
            {
                code: "normal", color: "green",
                value: 10, text: "",
                warn: "水稻芽期生长临界温度为粳稻≥10℃，籼稻≥12℃"
            }, {
                code: "normal", color: "green",
                value: 15, text: "",
                warn: "水稻灌浆期生长临界温度为粳稻≥15℃，籼稻≥17℃"
            }, {
                code: "normal", color: "green",
                value: 20, text: "",
                warn: "水稻孕穗期生长临界温度为粳稻≥20℃，籼稻≥22℃"
            }, {
                code: "", color: "red",
                value: 35, text: "",
                warn: "35℃作为危害水稻正常生长的临界温度"
            }
        ]
    },
    {
        code: "longan", name: "龙眼",
        wind: wind,
        temperature: [
            {
                value: -4,
                code: "cold", color: "red",
                text: "",
                warn: "-4.0℃时严重受冻"
            }, {
                value: -3,
                code: "cold", color: "orangered",
                text: "",
                warn: "-1.0~-3.0时大树受冻害"
            },
            {
                value: 0,
                code: "cold", color: "yellow",
                text: "",
                warn: "气温0℃时幼苗受冻害"
            }
        ]
    },
    {
        code: "canola", name: "油菜",
        wind: wind,
        temperature: [
            {
                value: -7.1, color: "red",
                code: "cold",
                text: "",
                warn: "气温-7℃以下时严重冻害"
            }, {
                value: -5.1,
                code: "cold", color: "orangered",
                text: "",
                warn: "-5.1℃~-7.0℃时中度冻害"
            }, {
                value: -3, color: "yellow",
                code: "cold",
                text: "",
                warn: "-3.0℃至-5℃时轻微冻害"
            }, {
                value: 0, color: "#C7C33B",
                code: "cold",
                text: "",
                warn: "0℃以下叶片冻伤、植株死亡或不能正常开花授粉"
            }
        ]
    },
    {
        code: "orange", name: "柑桔",
        wind: wind,
        temperature: [
            {
                value: -11, color: "red",
                code: "cold",
                text: "",
                warn: "气温-11℃以下时严重冻害"
            }, {
                value: -9.1,
                code: "cold", color: "orangered",
                text: "",
                warn: "-9.1℃~-11.0℃时中度冻害"
            }, {
                value: -7, color: "yellow",
                code: "cold",
                text: "",
                warn: "-7.0℃至-9℃时轻度冻害"
            }
        ]
    }
];
var fruitDanger1 = [
    {
        code: "lichee", name: "荔枝 ", 
        coldwind: coldwind,
        date: ["11-01", "3-1"],
        period: "休眠期",
        temperature: [
            {
                code: "cold", color: "darkred",
                value: -4, text: "",
                warn: "严重冻害",
                warnMsg: "≤-3~-4℃冻害致死温度"
            }, {
                code: "cold", color: "red",
                value: -2, text: "",
                warn: "冻害",
                warnMsg: "-2℃冻害的临界温度"
            }, {
                code: "cold", color: "orangered",
                value: 0, text: "",
                warn: "低温冷害",
                warnMsg: "≤0℃生长活动停止"
            }, {
                code: "cold", color: "yellow",
                value: 4, text: "",
                warn: "寒害",
                warnMsg: "0-3℃受到不同程度的寒害"
            }
        ]
    },
    {
        code: "rice", name: "水稻 ",
        date: ["3-1", "10-31"],
        coldwind: coldwind,
        temperature: [
            {
                code: "normal", color: "green",
                value: 10, text: "",
                warn: "水稻芽期生长临界温度为粳稻≥10℃，籼稻≥12℃",
                warnMsg: "水稻芽期生长临界温度为粳稻≥10℃，籼稻≥12℃"
            }, {
                code: "normal", color: "green",
                value: 15, text: "",
                warn: "水稻灌浆期生长临界温度为粳稻≥15℃，籼稻≥17℃",
                warnMsg: "水稻灌浆期生长临界温度为粳稻≥15℃，籼稻≥17℃"
            }, {
                code: "normal", color: "green",
                value: 20, text: "",
                warn: "水稻孕穗期生长临界温度为粳稻≥20℃，籼稻≥22℃",
                warnMsg: "水稻孕穗期生长临界温度为粳稻≥20℃，籼稻≥22℃"
            }
        ]
    },
    {
        code: "rice", name: "水稻 ",
        date: ["3-1", "3-31"],
        period: "‌幼苗期",
        temperature: [
            {
                value: -5,
                code: "cold", color: "red",
                text: "",
                warn: "重度倒春寒",
                warnMsg: "本旬平均气温比同期旬平均气温低5℃以上，且低于前一旬平均气温，受重度倒春寒"
            }, {
                value: -3.5,
                code: "cold", color: "orangered",
                text: "",
                warn: "中度倒春寒",
                warnMsg: "本旬平均气温比同期旬平均气温低3.5℃以上，且低于前一旬平均气温，受中度倒春寒"
            },
            {
                value: -2,
                code: "cold", color: "yellow",
                text: "",
                warn: "轻度倒春寒",
                warnMsg: "本旬平均气温比同期旬平均气温低2℃以上，且低于前一旬平均气温，受轻度倒春寒"
            }
        ]
    },
    {
        code: "rice", name: "水稻 ",
        date: ["5-1", "10-31"],
        temperature: [{
                code: "hot", color: "yellow",
                avgt: 30, maxt: 35, days: 3, value: 30,
                text: "",
                warn: "轻度热害",
                warnMsg: "日平均气温≥30℃或日最高气温≥35℃，且持续3-5天，水稻受轻度热害"
            }, {
                code: "hot", color: "orangered",
                avgt: 30, maxt: 35, days: 5, value: 30,
                text: "",
                warn: "中度热害",
                warnMsg: "日平均气温≥30℃或日最高气温≥35℃，且持续6-8天，水稻中度热害"
            }, {
                code: "hot", color: "red",
                avgt: 30, maxt: 35, days: 8, value: 30,
                text: "",
                warn: "重度热害",
                warnMsg: "日平均气温≥30℃或日最高气温≥35℃，且持续8天以上，重度热害"
            }
        ]
    },
    {
        code: "longan", name: "龙眼 ",
        coldwind: coldwind,
        temperature: [
            {
                value: -4,
                code: "cold", color: "red",
                text: "",
                warn: "重度冻害",
                warnMsg: "-4.0℃时严重受冻"
            }, {
                value: -3,
                code: "cold", color: "orangered",
                text: "",
                warn: "中度冻害",
                warnMsg: "-1.0~-3.0时大树受冻害"
            },
            {
                value: 0,
                code: "cold", color: "yellow",
                text: "",
                warn: "轻度冻害",
                warnMsg: "0℃时幼苗受冻"
            }
        ]
    },
    {
        code: "canola", name: "油菜 ",
        coldwind: coldwind,
        date: ["11-01", "2-1"],
        period: "苗期",
        temperature: [
            {
                value: -7.1, color: "red",
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "气温-7℃以下时严重冻害"
            }, {
                value: -5.1,
                code: "cold", color: "orangered",
                text: "",
                warn: "中度冻害",
                warnMsg: "-5.1℃~-7.0℃时中度冻害"
            }, {
                value: -3, color: "yellow",
                code: "cold",
                text: "",
                warn: "轻微冻害",
                warnMsg: "-3.0℃至-5℃时轻微冻害"
            }, {
                value: 0, color: "#C7C33B",
                code: "cold",
                text: "",
                warn: "低温灾害",
                warnMsg: "0℃以下叶片冻伤、植株死亡或不能正常开花授粉"
            }
        ]
    },
];

export var fruitDanger = function (flag: boolean = false) {
    let dangers: any[] = [];
    let date = new Date()
    let year = date.getFullYear() + "-"
    //dangers = dangers.concat(fruitDanger1)
    for (let item of fruitDanger1) {
        if (item.date) {
            let date0 = new Date(year + item.date[0])
            let date1 = new Date(year + item.date[1])
            if (date1 < date0) date1.setFullYear(date.getFullYear() + 1)
            date1.setDate(date1.getDate() + 1)

            if (date >= date0 && date < date1) {
                if (item.code == "rice")
                {
                    var ff = false;
                    for (let item1 of dangers) {
                        if (item1.code == "rice") {
                            item1.period = item.period;
                            item1.temperature = item1.temperature.concat(item.temperature);
                            ff = true;
                        }
                    }
                    if (!ff)
                        dangers.push(item)
                }
                else
                    dangers.push(item)
            }
        }
        else
            dangers.push(item)
    }

    for (let item of vegetableDanger) {
        let date0 = new Date(year + item.date[0])
        let date1 = new Date(year + item.date[1])
        if (date1 < date0) date1.setFullYear(date.getFullYear() + 1)
        date1.setDate(date1.getDate() + 1)

        if (date >= date0 && date < date1) {
            dangers.push(item)
        }
        else {
            date0 = new Date(year + item.date1[0])
            date1 = new Date(year + item.date1[1])
            if (date1 < date0) date1.setFullYear(date.getFullYear() + 1)
            date1.setDate(date1.getDate() + 1)

            if (date >= date0 && date < date1) {
                dangers.push({
                    vegetable: true,
                    code: item.code, code1: item.code1, name: item.name, period: item.period1,
                    coldwind: item.coldwind,
                    temperature: item.temperature1
                })
            } else {
                if (flag) {
                    dangers.push({
                        vegetable: true,
                        code: item.code, code1: item.code1, name: item.name, 
                        temperature: item.temperature
                    })
                } else {
                    dangers.push({
                        vegetable: true,
                        code: item.code, code1: item.code1, name: item.name,
                        coldwind: item.coldwind,
                        temperature: [vegetableNormal]
                    })
                }
            }
        }
    }

    for (let obj of dangers) {
        obj.storm = storm
    }
    dangers = dangers.concat(wind)

    return dangers;
}
export var fruitDanger2 = function (flag: boolean = false) {
    let dangers: any[] = [];
    let date = new Date()
    let year = date.getFullYear() + "-"
    var ff = false;
    for (let item of fruitDanger1) {
        if (item.code == "rice") {
            let date0 = new Date(year + item.date[0])
            let date1 = new Date(year + item.date[1])
            if (date1 < date0) date1.setFullYear(date.getFullYear() + 1)
            date1.setDate(date1.getDate() + 1)

            if (date >= date0 && date < date1) {

                for (let item1 of dangers) {
                    if (item1.code == "rice") {
                        let date0_ = new Date(year + item1.date[0])
                        let date1_ = new Date(year + item1.date[1])
                        if (date1_ < date0_) date1_.setFullYear(date.getFullYear() + 1)
                        date1_.setDate(date1_.getDate() + 1)

                        if (date >= date0_ && date < date1_) {
                            item1.period = item.period;
                            item1.temperature = item1.temperature.concat(item.temperature);
                            ff = true;
                        }
                    }
                }
                if (!ff)
                    dangers.push(item)

            }

        }
        else
            dangers.push(item)


    }

    for (let item of vegetableDanger) {
        let date0 = new Date(year + item.date[0])
        let date1 = new Date(year + item.date[1])
        if (date1 < date0) date1.setFullYear(date.getFullYear() + 1)
        date1.setDate(date1.getDate() + 1)

        if (date >= date0 && date < date1) {
            dangers.push(item)
        }
        else {
            date0 = new Date(year + item.date1[0])
            date1 = new Date(year + item.date1[1])
            if (date1 < date0) date1.setFullYear(date.getFullYear() + 1)
            date1.setDate(date1.getDate() + 1)

            if (date >= date0 && date < date1) {
                dangers.push({
                    vegetable: true,
                    code: item.code, code1: item.code1, name: item.name, period: item.period1,
                    coldwind: item.coldwind,
                    temperature: item.temperature1
                })
            } else {
                if (flag) {
                    dangers.push({
                        vegetable: true,
                        code: item.code, code1: item.code1, name: item.name,
                        temperature: item.temperature
                    })
                } else {
                    dangers.push()
                }
            }
        }
    }

    return dangers;
}

var vegetableDanger = [
    {
        vegetable: true,
        code: "vegetable", name: "番茄 ", code1:"tomato",
        coldwind: coldwind,
        date:["11-01", "12-31"],
        date1: ["6-01", "10-31"],
        period: "苗期",
        period1: "花果期",
        temperature: [//苗期
            {
                value: 0, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<0℃,且持续30小时以上，严重冻害"
            }, {
                value: 3,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温0-3℃,且持续20小时以上，中度冻害"
            }, {
                value: 5, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温3-5℃，轻度冻害"
            }
        ],
        temperature1: [//花果期
            {
                value: 0, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<0℃,且持续30小时以上，严重冻害"
            }, {
                value: 5,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温0-5℃,且持续20小时以上，中度冻害"
            }, {
                value: 8, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温6-8℃，轻度冻害"
            }
        ]
    },
    {
        vegetable: true,
        code: "vegetable", name: "黄瓜 ", code1: "cucumber",
        coldwind: coldwind,
        date: ["1-01", "3-31"],
        date1: ["6-01", "9-30"],
        period: "苗期",
        period1: "花果期",
        temperature: [//苗期
            {
                value: 1, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<1℃,且持续30小时以上，严重冻害"
            }, {
                value: 5,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温1-5℃,且持续20小时以上，中度冻害"
            }, {
                value: 8, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温6-8℃，轻度冻害"
            }
        ],
        temperature1: [//花果期
            {
                value: 0, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<0℃,且持续30小时以上，严重冻害"
            }, {
                value: 8,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温1-8℃,且持续20小时以上，中度冻害"
            }, {
                value: 10, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温8-10℃，轻度冻害"
            }
        ]
    },
    {
        vegetable: true,
        code: "vegetable", name: "甜椒 ", code1: "sweetpepper",
        coldwind: coldwind,
        date: ["12-01", "3-31"],
        date1: ["7-01", "11-30"],
        period: "苗期",
        period1: "花果期",
        temperature: [//苗期
            {
                value: 3, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<3℃,且持续30小时以上，严重冻害"
            }, {
                value: 6,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温3-6℃,且持续20小时以上，中度冻害"
            }, {
                value: 10, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温6-8℃，轻度冻害"
            }
        ],
        temperature1: [//花果期
            {
                value: 2, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<2℃,且持续30小时以上，严重冻害"
            }, {
                value: 8,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温3-8℃,且持续20小时以上，中度冻害"
            }, {
                value: 12, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温8-12℃，轻度冻害"
            }
        ]
    },
    {
        vegetable: true,
        code: "vegetable", name: "茄子 ", code1:"eggplant",
        coldwind: coldwind,
        date: ["12-01", "1-31"],
        date1: ["6-01", "8-31"],
        period: "苗期",
        period1: "花果期",
        temperature: [//苗期
            {
                value: 3, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<3℃,且持续30小时以上，严重冻害"
            }, {
                value: 6,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温3-8℃,且持续20小时以上，中度冻害"
            }, {
                value: 10, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温6-10℃，轻度冻害"
            }
        ],
        temperature1: [//花果期
            {
                value: 2, color: "red",
                continue: 30,
                code: "cold",
                text: "",
                warn: "严重冻害",
                warnMsg: "最低气温<2℃,且持续30小时以上，严重冻害"
            }, {
                value: 8,
                code: "cold", color: "orangered",
                continue: 20,
                text: "",
                warn: "中度冻害",
                warnMsg: "最低气温2-8℃,且持续20小时以上，中度冻害"
            }, {
                value: 12, color: "yellow",
                continue: -1,
                code: "cold",
                text: "",
                warn: "轻度冻害",
                warnMsg: "最低气温8-12℃，轻度冻害"
            }
        ]
    }
];
var vegetableNormal =
{
    value: -999, color: "green",
    continue: 100,
    code: "cold",
    text: "",
    warn: "正常",
    warnMsg: ""
}

export var disaster2Plant = [
    { disaster: "cold", plants: ["lichee", "rice", "longan", "canola", "vegetable"] },//, "tomato", "cucumber", "sweetpepper", "eggplant"
    { disaster: "hot", plants: ["rice"] },
    { disaster: "wind", plants: ["greenhouse0", "greenhouse1", "greenhouse2"] },
    { disaster: "coldwind", plants: ["-1"] },
    { disaster: "drought", plants: ["-1"] },
    { disaster: "storm", plants: ["-1"] }
]


export function getStreetDisaster(fruitCode: string, minthour: number, temperature: number[], wind: number[], rain: number[], storm: any[],
    p0: number, p1: number, avgt_30: number, avgt_0: number, avgt_1: number) {
    let dayTemp: any = []
    let cc = 24, skip = 24;
    for (let i = 0; i < 10; i++) {
        if (i < 3) {
            skip = i*cc;
        } else {
            cc = 8;
            skip = 72 + (i - 3) * cc;
        }
        var temps = temperature.slice(skip, (i + 1) * cc);
        if (temps.length > 0) {
            let avgt = +Enumerable.from(temps).max().toFixed(1);
            let maxt = +Enumerable.from(temps).max().toFixed(1);
            let mint_ = +Enumerable.from(temps).min().toFixed(1);
            var rain_ = rain.slice(skip, (i + 1) * cc);
            let r24h = +Enumerable.from(rain_).sum().toFixed(1);

            dayTemp.push({ day: i, avgt: avgt, maxt: maxt, mint: mint_, r24h: r24h });
        }
    }

    let temp_ = temperature.slice(0, minthour);
    let mint = +Enumerable.from(temp_).min().toFixed(1);
    let mint_5_hour = 0;
    for (let t of temp_) {
        if (t <= 5) mint_5_hour++
        else mint_5_hour=0
    }


    let maxWind = +Enumerable.from(wind).max().toFixed(2)
    let windScale = weatherUtil.WindLevel(maxWind);

    var warnings: iFruitWarning[] = [];
    var fruitDanger_ = fruitDanger()
    for (let fruit of fruitDanger_) {
        if (fruitCode && fruit.code != fruitCode)
            continue;
        if (fruit.temperature) {
            var hotObj:any = {}
            for (let tw of fruit.temperature) {
                if (tw.code == "cold") {
                    if (fruit.name == "水稻") {
                        let t_diff = avgt_1 - avgt_30;
                        let t_diff1 = avgt_1 - avgt_0
                        if (t_diff1 < 0 && t_diff < 2) {
                            if (t_diff <= tw.value) {
                                let msg = "本旬预报气温比同期平均低" + t_diff.toFixed(1) + "℃,比上一旬低" + t_diff1.toFixed(1) + "℃<br/>"
                                warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: t_diff, warnMsg: msg });
                                break;
                            }
                        }

                    }                    
                    else if (fruit.code == "vegetable") {
                        if (mint < tw.value && mint_5_hour > tw.continue) {
                            let continue_ = tw.continue > -1 ? ",≤5℃时间持续" + mint_5_hour + "小时<br/>" :"<br/>"
                            let msg = "预报气温" + mint.toFixed(1) + "℃" + continue_ + fruit.name
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: mint, warnMsg: msg });

                        }} else {
                        if (mint <= tw.value) {
                            let msg = "预报气温" + mint.toFixed(1) + "℃<br/>" + fruit.name
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: mint, warnMsg: msg });
                        }
                    }
                }
                else if (tw.code == "hot") {
                    var days = 0;
                    for (let obj of dayTemp) {
                        if (obj.avgt >= tw.avgt || obj.maxt >= tw.maxt) {
                            days++;
                        }
                        else break;
                        if (days > tw.days)
                            hotObj = { fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.maxt };
                    }

                }
                else {
                    for (let obj of dayTemp) {
                        if (obj.maxt >= tw.maxt) {
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.maxt });
                        }
                    }
                }
            }
            if (hotObj.fruitCode)
                warnings.push(hotObj)

        }
        if (fruit.wind) {
            for (let tw of fruit.wind) {
                //var windScale = weatherUtil.WindLevel(maxWind);
                if (windScale >= tw.value) {
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: maxWind });
                    break;
                }
            }
        }
        if (fruit.coldwind) {
            for (let tw of fruit.coldwind) {
                var windObj: any = {}
                var avgtdays = 0, mintdays = 0, raindays = 0;
                for (let obj of dayTemp) { 
                    if (obj.avgt <= tw.avgt)
                        avgtdays = avgtdays++;
                    else break

                    if (obj.mint <= tw.mint)
                        mintdays = mintdays++;
                    else break

                    raindays = obj.r24h >0 ? raindays + 1 : 0;
                    if (avgtdays >= tw.avgtdays && mintdays >= tw.mintdays && raindays >= tw.raindays) {
                        let msg = "预测未来" + avgtdays + "天日平均气温&le;" + tw.avgt + "℃<br/>"
                        msg += mintdays + "天日最低气温&le;" + tw.mint + "℃<br/>"
                        if (fruit.warn.indexOf("湿冷型") > -1)
                            msg += "降雨日" + raindays + "天<br/>"

                        windObj = { fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, warnMsg: msg };

                    }else break;
                }
            }
            if (windObj.fruitCode)
                warnings.push(windObj)
        }

        if (fruit.storm) {
            for (let tw of fruit.storm) {
                for (let obj of storm) {
                    if (obj.R >= tw.value) {
                        let msg = "预测未来" + obj.StormDay +"天持续暴雨<br/>"
                        msg += "暴雨站数" + obj.StormSt + "<br/>"

                        warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.R, warnMsg: msg });
                    }
                }
            }
        }

        if (fruit.drought) {
            let p = (p1 - p0) / p0;
            for (let tw of fruit.drought) {
                if (p <= tw.value) {
                    let msg = "预测未来10天降雨" + p1.toFixed(1) + "mm,比同期少" + Math.abs(p1 - p0).toFixed(1) +"mm<br/>"

                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: p, warnMsg: msg });
                    break
                }
            }
        }
    }
    return warnings;
}

export function getStreetDisaster1_(obtid: string, start: Date, 
    data: iLOCALOBTHOURD[],  data1: number[]) {
    let dayTemp: any = []
    let stormDay = 0;
    start.setDate(start.getDate()-10)
    for (let i = 0; i < 10; i++) {
        let date_ = new Date(start.toStr("yyyy-MM-dd"))
        date_.setDate(date_.getDate() + i)
        let data_ = Enumerable.from(data).where(p => p.OBTID == obtid
            && p.DDATETIME.toStr("yyyyMMdd") == date_.toStr("yyyyMMdd")).toArray()
        if (data_.length > 0) {
            let avgt = +Enumerable.from(data_).max(t => t.T).toFixed(1);
            let maxt = +Enumerable.from(data_).max(t => t.MAXT).toFixed(1);
            let mint_ = +Enumerable.from(data_).min(t => t.MINT).toFixed(1);
            let r24h = +Enumerable.from(data_).sum(t => +t.R24H).toFixed(1);
            stormDay = r24h >= 50 ? stormDay++:0

            dayTemp.push({  day: i, avgt: avgt, maxt: maxt, mint: mint_, r24h: r24h });
        }
    }


    let p0 = data1[0]
    let p1 = Enumerable.from(dayTemp).average((t: any) => t.r24h)

    start.setDate(start.getDate() + 10)
    let data_ = Enumerable.from(data).where(p => p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd")).toArray()
    let totalSt = data_.length
    let stormSt = Enumerable.from(data_).where(t => +t.R24H>=50).length
    
    let RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
    RD = RD * 0.25;
    let RA = stormSt / totalSt
    RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
    RA = RA * 0.25;
    let R = (RA+RD)/2

    let mint = 99;
    let mint_5_hour = 0;
    data_ = Enumerable.from(data).where(p => p.OBTID == obtid).orderBy(p => p.DDATETIME).toArray()
    for (let t of data_) {
        if (t.MINT <= 5) {
            mint = mint > t.MINT ? t.MINT : mint;
            mint_5_hour++
        }
        else {
            mint = 99;
            mint_5_hour = 0
        }
    }


    let maxWind = Enumerable.from(data_).max(t => t.WD3SMAXDF)
    let windScale = weatherUtil.WindLevel(maxWind);

    var warnings: iFruitWarning[] = [];
    var fruitDanger_ = fruitDanger()
    for (let fruit of fruitDanger_) {
        if (fruit.temperature) {
            var hotObj: any = {}
            for (let tw of fruit.temperature) {
                if (tw.code == "cold") {
                    if (fruit.name == "水稻") {
                        let avgt_30 = data1[1]
                        let avgt_1 = data1[2]
                        let avgt_0 = data1[3]
                        let t_diff = avgt_1 - avgt_30;
                        let t_diff1 = avgt_1 - avgt_0
                        if (t_diff1 < 0 && t_diff < 2) {
                            if (t_diff <= tw.value) {
                                let msg = "本旬气温比同期平均低" + t_diff.toFixed(1) + "℃,比上一旬低" + t_diff1.toFixed(1) + "℃"
                                warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: t_diff, warnMsg: msg });
                                break;
                            }
                        }

                    }
                    else if (fruit.code == "vegetable") {
                        if (mint < tw.value && mint_5_hour > tw.continue) {
                            let continue_ = tw.continue > -1 ? ",≤5℃时间持续" + mint_5_hour + "小时，" : "，"
                            let msg = "气温" + mint.toFixed(1) + "℃" + continue_
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: mint, warnMsg: msg });

                        }
                    } else {
                        if (mint <= tw.value) {
                            let msg = "气温" + mint.toFixed(1) + "℃"
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: mint, warnMsg: msg });
                        }
                    }
                }
                else if (tw.code == "hot") {
                    var days = 0;
                    for (let obj of dayTemp) {
                        if (obj.avgt >= tw.avgt || obj.maxt >= tw.maxt) {
                            days++;
                        }
                        else break;
                        if (days > tw.days) {
                            let msg = "气温" + obj.maxt.toFixed(1) + "℃"
                            hotObj = { fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.maxt, warnMsg: msg };
                        }
                    }

                }
                else {
                    for (let obj of dayTemp) {
                        if (obj.maxt >= tw.maxt) {
                            let msg = "日最高气温" + obj.maxt.toFixed(1) + "℃"
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.maxt, warnMsg: msg });
                        }
                    }
                }
            }
            if (hotObj.fruitCode)
                warnings.push(hotObj)

        }
        if (fruit.wind) {
            for (let tw of fruit.wind) {
                //var windScale = weatherUtil.WindLevel(maxWind);
                if (windScale >= tw.value) {
                    let msg = "日最大瞬时风速达到" + maxWind.toFixed(1) + "m/s(" + windScale + "级)"
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: maxWind, warnMsg: msg });
                    break;
                }
            }
        }
        if (fruit.coldwind) {
            for (let tw of fruit.coldwind) {
                var windObj: any = {}
                var avgtdays = 0, mintdays = 0, raindays = 0;
                for (let obj of dayTemp) {
                    if (obj.avgt <= tw.avgt)
                        avgtdays = avgtdays++;
                    else break

                    if (obj.mint <= tw.mint)
                        mintdays = mintdays++;
                    else break

                    raindays = obj.r24h > 0 ? raindays + 1 : 0;
                    if (avgtdays >= tw.avgtdays && mintdays >= tw.mintdays && raindays >= tw.raindays) {
                        let msg = "过去" + avgtdays + "天日平均气温&le;" + tw.avgt + "℃，"
                        msg += mintdays + "天日最低气温&le;" + tw.mint + "℃，"
                        if (fruit.warn.indexOf("湿冷型") > -1)
                            msg += "降雨日" + raindays + "天"
                        windObj = { fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, warnMsg: msg };

                    } else break;
                }
            }
            if (windObj.fruitCode)
                warnings.push(windObj)
        }

        if (fruit.storm) {
            for (let tw of fruit.storm) {
                if (R >= tw.value) {
                    let msg = "过去" + stormDay + "天持续暴雨,"
                    msg += "暴雨站数" + stormSt
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: R, warnMsg: msg });
                }
            }
        }

        if (fruit.drought) {
            let p = (p1 - p0) / p0;
            for (let tw of fruit.drought) {
                if (p <= tw.value) {
                    let msg = "过去10天降雨" + p1.toFixed(1) + "mm,比同期少" + Math.abs(p1 - p0).toFixed(1) + "mm"
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: p, warnMsg: msg });
                    break
                }
            }
        }
    }
    return warnings;
}
export function getStreetDisaster1(obtid: string, start: Date,
    data: iLOCALOBTHOURD[], data1: number[]) {
    let p0 = data1[0]
    let p1 = Enumerable.from(data).average((t: any) => +t.R24H)

    start.setDate(start.getDate() + 10)
    let data_ = Enumerable.from(data).where(p => p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd")).toArray()
    let totalSt = data_.length
    let stormSt = Enumerable.from(data_).where(t => +t.R24H >= 50).length

    let obj = Enumerable.from(data).first(p=> p.OBTID == obtid && p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd"))

    let stormDay = +obj.HOURR;
    let RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
    RD = RD * 0.25;
    let RA = stormSt / totalSt
    RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
    RA = RA * 0.25;
    let R = (RA + RD) / 2

    let mint_5_hour = +obj.MINTTIME;
    let windScale = weatherUtil.WindLevel(obj.WD3SMAXDF);

    data_ = Enumerable.from(data).where(p => p.OBTID == obtid
        && p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd"))
        .orderBy(p => p.DDATETIME).toArray()
    var warnings: iFruitWarning[] = [];
    var fruitDanger_ = fruitDanger()
    for (let fruit of fruitDanger_) {
        if (fruit.temperature) {
            var hotObj: any = {}
            for (let tw of fruit.temperature) {
                if (tw.code == "cold") {
                    if (fruit.name == "水稻") {
                        let avgt_30 = data1[1]
                        let avgt_1 = data1[2]
                        let avgt_0 = data1[3]
                        let t_diff = avgt_1 - avgt_30;
                        let t_diff1 = avgt_1 - avgt_0
                        if (t_diff1 < 0 && t_diff < 2) {
                            if (t_diff <= tw.value) {
                                let msg = "本旬气温比同期平均低" + t_diff.toFixed(1) + "℃,比上一旬低" + t_diff1.toFixed(1) + "℃"
                                warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: t_diff, warnMsg: msg });
                                break;
                            }
                        }

                    }
                    else if (fruit.code == "vegetable") {
                        if (obj.MINT < tw.value && mint_5_hour > tw.continue) {
                            let continue_ = tw.continue > -1 ? ",≤5℃时间持续" + mint_5_hour + "小时，" : "，"
                            let msg = "气温" + obj.MINT.toFixed(1) + "℃" + continue_
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.MINT, warnMsg: msg });

                        }
                    } else {
                        if (obj.MINT <= tw.value) {
                            let msg = "气温" + obj.MINT.toFixed(1) + "℃"
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.MINT, warnMsg: msg });
                        }
                    }
                }
                else if (tw.code == "hot") {
                    var days = 0;
                    for (let obj of data_) {
                        if (obj.T >= tw.avgt || obj.MAXT >= tw.maxt) {
                            days++;
                        }
                        else break;
                        if (days > tw.days) {
                            let msg = "气温" + obj.MAXT.toFixed(1) + "℃"
                            hotObj = { fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.MAXT, warnMsg: msg };
                        }
                    }

                }
                else {
                    for (let obj of data_) {
                        if (obj.MAXT >= tw.maxt) {
                            let msg = "日最高气温" + obj.MAXT.toFixed(1) + "℃"
                            warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.MAXT, warnMsg: msg });
                        }
                    }
                }
            }
            if (hotObj.fruitCode)
                warnings.push(hotObj)

        }
        if (fruit.wind) {
            for (let tw of fruit.wind) {
                //var windScale = weatherUtil.WindLevel(maxWind);
                if (windScale >= tw.value) {
                    let msg = "日最大瞬时风速达到" + obj.WD3SMAXDF.toFixed(1) + "m/s(" + windScale + "级)"
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.WD3SMAXDF, warnMsg: msg });
                    break;
                }
            }
        }
        if (fruit.coldwind) {
            for (let tw of fruit.coldwind) {
                var windObj: any = {}
                var avgtdays = 0, mintdays = 0, raindays = 0;
                for (let obj of data_) {
                    if (obj.T <= tw.avgt)
                        avgtdays = avgtdays++;
                    else break

                    if (obj.MINT <= tw.mint)
                        mintdays = mintdays++;
                    else break

                    raindays = +obj.R24H > 0 ? raindays + 1 : 0;
                    if (avgtdays >= tw.avgtdays && mintdays >= tw.mintdays && raindays >= tw.raindays) {
                        let msg = "过去" + avgtdays + "天日平均气温&le;" + tw.avgt + "℃，"
                        msg += mintdays + "天日最低气温&le;" + tw.mint + "℃，"
                        if (fruit.warn.indexOf("湿冷型") > -1)
                            msg += "降雨日" + raindays + "天"
                        windObj = { fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, warnMsg: msg };

                    } else break;
                }
            }
            if (windObj.fruitCode)
                warnings.push(windObj)
        }

        if (fruit.storm) {
            for (let tw of fruit.storm) {
                if (R >= tw.value) {
                    let msg = "过去" + stormDay + "天持续暴雨,"
                    msg += "暴雨站数" + stormSt
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: R, warnMsg: msg });
                }
            }
        }

        if (fruit.drought) {
            let p = (p1 - p0) / p0;
            for (let tw of fruit.drought) {
                if (p <= tw.value) {
                    let msg = "过去10天降雨" + p1.toFixed(1) + "mm,比同期少" + Math.abs(p1 - p0).toFixed(1) + "mm"
                    warnings.push({ fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: p, warnMsg: msg });
                    break
                }
            }
        }
    }
    return warnings;
}

export function getStreetDisaster2_(start: Date, data: iLOCALOBTHOURD[], data1: any) {
    var warnings: any[] = [];
    for (let obt of Enumerable.from(data).groupBy(t => t.OBTID)) {
        let dayTemp: any = []
        let stormDay = 0;
        let start_ = new Date(start)
        start_.setDate(start_.getDate() - 10)
        let data_ = obt.getSource()
        let obtid = obt.key()
        let obtName = data_[0].OBTNAME
        for (let i = 0; i < 10; i++) {
            let date_ = new Date(start_.toStr("yyyy-MM-dd"))
            date_.setDate(date_.getDate() + i)
            var data__ = Enumerable.from(data_).where(p => p.DDATETIME.toStr("yyyyMMdd") == date_.toStr("yyyyMMdd")).toArray()
            if (data__.length > 0) {
                let avgt = +Enumerable.from(data__).max(t => t.T).toFixed(1);
                let maxt = +Enumerable.from(data__).max(t => t.MAXT).toFixed(1);
                let mint_ = +Enumerable.from(data__).min(t => t.MINT).toFixed(1);
                let r24h = +Enumerable.from(data__).sum(t => +t.R24H).toFixed(1);
                stormDay = r24h >= 50 ? stormDay++ : 0

                dayTemp.push({ day: i, avgt: avgt, maxt: maxt, mint: mint_, r24h: r24h });
            }
        }

        let data1_ = data1[obtid];
        if (data1_ == null || data1_ == undefined) continue;
        let p0 = data1_[0]
        let p1 = Enumerable.from(dayTemp).average((t: any) => t.r24h)

        start_.setDate(start_.getDate() + 10)
        data__ = Enumerable.from(data_).where(p => p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd") ).toArray()
        let totalSt = data__.length
        let stormSt = Enumerable.from(data__).where(t => +t.R24H >= 50).length

        let RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
        RD = RD * 0.25;
        let RA = stormSt / totalSt
        RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
        RA = RA * 0.25;
        let R = (RA + RD) / 2

        let mint = 99;
        let mint_5_hour = 0;
        data__ = Enumerable.from(data_).where(p => p.OBTID == obtid).orderBy(p => p.DDATETIME).toArray()
        for (let t of data_) {
            if (t.MINT <= 5) {
                mint = mint > t.MINT ? t.MINT : mint;
                mint_5_hour++
            }
            else {
                mint = 99;
                mint_5_hour = 0
            }
        }

        let maxWind = Enumerable.from(data__).max(t => t.WD3SMAXDF)
        let windScale = weatherUtil.WindLevel(maxWind);

        var fruitDanger_ = fruitDanger()
        for (let fruit of fruitDanger_) {
            if (fruit.temperature) {
                var hotObj: any = {}
                for (let tw of fruit.temperature) {
                    if (tw.code == "cold") {
                        if (fruit.name == "水稻") {
                            let avgt_30 = data1_[1]
                            let avgt_1 = data1_[2]
                            let avgt_0 = data1_[3]
                            let t_diff = avgt_1 - avgt_30;
                            let t_diff1 = avgt_1 - avgt_0
                            if (t_diff1 < 0 && t_diff < 2) {
                                if (t_diff <= tw.value) {
                                    let msg = "本旬气温比同期平均低" + t_diff.toFixed(1) + "℃,比上一旬低" + t_diff1.toFixed(1) + "℃"
                                    warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: t_diff, warnMsg: msg });
                                    break;
                                }
                            }

                        }
                        else if (fruit.code == "vegetable") {
                            if (mint < tw.value && mint_5_hour > tw.continue) {
                                let continue_ = tw.continue > -1 ? ",≤5℃时间持续" + mint_5_hour + "小时，" : "，"
                                let msg = "气温" + mint.toFixed(1) + "℃" + continue_
                                warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: mint, warnMsg: msg });

                            }
                        } else {
                            if (mint <= tw.value) {
                                let msg = "气温" + mint.toFixed(1) + "℃"
                                warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: mint, warnMsg: msg });
                            }
                        }
                    }
                    else if (tw.code == "hot") {
                        var days = 0;
                        for (let obj of dayTemp) {
                            if (obj.avgt >= tw.avgt || obj.maxt >= tw.maxt) {
                                days++;
                            }
                            else break;
                            if (days > tw.days) {
                                let msg = "气温" + obj.maxt.toFixed(1) + "℃"
                                hotObj = { obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.maxt, warnMsg: msg };
                            }
                        }

                    }
                    else {
                        for (let obj of dayTemp) {
                            if (obj.maxt >= tw.maxt) {
                                let msg = "日最高气温" + obj.maxt.toFixed(1) + "℃"
                                warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj.maxt, warnMsg: msg });
                            }
                        }
                    }
                }
                if (hotObj.fruitCode)
                    warnings.push(hotObj)

            }
            if (fruit.wind) {
                for (let tw of fruit.wind) {
                    //var windScale = weatherUtil.WindLevel(maxWind);
                    if (windScale >= tw.value) {
                        let msg = "日最大瞬时风速达到" + maxWind.toFixed(1) + "m/s(" + windScale + "级)"
                        warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: maxWind, warnMsg: msg });
                        break;
                    }
                }
            }
            if (fruit.coldwind) {
                for (let tw of fruit.coldwind) {
                    var windObj: any = {}
                    var avgtdays = 0, mintdays = 0, raindays = 0;
                    for (let obj of dayTemp) {
                        if (obj.avgt <= tw.avgt)
                            avgtdays = avgtdays++;
                        else break

                        if (obj.mint <= tw.mint)
                            mintdays = mintdays++;
                        else break

                        raindays = obj.r24h > 0 ? raindays + 1 : 0;
                        if (avgtdays >= tw.avgtdays && mintdays >= tw.mintdays && raindays >= tw.raindays) {
                            let msg = "过去" + avgtdays + "天日平均气温&le;" + tw.avgt + "℃，"
                            msg += mintdays + "天日最低气温&le;" + tw.mint + "℃，"
                            if (fruit.warn.indexOf("湿冷型") > -1)
                                msg += "降雨日" + raindays + "天"
                            windObj = { obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, warnMsg: msg };

                        } else break;
                    }
                }
                if (windObj.fruitCode)
                    warnings.push(windObj)
            }

            if (fruit.storm) {
                for (let tw of fruit.storm) {
                    if (R >= tw.value) {
                        let msg = "过去" + stormDay + "天持续暴雨,"
                        msg += "暴雨站数" + stormSt
                        warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: R, warnMsg: msg });
                    }
                }
            }

            if (fruit.drought) {
                let p = (p1 - p0) / p0;
                for (let tw of fruit.drought) {
                    if (p <= tw.value) {
                        let msg = "过去10天降雨" + p1.toFixed(1) + "mm,比同期少" + Math.abs(p1 - p0).toFixed(1) + "mm"
                        warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: p, warnMsg: msg });
                        break
                    }
                }
            }
        }
    }

    return warnings;
}

export function getStreetDisaster2(start: Date, data: iLOCALOBTHOURD[], data1: any) {
    var warnings: any[] = [];
    for (let obt of Enumerable.from(data).groupBy(t => t.OBTID)) {
        let start_ = new Date(start)
        start_.setDate(start_.getDate() - 10)
        let data_ = obt.getSource()
        let obtid = obt.key()
        let obtName = data_[0].OBTNAME

        let data1_ = data1[obtid];
        if (data1_ == null || data1_ == undefined) continue;
        let p0 = data1_[0]
        let p1 = Enumerable.from(data_).average((t: any) => +t.R24H)

        start_.setDate(start_.getDate() + 10)
        let data__ = Enumerable.from(data).where(p => p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd")).toArray()
        let totalSt = data__.length
        let stormSt = Enumerable.from(data__).where(t => +t.R24H >= 50).length

        var obj_ = Enumerable.from(data_).firstOrDefault(p => p.DDATETIME.toStr("yyyyMMdd") == start.toStr("yyyyMMdd"))
        if (obj_ == null || obj_ == undefined) continue

        let stormDay = +obj_.HOURR;
        let RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
        RD = RD * 0.25;
        let RA = stormSt / totalSt
        RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
        RA = RA * 0.25;
        let R = (RA + RD) / 2

        let mint_5_hour = +obj_.MINTTIME;
        let windScale = weatherUtil.WindLevel(obj_.WD3SMAXDF);

        data_ = Enumerable.from(data_).orderBy(p => p.DDATETIME).toArray()
        var fruitDanger_ = fruitDanger()
        for (let fruit of fruitDanger_) {
            if (fruit.temperature) {
                var hotObj: any = {}
                for (let tw of fruit.temperature) {
                    if (tw.code == "cold") {
                        if (fruit.name == "水稻") {
                            let avgt_30 = data1_[1]
                            let avgt_1 = data1_[2]
                            let avgt_0 = data1_[3]
                            let t_diff = avgt_1 - avgt_30;
                            let t_diff1 = avgt_1 - avgt_0
                            if (t_diff1 < 0 && t_diff < 2) {
                                if (t_diff <= tw.value) {
                                    let msg = "本旬气温比同期平均低" + t_diff.toFixed(1) + "℃,比上一旬低" + t_diff1.toFixed(1) + "℃"
                                    warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: t_diff, warnMsg: msg });
                                    break;
                                }
                            }

                        }
                        else if (fruit.code == "vegetable") {
                            if (obj_.MINT < tw.value && mint_5_hour > tw.continue) {
                                let continue_ = tw.continue > -1 ? ",≤5℃时间持续" + mint_5_hour + "小时，" : "，"
                                let msg = "气温" + obj_.MINT.toFixed(1) + "℃" + continue_
                                warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj_.MINT, warnMsg: msg });

                            }
                        } else {
                            if (obj_.MINT <= tw.value) {
                                let msg = "气温" + obj_.MINT.toFixed(1) + "℃"
                                warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj_.MINT, warnMsg: msg });
                            }
                        }
                    }
                    else if (tw.code == "hot") {
                        var days = 0;
                        for (let obj of data_) {
                            if (obj.T >= tw.avgt || obj.MAXT >= tw.maxt) {
                                days++;
                            }
                            else break;
                            if (days > tw.days) {
                                let msg = "气温" + obj.MAXT.toFixed(1) + "℃"
                                hotObj = { obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj_.MAXT, warnMsg: msg };
                            }
                        }

                    }
                    else {
                        for (let obj of data_) {
                            if (obj.MAXT >= tw.maxt) {
                                let msg = "日最高气温" + obj.MAXT.toFixed(1) + "℃"
                                warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj_.MAXT, warnMsg: msg });
                            }
                        }
                    }
                }
                if (hotObj.fruitCode)
                    warnings.push(hotObj)

            }
            if (fruit.wind) {
                for (let tw of fruit.wind) {
                    //var windScale = weatherUtil.WindLevel(maxWind);
                    if (windScale >= tw.value) {
                        let msg = "日最大瞬时风速达到" + obj_.WD3SMAXDF.toFixed(1) + "m/s(" + windScale + "级)"
                        warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: obj_.WD3SMAXDF, warnMsg: msg });
                        break;
                    }
                }
            }
            if (fruit.coldwind) {
                for (let tw of fruit.coldwind) {
                    var windObj: any = {}
                    var avgtdays = 0, mintdays = 0, raindays = 0;
                    for (let obj of data_) {
                        if (obj.T <= tw.avgt)
                            avgtdays = avgtdays++;
                        else break

                        if (obj.MINT <= tw.mint)
                            mintdays = mintdays++;
                        else break

                        raindays = +obj.R24H > 0 ? raindays + 1 : 0;
                        if (avgtdays >= tw.avgtdays && mintdays >= tw.mintdays && raindays >= tw.raindays) {
                            let msg = "过去" + avgtdays + "天日平均气温&le;" + tw.avgt + "℃，"
                            msg += mintdays + "天日最低气温&le;" + tw.mint + "℃，"
                            if (fruit.warn.indexOf("湿冷型") > -1)
                                msg += "降雨日" + raindays + "天"
                            windObj = { obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, warnMsg: msg };

                        } else break;
                    }
                }
                if (windObj.fruitCode)
                    warnings.push(windObj)
            }

            if (fruit.storm) {
                for (let tw of fruit.storm) {
                    if (R >= tw.value) {
                        let msg = "过去" + stormDay + "天持续暴雨,"
                        msg += "暴雨站数" + stormSt
                        warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: R, warnMsg: msg });
                    }
                }
            }

            if (fruit.drought) {
                let p = (p1 - p0) / p0;
                for (let tw of fruit.drought) {
                    if (p <= tw.value) {
                        let msg = "过去10天降雨" + p1.toFixed(1) + "mm,比同期少" + Math.abs(p1 - p0).toFixed(1) + "mm"
                        warnings.push({ obtid: obtid, obtName: obtName, fruitCode: fruit.code, color: tw.color, fruitName: fruit.name, warnName: tw.warn, warnCode: tw.code, forValue: p, warnMsg: msg });
                        break
                    }
                }
            }
        }
    }

    return warnings;
}

