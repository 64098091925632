export default (function () {
    //字符串扩展方法
    String.prototype.convert2date = function (this: string) {
        if (this.lastIndexOf("Z") == this.length - 1) return new Date(this);
        else if (this.lastIndexOf("T") > 0) return new Date(this);
        let _number = /-{0,1}\d+/.exec(this);
        if (_number) return new Date(parseFloat(_number[0]));
    }
    String.prototype.getByteLength = function () {
        var byteLen = 0, len = this.length;
        for (var i = 0; i < len; i++) {
            if (this.charCodeAt(i) > 255) byteLen += 2;
            else byteLen++;
        }
        return byteLen;
    }
    String.prototype.paddingLeft = function (length: number, c: string) {
        var r = this;
        while (r.length < length)
            r = c + r;
        return r;
    }
    //时间扩展方法
    Date.prototype.toStr = function (this: Date, formater: string) {
        let o: any = {
            M: this.getMonth() + 1, d: this.getDate(), H: this.getHours(), m: this.getMinutes(),
            s: this.getSeconds(), q: Math.floor((this.getMonth() + 3) / 3), S: this.getMilliseconds()
        }
        if (/(y+)/.test(formater))
            formater = formater.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o) {
            if (new RegExp("(" + k + "+)").test(formater)) {
                formater = formater.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
            }
        }
        return formater;
    }
    //HTML元素扩素方法
    HTMLElement.prototype.appendText = function (this: HTMLElement, text: string) {
        let tNode = document.createTextNode(text);
        this.appendChild(tNode);
        return this;
    }
    HTMLElement.prototype.appendInput = function (this: HTMLElement, type: string, value: string, style: { [x: string]: string }) {
        let input = document.createElement("input");
        input.type = type;
        input.value = value;
        for (let t in style)
            input.style.setProperty(t, style[t]);
        this.appendChild(input);
        return input;
    }
    HTMLElement.prototype.appendElement = function (this: HTMLElement, tagName: string, style: { [x: string]: string }) {
        let element = document.createElement(tagName);
        for (var t in style)
            element.style.setProperty(t, style[t]);
        this.appendChild(element);
        return element;
    }
    HTMLElement.prototype.appendTd = function (this: HTMLElement, style: { [x: string]: string }) {
        return this.appendElement("td", style) as HTMLTableCellElement;
    }
    HTMLElement.prototype.appendImage = function (this: HTMLElement, src: string, style: { [x: string]: string }) {
        var img = this.appendElement("img", style) as HTMLImageElement;
        img.src = src;
        return img;
    }
    HTMLElement.prototype.appendButton = function (this: HTMLElement, value: string, style?: { [x: string]: string }) {
        var btn = this.appendInput("button", style) as HTMLInputElement;
        btn.value = value;
        if (style) for (var t in style)
            btn.style.setProperty(t, style[t]);
        return btn;
    }
})();
declare global {
    interface String {
        convert2date(): Date | undefined
        getByteLength(): number
        paddingLeft(lenth: number, c: string): String
    }
    interface Date {
        toStr(this: Date, formater: string): string;
    }
    interface HTMLElement {
        appendText(text: string | number): this,
        appendInput(type: string, value: any, style?: any): HTMLInputElement,
        appendButton(value: string, style?: any): HTMLInputElement,
        appendElement(tagName: string, style?: any): HTMLElement,
        appendImage(src: string, style?: any): HTMLImageElement,
        appendTd(style?: any): HTMLTableCellElement
    }
}


