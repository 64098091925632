//自动站
import { Component, createRef, useContext } from 'react';
import backendMethod from '../API/backend';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.min.css"
import Enumerable from 'linq';
import Highcharts from "highcharts";
import * as ReactDOM from 'react-dom';
import { ColorBarMobile, ColorBarPC } from './wstationColorbar';
import { Modal } from 'bootstrap';
import DdatePicker from "./ddatePicker";
import wdb from "../util/wdb"
import { getStreetDisaster1 } from "../API/disarter";

export default class weatherStaion extends Component<{ map: L.Map, mobile?: boolean, onChangeDate?: (date: Date) => void }>{
    //
    private ddatetime: Date;
    private selectField: string;
    private selectName: string;
    private changeTimeCallback: (field: string, name: string) => void;
    private lg: L.FeatureGroup;
    private selectOBT: iLOCALOBTMIND
    private datetimeInput: HTMLInputElement;
    private weatherstationDiv = createRef<HTMLDivElement>();
    private rasterColorTables = createRef<HTMLDivElement>();
    private warningDiv = createRef<HTMLDivElement>();
    private warningDiv2 = createRef<HTMLDivElement>();
    private fieldClass: string;
    private dataImageOverlaySZ: L.ImageOverlay;
    private dataImageOverlaySS: L.ImageOverlay;
    private canvasLayer: L.ImageOverlay;
    private canvas: HTMLCanvasElement;

    public constructor(props: { map: L.Map, mobile?: boolean, onChangeDate?: (date: Date) => void }) {
        super(props);
        this.canvas = document.createElement('canvas');
        this.canvas.width = 1295;
        this.canvas.height = 750;
        this.props.map.createPane('canvas')
        
        this.ddatetime = new Date();
        let mm = this.ddatetime.getMinutes() - 10;
        mm = mm - mm % 5;
        this.ddatetime.setMinutes(mm, 0, 0);

        //console.log(document.location)
        if (window.location.search != "") {
            let params = new URLSearchParams(window.location.search);// 获取地址栏参数
            if (params) {
                let time = params.get('t'); // 根据参数名获取参数值
                if (time) {
                    this.ddatetime = new Date(time);
                    let mm = this.ddatetime.getMinutes();
                    mm = mm - mm % 5;
                    this.ddatetime.setMinutes(mm, 0, 0);
                }
            }
        }
    }
    public componentDidMount(): void {
        let allCollapse = this.weatherstationDiv.current.querySelectorAll(".collapse");
        for (var item of allCollapse) {
            item.addEventListener('show.bs.collapse', event => {
                for (var bi of allCollapse) {
                    if (bi != event.target)
                        bi.className = "collapse";
                }
            })
        }
        (document.querySelector("#collapseTemperature input[value='T']") as HTMLInputElement).checked = true;
        this.showTemperatureOnMap("T", "实时温度");
    }
    private changePickerDate(date: Date) {
        this.ddatetime = date;
        if (this.props.onChangeDate) this.props.onChangeDate(date);
        if (this.changeTimeCallback)
            this.changeTimeCallback(this.selectField, this.selectName);
    }
    private setDateinputChangeEvent(field: string, name: string, callback: (field: string, name: string) => void) {
        this.selectField = field;
        this.selectName = name;
        this.changeTimeCallback = callback;
    }
    public render = () => {
        if (this.props.mobile)
            return <>
                <div id="awsTitleDiv" style={{ position: 'absolute', fontSize: 17, top: 40, left: 60, color: "#fff", zIndex: 520 }}></div>
                <div ref={this.weatherstationDiv} className="card mb-3" style={{ position: 'absolute', color: "#fff", left: 0, marginBottom: 0, bottom: 0, zIndex: 520, width: "100%", border: "2px solid #0E77D3" }} >
                    <div className="card-header" style={{ padding: "3px", backgroundColor: "#0E77D3" }}>气象自动站
                        <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={this.close.bind(this)}></span>
                    </div>
                    <div className="card-body" style={{ background: "#001A35", padding: 0, textAlign: "center" }}>
                        <div style={{ textAlign: "left", marginLeft: 0 }}>
                            <label style={{ width: 100, margin: 10 }} onClick={e => new Modal("#modelT2M", { backdrop: !true }).show()}> <i className="bi bi-thermometer-half"></i>&nbsp;大气温度</label>
                            <label style={{ width: 100, margin: 10 }} onClick={e => new Modal("#modelTP", { backdrop: !true }).show()}> <i className="bi bi-umbrella"></i>&nbsp;滑动雨量</label>
                            <label style={{ width: 100, margin: 10 }} onClick={e => new Modal("#modelWind", { backdrop: !true }).show()}> <i className="bi bi-wind"></i>&nbsp;风速风向</label>

                            <br></br>
                            <label style={{ width: 100, margin: 10 }} onClick={e => new Modal("#modelHum", { backdrop: !true }).show()}> <i className="bi bi-moisture"></i>&nbsp;大气湿度</label>
                            <label style={{ width: 100, margin: 10 }} onClick={e => new Modal("#modelPre", { backdrop: !true }).show()}> <i className="bi bi-speedometer"></i>&nbsp;大气气压</label>
                        </div>
                        <DdatePicker startDate={this.ddatetime} onChange={this.changePickerDate.bind(this)} hourStep={1} minStep={5} />
                        <div className='modal' id="modelT2M">
                            <div className="modal-dialog" style={{}}>
                                <div className="modal-content" style={{ textAlign: "left", backgroundColor: "#8BA6B4", color: "#000", fontWeight: 'bold', fontSize: 18 }}>
                                    <div className="modal-header">大气温度</div>
                                    <div className="modal-body">
                                        <ul style={{ marginLeft: 50 }} id="collapseTemperature">
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="T" onChange={e => this.showTemperatureOnMap(e.target.value, "实时温度")} /> 实时温度&nbsp;&nbsp;&nbsp;&nbsp;</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MINT" onChange={e => this.showTemperatureOnMap(e.target.value, "本小时最低温度")} /> 本小时最低</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MAXT" onChange={e => this.showTemperatureOnMap(e.target.value, "本小时最高温度")} /> 本小时最高</label> </li>
                                        </ul>
                                    </div>
                                    <div className="modal-footer" style={{ padding: 0 }}>
                                        <button type="button" className="btn btn-primary" style={{ width: "98%" }} data-bs-dismiss="modal">关闭</button>
                                    </div>
                                </div></div>
                        </div>
                        <div className='modal' id="modelTP" >
                            <div className="modal-dialog" style={{}}>
                                <div className="modal-content" style={{ textAlign: "left", backgroundColor: "#8BA6B4", color: "#000", fontWeight: 'bold', fontSize: 18 }}>
                                    <div className="modal-header" >滑动雨量</div>
                                    <div className="modal-body">
                                        <ul style={{ marginLeft: 50 }}>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R30M" onChange={e => this.showRainOnMap(e.target.value, "30分钟滑动雨量")} /> 30分钟</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R01H" onChange={e => this.showRainOnMap(e.target.value, "1小时滑动雨量")} /> 01小时</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R02H" onChange={e => this.showRainOnMap(e.target.value, "2小时滑动雨量")} /> 02小时</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R03H" onChange={e => this.showRainOnMap(e.target.value, "3小时滑动雨量")} /> 03小时</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R06H" onChange={e => this.showRainOnMap(e.target.value, "6小时滑动雨量")} /> 06小时</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R12H" onChange={e => this.showRainOnMap(e.target.value, "12小时滑动雨量")} /> 12小时</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R24H" onChange={e => this.showRainOnMap(e.target.value, "1天滑动雨量")} /> 1天&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R48H" onChange={e => this.showRainOnMap(e.target.value, "2天小时滑动雨量")} /> 2天&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> </li>
                                        </ul>
                                    </div>
                                    <div className="modal-footer" style={{ padding: 0 }}>
                                        <button type="button" className="btn btn-primary" style={{ width: "98%" }} data-bs-dismiss="modal">关闭</button>
                                    </div>
                                </div></div>
                        </div>
                        <div className='modal' id="modelWind">
                            <div className="modal-dialog" style={{}}>
                                <div className="modal-content" style={{ textAlign: "left", backgroundColor: "#8BA6B4", color: "#000", fontWeight: 'bold', fontSize: 18 }}>
                                    <div className="modal-header">风速风向</div>
                                    <div className="modal-body">
                                        <ul style={{ marginLeft: 50 }}>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WDIDF" onChange={e => this.showWindOnMap(e.target.value, "瞬时风")} /> 瞬时风速&nbsp;&nbsp;&nbsp;&nbsp;</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WD2DF" onChange={e => this.showWindOnMap(e.target.value, "两分钟平均风")} /> 02分钟风速</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WD10DF" onChange={e => this.showWindOnMap(e.target.value, "十分钟平均风")} /> 10分风速&nbsp;&nbsp;&nbsp;</label> </li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WD3SMAXDF" onChange={e => this.showWindOnMap(e.target.value, "小时极大风")} /> 小时极大风</label> </li>
                                        </ul>
                                    </div>
                                    <div className="modal-footer" style={{ padding: 0 }}>
                                        <button type="button" className="btn btn-primary" style={{ width: "98%" }} data-bs-dismiss="modal">关闭</button>
                                    </div>
                                </div></div>
                        </div>
                        <div className='modal' id="modelHum">
                            <div className="modal-dialog" style={{}}>
                                <div className="modal-content" style={{ textAlign: "left", backgroundColor: "#8BA6B4", color: "#000", fontWeight: 'bold', fontSize: 18 }}>
                                    <div className="modal-header">大气湿度</div>
                                    <div className="modal-body">
                                        <ul style={{ marginLeft: 50 }}>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="U" onChange={e => this.showHumityOnMap(e.target.value, "实时湿度")} /> 实时湿度&nbsp;&nbsp;&nbsp;&nbsp;</label></li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MINU" onChange={e => this.showHumityOnMap(e.target.value, "本小时最低湿度")} /> 本小时最低</label></li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MAXU" onChange={e => this.showHumityOnMap(e.target.value, "本小时最高湿度")} /> 本小时最高</label></li>
                                        </ul>
                                    </div>
                                    <div className="modal-footer" style={{ padding: 0 }}>
                                        <button type="button" className="btn btn-primary" style={{ width: "98%" }} data-bs-dismiss="modal">关闭</button>
                                    </div>
                                </div></div>
                        </div>
                        <div className='modal' id="modelPre">
                            <div className="modal-dialog" style={{}}>
                                <div className="modal-content" style={{ textAlign: "left", backgroundColor: "#8BA6B4", color: "#000", fontWeight: 'bold', fontSize: 18 }}>
                                    <div className="modal-header">大气气压</div>
                                    <div className="modal-body">
                                        <ul style={{ marginLeft: 50 }}>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="P" onChange={e => this.showPressureOnMap(e.target.value, "实时气压")} /> 实时气压&nbsp;&nbsp;&nbsp;&nbsp;</label></li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MINP" onChange={e => this.showPressureOnMap(e.target.value, "本小时最低气压")} /> 本小时最低</label></li>
                                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MAXP" onChange={e => this.showPressureOnMap(e.target.value, "本小时最高气压")} /> 本小时最高</label></li>
                                        </ul>
                                    </div>
                                    <div className="modal-footer" style={{ padding: 0 }}>
                                        <button type="button" className="btn btn-primary" style={{ width: "98%" }} data-bs-dismiss="modal">关闭</button>
                                    </div>
                                </div></div>
                        </div>
                    </div>
                    <div className="card-foot" >
                        <div ref={this.rasterColorTables} style={{ fontSize: 12, left: 50, overflow: 'hidden' }}>
                            <ColorBarMobile />
                        </div>
                    </div>
                </div>
            </>
        else return <>
            <div id="awsTitleDiv" style={{ position: 'fixed', fontSize: 20, fontWeight: "bold", top: 52, left: window.innerWidth / 2 - 300, color: "#000", zIndex: 520 }}></div>
            <div ref={this.weatherstationDiv} className="card mb-3" style={{
                background: "#ffffffb4", color: "#fff", zIndex: 520, width: 242, border: "2px solid #0E77D3"
            }} >
                <div className="card-header" style={{ padding: "3px", backgroundColor: "#0E77D3" }}>自动站
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={this.close.bind(this)}></span>
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-dash' onClick={this.swiftForm.bind(this)}></span>
                </div>
                <div className="card-body" style={{ background: "", padding: 0 }}>
                    <DdatePicker startDate={this.ddatetime} className='reactDatePicker' onChange={this.changePickerDate.bind(this)} hourStep={1} minStep={5} />
                    <div className="list-group">
                        <button className="list-group-item list-group-item-action" style={{ background: "#ffffffb4", color: "", borderStyle: 'none', borderBottom: "1px solid gray" }} data-bs-toggle="collapse" data-bs-target="#collapseTemperature">
                            <i style={{ color: "#2AA7FF" }} className="bi bi-thermometer-half"></i>&nbsp;&nbsp;大气温度</button>
                        <ul className="collapse" id="collapseTemperature" style={{ backgroundColor: "#6E99DA", color: "", margin: 0 }}>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="T" onChange={e => this.showTemperatureOnMap(e.target.value, "实时温度")} /> 实时温度</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MINT" onChange={e => this.showTemperatureOnMap(e.target.value, "本小时最低温度")} /> 本小时最低</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MAXT" onChange={e => this.showTemperatureOnMap(e.target.value, "本小时最高温度")} /> 本小时最高</label> </li>
                        </ul>
                        <button className="list-group-item list-group-item-action" style={{ background: "#ffffffb4", color: "", borderStyle: 'none', borderBottom: "1px solid gray" }} data-bs-toggle="collapse" data-bs-target="#collapseRain">
                            <i style={{ color: "#2AA7FF" }} className="bi bi-umbrella"></i>&nbsp;&nbsp;滑动雨量</button>
                        <ul className="collapse" id="collapseRain" style={{ backgroundColor: "#6E99DA", color: "", margin: 0 }}>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R30M" onChange={e => this.showRainOnMap(e.target.value, "30分钟滑动雨量")} /> 30分钟</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R01H" onChange={e => this.showRainOnMap(e.target.value, "1小时滑动雨量")} /> 01小时</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R02H" onChange={e => this.showRainOnMap(e.target.value, "2小时滑动雨量")} /> 02小时</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R03H" onChange={e => this.showRainOnMap(e.target.value, "3小时滑动雨量")} /> 03小时</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R06H" onChange={e => this.showRainOnMap(e.target.value, "6小时滑动雨量")} /> 06小时</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R12H" onChange={e => this.showRainOnMap(e.target.value, "12小时滑动雨量")} /> 12小时</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R24H" onChange={e => this.showRainOnMap(e.target.value, "1天滑动雨量")} /> 1天</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="R48H" onChange={e => this.showRainOnMap(e.target.value, "2天小时滑动雨量")} /> 2天</label> </li>
                        </ul>
                        <button className="list-group-item list-group-item-action" style={{ background: "#ffffffb4", color: "", borderStyle: 'none', borderBottom: "1px solid gray" }} data-bs-toggle="collapse" data-bs-target="#collapseWind">
                            <i style={{ color: "#2AA7FF" }} className="bi bi-wind"></i>&nbsp;&nbsp;风速风向</button>
                        <ul className="collapse" id="collapseWind" style={{ backgroundColor: "#6E99DA", color: "", margin: 0 }}>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WDIDF" onChange={e => this.showWindOnMap(e.target.value, "瞬时风")} /> 瞬时风速</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WD2DF" onChange={e => this.showWindOnMap(e.target.value, "两分钟平均风")} /> 02分钟风速</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WD10DF" onChange={e => this.showWindOnMap(e.target.value, "十分钟平均风")} /> 10分风速</label> </li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="WD3SMAXDF" onChange={e => this.showWindOnMap(e.target.value, "小时极大风")} /> 小时极大风</label> </li>
                        </ul>
                        <button className="list-group-item list-group-item-action" style={{ background: "#ffffffb4", color: "", borderStyle: 'none', borderBottom: "1px solid gray" }} data-bs-toggle="collapse" data-bs-target="#collapseHumity">
                            <i style={{ color: "#2AA7FF" }} className="bi bi-moisture"></i>&nbsp;&nbsp;大气湿度</button>
                        <ul className="collapse" id="collapseHumity" style={{ backgroundColor: "#6E99DA", color: "", margin: 0 }}>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="U" onChange={e => this.showHumityOnMap(e.target.value, "实时湿度")} /> 实时湿度</label></li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MINU" onChange={e => this.showHumityOnMap(e.target.value, "本小时最低湿度")} /> 本小时最低</label></li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MAXU" onChange={e => this.showHumityOnMap(e.target.value, "本小时最高湿度")} /> 本小时最高</label></li>
                        </ul>
                        <button className="list-group-item list-group-item-action" style={{ background: "#ffffffb4", color: "", borderStyle: 'none', borderBottom: "1px solid gray" }} data-bs-toggle="collapse" data-bs-target="#collapsePressure">
                            <i style={{ color: "#2AA7FF" }} className="bi bi-speedometer"></i>&nbsp;&nbsp;大气气压</button>
                        <ul className="collapse" id="collapsePressure" style={{ backgroundColor: "#6E99DA", color: "", margin: 0 }}>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="P" onChange={e => this.showPressureOnMap(e.target.value, "实时气压")} /> 实时气压</label></li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MINP" onChange={e => this.showPressureOnMap(e.target.value, "本小时最低气压")} /> 本小时最低</label></li>
                            <li style={{ margin: 2 }}><label><input name='wstationItem' type='radio' value="MAXP" onChange={e => this.showPressureOnMap(e.target.value, "本小时最高气压")} /> 本小时最高</label></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div ref={this.rasterColorTables} style={{ position: "fixed", zIndex: 520, bottom: 10, left: 560 }}>
                <ColorBarPC />
            </div>
        </>
    }
    private close() {
        this.weatherstationDiv.current.style.display = "none";
        this.showRasterImage();
        if (this.lg) this.lg.clearLayers();
        var awsTitleDiv = document.getElementById("awsTitleDiv");
        while (awsTitleDiv.lastChild) awsTitleDiv.removeChild(awsTitleDiv.lastChild);
    }
    private swiftForm() {
        let cardbody = this.weatherstationDiv.current.querySelector(".card-body") as HTMLDivElement;
        let dash = this.weatherstationDiv.current.querySelector(".card-header .bi-dash") as HTMLSpanElement;
        let xbtn = this.weatherstationDiv.current.querySelector(".bi.bi-x") as HTMLSpanElement;
        if (dash) {
            cardbody.style.display = "none";
            dash.className = "bi bi-app";
            this.weatherstationDiv.current.style.width = "80px";
            xbtn.style.display = "none";
        } else {
            dash = this.weatherstationDiv.current.querySelector(".card-header .bi-app") as HTMLSpanElement;
            cardbody.style.display = "";
            dash.className = "bi bi-dash";
            this.weatherstationDiv.current.style.width = "242px";
            xbtn.style.display = "";
        }
    }
    private getPopNode() {
        return <div>
            <div className="modal-dialog" style={{ width: 1000 }}>
                <div className="modal-content" style={{ width: 1000, color: "#fff" }}>
                    <div className="modal-header" style={{ padding: 5, backgroundColor: "#144373" }}>
                        <h6 className="modal-title" style={{ color: "#fff" }}>单站时序图</h6>
                        <b onClick={() => this.props.map.closePopup()} className="bi bi-x-circle" style={{ color: "#fff", background: "#144373", fontSize: 22 }}></b>
                    </div>
                    <div className="modal-body" style={{ background: "#132A49", padding: 10 }}>
                        <div>要素选择：
                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" name="btnradioWeather" value={"rain"} id="btnradio1" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio1">小时降水</label>
                                <input type="radio" className="btn-check" name="btnradioWeather" value={"temperature"} id="btnradio2" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio2">气温</label>
                                <input type="radio" className="btn-check" name="btnradioWeather" value={"wind"} id="btnradio3" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio3">风</label>
                                <input type="radio" className="btn-check" name="btnradioWeather" value={"humidity"} id="btnradio4" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio4">湿度</label>
                                <input type="radio" className="btn-check" name="btnradioWeather" value={"pressure"} id="btnradio5" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio5">气压</label>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp; 时间选择：
                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" value={12} name="btnradioTime" id="btnradio12" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio12">近12小时</label>
                                <input type="radio" className="btn-check" value={24} name="btnradioTime" id="btnradio24" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio24">近24小时</label>
                                <input type="radio" className="btn-check" value={48} name="btnradioTime" id="btnradio48" autoComplete="off" onChange={this.buildStationChart.bind(this)} />
                                <label className="btn btn-outline-primary" htmlFor="btnradio48">近48小时</label>
                            </div>
                        </div>
                        <div ref={this.warningDiv} style={{ marginTop: 10, fontSize: 16}}>
                        </div>
                        <div id="weatherStionHighchartDiv" style={{ marginTop: 10, height: 400 }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    private getWindSearial(start: Date, endt: Date, items: iLOCALOBTHOURD[]) {
        let buildseries: { x: number, y: number, wd: number }[] = [];
        Enumerable.from(items).orderBy(t => t.DDATETIME).forEach(t => {
            buildseries.push({
                x: t.DDATETIME.getTime(), y: t.WD3SMAXDF > -100 ? t.WD3SMAXDF : NaN,
                wd: t.WD3SMAXDD
            });
        });
        let windbSeries = buildseries.map(t => [t.x, t.y, t.wd]);
        return {
            credits: { enabled: false },
            title: {
                style: {
                    color: '#fff',
                    font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
                }, text: this.selectOBT.OBTNAME + "(" + this.selectOBT.OBTID + ")小时极大风时序图"
            },
            subtitle: {
                style: {
                    color: '#fff',
                    font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
                }, text: start.toStr("yyyy-MM-dd HH:mm至") + endt.toStr("yyyy-MM-dd HH:mm")
            },
            chart: {
                type: 'spline', spacing: 1, backgroundColor: "#122A46",
                style: { color: "#fff" }
            },
            yAxis: [
                {
                    title: { text: "小时极大风m/s" }, labels: { style: { color: "#ffffff" } }, gridLineColor: "#6E6D6C"
                }
            ],
            xAxis: {
                title: { text: '' },
                labels: { style: { color: "#ffffff" } },
                type: "datetime",
                tickInterval: 60 * 60 * 1000
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y}%\r\n{point.x}',
                pointFormatter: function (this: any) { return `${this.y}m/s\n` + new Date(this.x).toStr("dd日HH时mm分") }
            },
            series: [{
                name: '',
                data: buildseries
            }, {
                type: 'windbarb',
                data: windbSeries,
                name: 'Wind',
                color: "#fff",
                showInLegend: false,
                tooltip: {
                    valueSuffix: ' m/s'
                }
            }]
        };
    }
    private getSearial(start: Date, endt: Date, items: iLOCALOBTHOURD[], field: string, fname: string, unit: string, seriesType: string) {
        let buildseries: [number, number][] = [];
        Enumerable.from(items).orderBy(t => t.DDATETIME).forEach((t: any) => { buildseries.push([t.DDATETIME.getTime(), t[field] > -100 ? t[field] : NaN]); });        
        return {
            credits: { enabled: false },
            title: {
                style: {
                    color: '#fff',
                    font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
                }, text: this.selectOBT.OBTNAME + "(" + this.selectOBT.OBTID + ")" + fname + "时序图"
            },
            subtitle: {
                style: {
                    color: '#fff',
                    font: 'bold 12px "Trebuchet MS", Verdana, sans-serif'
                }, text: start.toStr("yyyy-MM-dd HH:mm至") + endt.toStr("yyyy-MM-dd HH:mm")
            },
            chart: {
                type: 'spline', spacing: 1, backgroundColor: "#122A46",
                style: { color: "#fff" }
            },
            yAxis: [
                {
                    title: { text: fname + unit, style: { color: "#fff" } },
                    labels: { style: { color: "#ffffff" } }, gridLineColor: "#6E6D6C"
                }
            ],
            xAxis: {
                title: { text: '' },
                labels: { style: { color: "#ffffff" } },
                type: "datetime",
                tickInterval: 60 * 60 * 1000
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.y}%\r\n{point.x}',
                pointFormatter: function (this: any) { return `${this.y}${unit}\n` + new Date(this.x).toStr("dd日HH时mm分") }
            },
            series: [{
                name: '',
                type: seriesType,
                data: buildseries
            }]
        };
    }

    private buildStationChart() {
        let start: Date = new Date(), endt: Date = new Date();
        for (var radio of document.getElementsByName("btnradioTime") as NodeListOf<HTMLInputElement>) {
            if (radio.checked) {
                start.setHours(start.getHours() - +radio.value, 0, 0, 0);
                break;
            }
        }
        let weatherType: string;
        for (radio of document.getElementsByName("btnradioWeather") as NodeListOf<HTMLInputElement>) {
            if (radio.checked) {
                weatherType = radio.value;
                break;
            }
        }
        if (weatherType) {
            backendMethod.getSZObtHourd(this.selectOBT.OBTID, start, endt).then(items => {
                let chartDiv = document.getElementById("weatherStionHighchartDiv");
                var options: any;
                if (weatherType == "rain") options = this.getSearial(start, endt, items, "R01H", "小时雨量", "mm", "column");
                else if (weatherType == "temperature") options = this.getSearial(start, endt, items, "T", "温度", "°C", "spline");
                else if (weatherType == "wind") options = this.getWindSearial(start, endt, items);
                else if (weatherType == "humidity") options = this.getSearial(start, endt, items, "U", "湿度", "%", "spline");
                else if (weatherType == "pressure") options = this.getSearial(start, endt, items, "P", "气压", "hPa", "spline");
                Highcharts.chart(chartDiv, options);
            });
        }
    }

    private buildStationChart1() {
        let start: Date = new Date(), endt: Date = new Date();
        start.setHours(start.getHours() - 240, 0, 0, 0);

        backendMethod.getSZObtHourds1(this.selectOBT.AREAID, start, endt).then(items => {
            //items = Enumerable.from(items).where((t: any) => t.AREAID == this.selectOBT.AREAID).toArray();
            backendMethod.getObtDayDataByD30(this.selectOBT.OBTID, endt).then(items1 => {
                var warnings = getStreetDisaster1(this.selectOBT.OBTID, endt, items, items1);

                var normal = Enumerable.from(warnings).where((t: any) => t.warnCode == "normal").toArray();
                var cold = Enumerable.from(warnings).where((t: any) => t.warnCode == "cold").toArray();
                var hot = Enumerable.from(warnings).where((t: any) => t.warnCode == "hot").toArray();
                var wind = Enumerable.from(warnings).where((t: any) => t.warnCode == "wind").toArray();
                var coldwind = Enumerable.from(warnings).where((t: any) => t.warnCode == "coldwind").toArray();
                var drought = Enumerable.from(warnings).where((t: any) => t.warnCode == "drought").toArray();
                var storm = Enumerable.from(warnings).where((t: any) => t.warnCode == "storm").toArray();
                var html = ""
                if (warnings.length == 0 || normal.length == warnings.length) {
                    html = "<div>当前天气正常，没有作物受到灾害影响。</div>"
                }
                if (cold.length) {
                    Enumerable.from(cold).orderBy(t => t.forValue).forEach(function (t: any) {
                        html += "<div>" + t.warnMsg + "</div>"
                    });
                }
                if (hot.length) {
                    Enumerable.from(hot).orderBy(t => t.forValue).forEach(function (t: any) {
                        html += "<div>" + t.warnMsg + "</div>"
                    });
                }
                if (wind.length) {
                    Enumerable.from(wind).orderBy(t => t.forValue).forEach(function (t: any) {
                        html += "<div>" + t.warnMsg + "</div>"
                    });
                }
                if (coldwind.length) {
                    Enumerable.from(coldwind).orderBy(t => t.forValue).forEach(function (t: any) {
                        html += "<div>" + t.warnMsg + "</div>"
                    });
                }
                if (drought.length) {
                    Enumerable.from(drought).orderBy(t => t.forValue).forEach(function (t: any) {
                        html += "<div>" + t.warnMsg + "</div>"
                    });
                }
                if (storm.length) {
                    Enumerable.from(storm).orderBy(t => t.forValue).forEach(function (t: any) {
                        html += "<div>" + t.warnMsg + "</div>"
                    });
                }
                html ="<table class='table table-sm table-bordered' style='margin:5px 0px;color:#fff'><tr><th>灾种</th><th>影响作物</th><th>天气状况</th></tr>"
                for (let item of warnings) {
                    html += "<tr><td>" + item.warnName + "</td><td>" + item.fruitName + "</td><td>" + item.warnMsg + "</td></td>"
                }
                html += "</table>"

                this.warningDiv.current.innerHTML = html;
            });

        });

    }


    private setMouseEvent(typename: string, field: string, unit: string) {
        this.lg.off("mouseover").on("mouseover", (evt) => {
            if (!(this.props.map as any).fixedPopwindow) {
                let m: iLOCALOBTMIND = evt.layer.options.attributes;
                L.popup({ offset: [0, -5] }).setLatLng(evt.latlng)
                    .setContent(m.OBTNAME + "(" + m.OBTID + ")<br/>" + m.DDATETIME.toStr("yyyy-MM-dd HH:mm") + "<br/>" + typename + "：" + (m as any)[field] + unit).openOn(this.props.map);
            }
        });
        this.lg.off("mouseout").on("mouseout", () => { if (!(this.props.map as any).fixedPopwindow) this.props.map.closePopup() });
        this.lg.off("click").on("click", (evt) => {
            (this.props.map as any).fixedPopwindow = true;
            this.selectOBT = evt.layer.options.attributes;
            let popcontent = document.createElement("div") as HTMLElement;
            ReactDOM.render(this.getPopNode(), popcontent, () => {
                L.popup({ autoClose: false, keepInView: true, maxWidth: 1000, className: "customDarkPopup" }).setLatLng(evt.latlng).setContent(popcontent).openOn(this.props.map)
                    .on("remove", () => (this.props.map as any).fixedPopwindow = false);
                this.props.map.setView(evt.latlng)
                let btnradio12 = document.getElementById("btnradio12") as HTMLInputElement;
                btnradio12.checked = true;
                for (var radio of document.getElementsByName("btnradioWeather") as NodeListOf<HTMLInputElement>) {
                    if (radio.value == this.fieldClass) {
                        radio.checked = true;
                        break;
                    }
                }
                this.buildStationChart();
                this.buildStationChart1();
            });
        });
    }

    private showTitle(fieldclass: string, field: string, title: string, closecallback: (f: string, uncheckedInput: boolean) => void) {
        let awsTitleDiv = document.getElementById("awsTitleDiv");
        awsTitleDiv.style.display = "";
        this.fieldClass = fieldclass;
        let olddiv = awsTitleDiv.querySelector("div[fieldclass='" + fieldclass + "']");
        if (olddiv) olddiv.parentElement.removeChild(olddiv);
        let div = awsTitleDiv.appendElement("div", { "font-size": "15px" }).appendText(this.ddatetime.toStr("MM月dd日 HH时mm "));
        div.style.border = "1px solid green";
        div.style.padding = "4px";
        div.setAttribute("fieldclass", fieldclass);
        div.appendElement("b", { color: "red" }).appendText(title);
        let close = div.appendElement("i", { color: "red" });
        close.className = "bi bi-x-lg";
        close.onclick = () => {
            awsTitleDiv.removeChild(div);
            closecallback.apply(this, [field, true]);
        }
    }
    private removeLayer(field: string, uncheckedInput = false) {
        this.props.map.eachLayer(t => {
            if (t.options.attribution == "stationLayer")
                this.props.map.removeLayer(t);
        });
        if (this.lg) {
            this.props.map.removeLayer(this.lg);
            delete this.lg;
        }
        if (this.fieldClass) {
            let awsTitleDiv = document.getElementById("awsTitleDiv");
            let olddiv = awsTitleDiv.querySelector("div[fieldclass='" + this.fieldClass + "']");
            if (olddiv) olddiv.parentElement.removeChild(olddiv);
            this.fieldClass = null;
        }
        if (uncheckedInput)
            (this.weatherstationDiv.current.querySelector("input[value='" + field + "']") as HTMLInputElement).checked = false;
    }
    private markerFilterMash(markerOptions: any) {
        var render = () => {
            this.lg.clearLayers();
            let already = [];            

            for (var op of markerOptions) {
                let pointxy = this.props.map.latLngToContainerPoint(op.latlng); 
                let pass = true;
                for (var p of already) {
                    var distance = Math.abs(p.x - pointxy.x) + Math.abs(p.y - pointxy.y);
                    if (distance < 30) {
                        pass = false;
                        break;
                    }
                }
                if (pass) {
                    L.marker(op.latlng, op).addTo(this.lg);
                    already.push(pointxy);
                }
            }
        }
        if (!this.lg) {
            this.lg = L.featureGroup([], { attribution: "stationLayer" }).addTo(this.props.map);

            this.props.map.off("zoomend");
            this.props.map.on("zoomend", () => {
                render();
            });
        }
        render();
    }
    private showRainOnMap(filed: string, name: string) {
        this.removeLayer(filed);
        this.setDateinputChangeEvent(filed, name, this.showRainOnMap);
        if (filed) backendMethod.getSZObtMind(this.ddatetime).then(minds => {
            this.showTitle("rain", filed, name, (f, u) => { this.removeLayer(f, u); this.showRasterImage(); });
            this.showRasterImage(filed, this.ddatetime);
            let markers = [];
            for (var m of minds) {
                if (m.R_STAT == "1" || m.AREAID == "5949312") {
                    var value = (m as any)[filed];
                    if (value > -100) {
                        var options = { latlng: L.latLng(m.LAT, m.LNG), icon: L.divIcon({ className: '', html: `<span style='color:#${value > 0 ? "fff" : "000"}'>` + value + "</span>" }), attributes: m };
                        markers.push(options);
                    }
                }
            }
            this.markerFilterMash(markers);
            this.setMouseEvent(name, filed, "mm");
        });
        else this.showRasterImage();
    }
    private showWindOnMap(filed: string, name: string) {
        this.removeLayer(filed);
        this.setDateinputChangeEvent(filed, name, this.showWindOnMap);
        if (filed) backendMethod.getSZObtMind(this.ddatetime).then(minds => {
            this.showTitle("wind", filed, name, (f, u) => { this.removeLayer(f, u); this.showRasterImage(); });
            this.showRasterImage(filed, this.ddatetime);
            let markers = [];
            for (var m of minds) {
                if (m.W_STAT == "1" || m.AREAID == "5949312") {
                    var wind = (m as any)[filed];
                    var rotate = (m as any)[filed.replace("DF", "DD")];
                    var windbard = (m as any)[filed + "_BAR"];
                    if (wind > -100) {
                        //var barcolor = wind >= 10.8 ? "#f00" : "#000";
                        let wh = this.props.map.getZoom() / 12 * 60
                        var wdb = this.getArrowImgUrl(wind, rotate, wh);
                        var myIcon = L.icon({
                            iconUrl: wdb,
                            iconSize: [wh, wh],
                            iconAnchor: [wh * 0.5, wh * 0.5],
                            popupAnchor: [0, 0]
                        });

                        let options: any = {
                            latlng: L.latLng(m.LAT, m.LNG),
                            icon: myIcon,
                            attributes: m
                        };
                        markers.push(options);
                    }
                }
            }
            this.markerFilterMash(markers);
            this.setMouseEvent(name, filed, "m/s");
        });
        else this.showRasterImage();
    }
    private showTemperatureOnMap(filed: string, name: string) {
        this.setDateinputChangeEvent(filed, name, this.showTemperatureOnMap);
        if (filed) backendMethod.getSZObtMind(this.ddatetime).then(minds => {
            this.removeLayer(filed);
            this.showRasterImage();
            this.showTitle("temperature", filed, name, (f, u) => { this.removeLayer(f, u); this.showRasterImage(); });
            this.showRasterImage(filed, this.ddatetime);
            let markers = [];
            for (var m of minds) {
                if (m.T_STAT == "1" || m.AREAID == "5949312") {
                    var value = (m as any)[filed];
                    if (value > -100) {
                        var options = { latlng: L.latLng(m.LAT, m.LNG), icon: L.divIcon({ className: '', html: "<span style='color:#000'>" + value + "</span>" }), attributes: m };
                        markers.push(options);
                    }
                }
            }
            this.markerFilterMash(markers);
            this.setMouseEvent(name, filed, "摄氏度");
        });
        else this.showRasterImage();
    }
    private showHumityOnMap(filed: string, name: string) {
        this.removeLayer(filed);
        this.setDateinputChangeEvent(filed, name, this.showHumityOnMap)
        if (filed) backendMethod.getSZObtMind(this.ddatetime).then(minds => {
            this.showTitle("humidity", filed, name, this.removeLayer);
            this.showRasterImage(filed, this.ddatetime);
            let markers = [];
            for (var m of minds) {
                var value = (m as any)[filed];
                if (value > -100) {
                    var options = { latlng: L.latLng(m.LAT, m.LNG), icon: L.divIcon({ className: '', html: "<span style='color:#000'>" + value + "</span>" }), attributes: m };
                    markers.push(options);
                }
            }
            this.markerFilterMash(markers);
            this.setMouseEvent(name, filed, "%");
            this.datetimeInput.onchange = (e) => {
                this.ddatetime = new Date((e.target as HTMLInputElement).value);
                this.showHumityOnMap(filed, name);
            }
        });
        else this.showRasterImage();
    }
    private showPressureOnMap(filed: string, name: string) {
        this.removeLayer(filed);
        this.setDateinputChangeEvent(filed, name, this.showPressureOnMap);
        if (filed) backendMethod.getSZObtMind(this.ddatetime).then(minds => {
            this.showTitle("pressure", filed, name, this.removeLayer);
            this.showRasterImage(filed, this.ddatetime);
            let markers = [];
            for (var m of minds) {
                var value = (m as any)[filed];
                if (value > -100) {
                    var options = { latlng: L.latLng(m.LAT, m.LNG), icon: L.divIcon({ className: '', html: "<span style='color:#000'>" + value.toFixed(0) + "</span>" }), attributes: m };
                    markers.push(options);
                }
            }
            this.markerFilterMash(markers);
            this.setMouseEvent(name, filed, "hPa");
        });
        else this.showRasterImage();
    }
    private showRasterImage(field?: string, ddatetime?: Date) {
        var alltables = this.rasterColorTables.current.querySelectorAll("table");
        if (this.dataImageOverlaySZ) {
            this.props.map.removeLayer(this.dataImageOverlaySZ);
            this.props.map.removeLayer(this.dataImageOverlaySS);
            delete this.dataImageOverlaySZ;
            delete this.dataImageOverlaySS;
            for (var t of alltables)
                t.style.display = "none";
        }
        if (field) {

            var date = ddatetime.toStr('yyyyMMdd');
            var datetime_ = ddatetime.toStr('yyyyMMddHHmm');
            var urlsz = `/SZObtMindPic/${date}/${field}_${datetime_}_SZ.png`;
            var img = new Image()
            img.src = urlsz
            var proto = this
            img.onload = function () {
                proto.loadImageOver(urlsz);
            }

            img.onerror = function () {
                backendMethod.getSZObtMindPic(field, ddatetime, true).then(resp => {
                    if (resp) {
                        proto.loadImageOver(urlsz);
                    }
                });
                backendMethod.getSZObtMindPic(field, ddatetime, false).then(resp => {
                });
            }

            if (field.startsWith("R")) alltables[0].style.display = "";
            else if (field.startsWith("W")) alltables[1].style.display = "";
            else if (field.indexOf("T") > -1) alltables[2].style.display = "";
            else if (field.indexOf("U") > -1) alltables[3].style.display = "";
            else if (field.indexOf("P") > -1) alltables[4].style.display = "";
        }
    }
    private loadImageOver(urlsz: string) {
        let urlss = urlsz.replace('SZ.png', 'SS.png')
        if (this.dataImageOverlaySZ) {
            this.dataImageOverlaySZ.setUrl(urlsz);
            this.dataImageOverlaySS.setUrl(urlss);
        }
        else {

            this.dataImageOverlaySZ = L.imageOverlay(urlsz, L.latLngBounds(L.latLng(22.39, 113.74), L.latLng(22.88377, 114.63)), { attribution: "stationLayer" }).addTo(this.props.map);
            this.dataImageOverlaySS = L.imageOverlay(urlss, L.latLngBounds(L.latLng(22.66598, 114.88129), L.latLng(23.04056, 115.20)), { attribution: "stationLayer" }).addTo(this.props.map);
        }
    }

    //生成风向杆图标
    private getArrowImgUrl(wdf: number, angle: number, wh: number) {
        let canvas_ = document.createElement("canvas");
        canvas_.width = wh;
        canvas_.height = wh;
        let ctx = canvas_.getContext('2d');
        ctx.lineWidth = 1.3;
        ctx.strokeStyle = wdf >= 10.8 ? "#f00" : "#000";        
        let canvasPen = new wdb(ctx);
        canvasPen.draw(wh * 0.5, wh * 0.5, wdf, angle, 1); 
        ctx.stroke();
        return canvas_.toDataURL();
    }



}