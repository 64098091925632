import L from "leaflet";
import "leaflet/dist/leaflet.css";
import gcjOffsetTilemap from "../util/gcjTilemap.js";
import backendMethod from "../API/backend";

export default function leafletMap(container: HTMLDivElement, hideCustomLayerControl = false, zoom = 11, zoomPosition = "bottomright", layersControlPosition = "bottomleft", borderColor = "") {
    var map: L.Map;
    if (!(container as any)._leaflet_id) {
        window.map = map = L.map(container, {
            attributionControl: false,
            center: [22.6, 114.2],
            zoom: zoom,
            dragging: true,
            zoomControl: false
        });
        L.control.zoom({ position: (zoomPosition as any) }).addTo(map);
        CustomMapLayersControl(hideCustomLayerControl, layersControlPosition, borderColor);
    } else {
        map = window.map;
    }
    function CustomMapLayersControl(close = false, layersControlPosition = "bottomleft", borderColor="") {
        var satellite = gcjOffsetTilemap('http://webst0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&x={x}&y={y}&z={z}&style=6', { subdomains: "1234" });
        var traffic = gcjOffsetTilemap('http://webst0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&x={x}&y={y}&z={z}&style=8', { subdomains: "1234" });
        var administrator = gcjOffsetTilemap('http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&z={z}&x={x}&y={y}&style=8', { subdomains: "1234" });
        var tencentLand = getTencentLand();
        //var geoqDark = gcjOffsetTilemap("https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}");
        //var geoqGray = gcjOffsetTilemap("https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetGray/MapServer/tile/{z}/{y}/{x}").addTo(map);
        var ymMap = gcjOffsetTilemap("http://10.153.96.113/mapServer/{z}/{x}/{y}.png").addTo(map);
        var shenzhen = getShenzhenWMS(borderColor).addTo(map);
        var mlayers = L.control.layers(
            {
                "智图": ymMap,
                "高德卫星地图": satellite,
                "高德行政地图": administrator,
                "腾讯地形图": tencentLand
            },
            { "深圳行政地图": shenzhen, "地理名称及交通": traffic },// 
            { collapsed: false, position: layersControlPosition as any }).addTo(map);
        var layersControl = map.getContainer().querySelector(".leaflet-control-layers") as HTMLElement;
        layersControl.style.color = "#000";
        layersControl.style.fontSize = "15px";
        layersControl.style.background = "#ffffffb3";
        layersControl.style.padding = "0";

        var layersHeader = document.createElement("div");
        layersHeader.style.backgroundColor = "#0E77D3";
        layersHeader.style.borderRadius = "3px";
        layersHeader.style.color = "#fff";
        layersHeader.appendText("地图控制");
        layersHeader.style.padding = "5px";
        let closebtn = layersHeader.appendElement("span", { float: "right", "font-size": "18px" });
        closebtn.className = "bi bi-dash";
        closebtn.onclick = function (e) {
            mlayers.collapse();
            layersHeader.style.display = "none";
            layersControl.style.background = "";
        }
        let oldExpand = mlayers.expand;
        mlayers.expand = function () {
            layersHeader.style.display = "";
            return oldExpand.apply(mlayers);
        }
        layersControl.prepend(layersHeader);
        var layersBody = layersControl.querySelector(".leaflet-control-layers-list") as HTMLElement;
        layersBody.style.background = "";
        layersBody.style.padding = "15px";
        if (close) closebtn.onclick(null);
    }
    //腾讯地形图
    function getTencentLand() {
        return new (L.TileLayer.extend({
            autoTileSubdomain: ["0", "1", "2", "3"],
            getTileUrl: function (this: any, tilePoint: { x: number, y: number, z: number }) {
                let _y = Math.pow(2, tilePoint.z) - 1 - tilePoint.y;
                return `http://p${this.autoTileSubdomain[tilePoint.x % 4]}.map.gtimg.com/demTiles/${tilePoint.z}/${Math.floor(tilePoint.x / 16.0)}/${Math.floor(_y / 16.0)}/${tilePoint.x}_${_y}.jpg`;
            }
        }))();
    }
    //深圳WMS地图
    function getShenzhenWMS(borderColor = "") {
                
        var _shenzhenWMSLayer: L.ImageOverlay;
        var buildUrl = (bound: L.LatLngBounds) => {
            let size = map.getSize();
            return backendMethod.getShenzhenWMS(size.x, size.y, bound.getWest(), bound.getSouth(), bound.getEast(), bound.getNorth(), borderColor);
        }
        var bound = map.getBounds();
        _shenzhenWMSLayer = L.imageOverlay(buildUrl(bound), bound);
        var updateUrl = () => {
            bound = map.getBounds();
            _shenzhenWMSLayer.setUrl(buildUrl(bound));
        };
        _shenzhenWMSLayer.on("remove", () => {
            map.off("moveend", updateUrl);
        });
        _shenzhenWMSLayer.on("add", () => {
            map.on("moveend", updateUrl);
            var img = _shenzhenWMSLayer.getElement();
            img.onload = () => {
                _shenzhenWMSLayer.setBounds(bound);
            }
        });
        return _shenzhenWMSLayer;
    }
    return map;
}

