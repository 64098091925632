import { useEffect, createRef, useState } from 'react';
import { Modal } from "bootstrap";
import backendMethod from '../API/backend';
import Highcharts from "highcharts";
import LINQ from "linq";

export default function() {
    const [path, setPath] = useState("/");
    let navUL = createRef<HTMLUListElement>();
    let nav01 = createRef<HTMLAnchorElement>();
    let nav02 = createRef<HTMLAnchorElement>();
    let nav03 = createRef<HTMLAnchorElement>();
    let nav04 = createRef<HTMLAnchorElement>();
    let nav05 = createRef<HTMLAnchorElement>();
    let nav06 = createRef<HTMLAnchorElement>();
    let forecastChartDiv = createRef<HTMLDivElement>();
    let disarsterWaring = createRef<HTMLDivElement>();

    useEffect(() => {
        if (document.location.pathname == "/")
            nav01.current.className = "nav-link active";
        else if (document.location.pathname.indexOf("forecast") > 0)
            nav02.current.className = "nav-link active";
        else if (document.location.pathname.indexOf("warning") > 0)
            nav03.current.className = "nav-link active";
        else if (document.location.pathname.indexOf("express") > 0)
            nav04.current.className = "nav-link active";
        else if (document.location.pathname.indexOf("news") > 0)
            nav05.current.className = "nav-link active";
        else if (document.location.pathname.indexOf("contacts") > 0)
            nav06.current.className = "nav-link active";
    });
    return <>
        <ul className="nav nav-pills" ref={navUL} style={{ backgroundColor: "#00256B", paddingLeft: 10 }}>
            <li className="nav-item">
                <a className="nav-link" ref={nav01} href="/" style={{ color: "#fff" }}>实况监测</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/page/forecast" ref={nav02} style={{ color: "#fff" }}>农气预报</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/page/warning" ref={nav03} style={{ color: "#fff" }}>灾害预警</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/page/express" ref={nav04} style={{ color: "#fff" }}>气象助农直通车</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/page/news" ref={nav05} style={{ color: "#fff" }}>为农服务</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/page/contacts" ref={nav06} style={{ color: "#fff" }}>深农联系卡</a>
            </li>
        </ul>
    </>
}