import { createRef, useEffect } from 'react';
import backendMethod from '../API/backend';

export default function AgroRecommand(props: { stylePosition: any }) {
    var advice = createRef<HTMLOListElement>();
    var cstyle = { background: "#ffffffb5", color: "#000" };
    cstyle = Object.assign(cstyle, props.stylePosition);

    useEffect(() => {
        loadAgroAdvice();
    });

    function loadAgroAdvice() {
        backendMethod.getAgroAdvice().then(advices => {
            while (advice.current.lastChild)
                advice.current.removeChild(advice.current.lastChild);
            var list = advices.content.split('\n')
            for (var li_ of list) {
                if (li_.trim() === "") continue;
                let li = advice.current.appendElement("li"), a: HTMLAnchorElement;
                li.appendText(li_);
            }
        });
    }
    return <>
        <div className="card" style={cstyle}>
            <div className="card-header" style={{ fontWeight: "bold", color: "#fff", background: "#144373", padding: 3 }}>
                农业生产建议
            </div>
            <div className="card-body" style={{ padding: 5, fontSize: 14 }}>
                <ol ref={advice}>
                    <li>充分利用冬季前期有利的光温资源抓紧时间开展冬种生产；进入少雨季节，各地要注意节约用水和计划好冬种生产用水，做好农田水利建设和水库蓄水保水工作</li>

                    <li>加强果树和蔬菜田间管理。荔枝龙眼控冬梢培育健壮结果母枝，成熟水果及时采收并做好树势恢复，落叶果树做好清园、修剪和施基肥工作；做好蔬菜水肥管理和防病虫害工作，增加上市量，缓解市场蔬菜供应。</li>

                    <li>近期冷空气活动频繁，天气冷暖变化大，建议做好农业、畜牧和水产养殖业的防寒保温工作。</li>
                </ol>
            </div>
        </div>
    </>
}