import { Component, createRef } from 'react';
import backendMethod from '../API/backend';
import Enumerable from 'linq';
import { fruitDanger2  } from "../API/disarter";
import query from '../util/myquery';
import Recommand from "../comonent/recommand";

//export default class DisasterRecognize extends Component<{ mobile?: boolean, onCallback?: (data: any[]) => void }>{
export default class Advice extends Component < {mobile?: boolean }> {
    private cardBodyLastWeek = createRef<HTMLDivElement>();
    private cardBodyForecast = createRef<HTMLDivElement>();

    public constructor(props: { mobile?: boolean }) {
        super(props);


    }
    public componentDidMount(): void {
        var proto = this
        const tabEl = document.querySelector('#profile')
        tabEl.addEventListener('shown.bs.tab', function () { proto.loadForecast() });
        this.loadHistory();


    }

    public loadForecast(date?: Date) {
        var forecastStartTime = new Date();
        forecastStartTime.setDate(forecastStartTime.getDate() - 1);
        forecastStartTime.setHours(20, 0, 0, 0);
        if (date) forecastStartTime = date;
        this.cardBodyForecast.current.innerHTML = '<h4><i class="bi bi-minecart-loaded"></i> 数据加载中……</h4>';
        backendMethod.getStreetWarningInfo(forecastStartTime).then(allStreet => {
            var allStreet_ = Enumerable.from(allStreet).where(p => p.MAXT > -99);
            var maxt48h = allStreet_.orderByDescending(t => t.MAXT).first();
            var maxt48hAvg = +allStreet_.average(t => t.MAXT).toFixed(0);

            allStreet_ = Enumerable.from(allStreet).where(p => p.MINT > -99);
            var mint48h = allStreet_.orderBy(t => t.MINT).first();
            var mint48hAvg = +allStreet_.average(t => t.MINT).toFixed(0);

            allStreet_ = Enumerable.from(allStreet).where(p => p.MAXGUST > -99);
            var maxgust = allStreet_.orderByDescending(t => t.MAXGUST).first();

            var content = "<div style='width:90%'><div style='font-weight:bold;margin:8px'>未来48小时天气趋势：";

            content += `全市最高温<b style='color:#f00'>${maxt48h.MAXT.toFixed(0)}</b>℃(${maxt48h.Name})， `;
            content += `最低温<b style="color:#f00">${mint48h.MINT.toFixed(0)}</b>℃(${mint48h.Name})， `;
            content += `最大风速<b style="color:#f00">${maxgust.MAXGUST.toFixed(0)}</b>m/s(${maxgust.Name})</div>`;

            content += "<div style='font-weight:bold;margin:8px'>预报平均值：日最高温平均值";
            content += `<b style='color:#f00'>${maxt48hAvg}</b>℃，低温平均值<b style='color:#f00'>${mint48hAvg}</b>℃</div>`;

            for (var fruit of fruitDanger2()) {
                if (fruit.temperature) {
                    content += "<table style='width:100%'><tr><td style='width:80px'><h6 style='margin: 0 0 0 10px; text-align:center'>";
                    content += `<img src='/images/${(fruit.vegetable ? fruit.code1 : fruit.code)}.png' style='width: 20px; height: 20px'/><br/> ${fruit.name}${fruit.period?"<br/>("+fruit.period+")":""}</h6></td>`;
                    content += "<td><hr style='margin:5px;width:100%'/><ul style='margin-bottom: 0;'>";
                    for (var warn of fruit.temperature) {
                        content += "<li>";
                        if (warn.code == "cold") {
                            if (mint48h.MINT <= warn.value) {
                                content += "<span style='color:red;font-size:15px' class='bi bi-exclamation-triangle-fill'></span>";
                            } else
                                content += "<span style='color:green;font-size:15px' class='bi bi-check2-circle'>无影响</span>";
                        } else if (warn.code == "hot") {
                            if (maxt48h.MAXT >= warn.value) {
                                content += "<span style='color:red;font-size:15px' class='bi bi-exclamation-triangle-fill'>注意高温</span>";
                            } else
                                content += "<span style='color:green;font-size:15px' class='bi bi-fire'>无影响</span>";
                        } else {
                            if ((mint48hAvg >= warn.value || maxt48hAvg >= warn.value) && maxt48h.MAXT < 35) {
                                content += "<span style='color:yellow;font-size:15px' class='bi bi-sun-fill'>适宜</span>";
                            } else
                                content += "<span style='color:green;font-size:15px' class='bi bi-check2-circle'>无影响</span>";
                        }
                        content += warn.warnMsg + "</li>";
                    }
                    content += "</ul></td></tr></table><div>"
                }
            }
            this.cardBodyForecast.current.innerHTML = content;
        });
    }
    public loadHistory() {
        backendMethod.getLastWeekObtDataByD().then(data => {
            var maxtWeek = Enumerable.from(data).where((t: any) => t.T_STAT == "1").orderByDescending(t => t.MAXT).first();
            var avgmaxtWeek = +Enumerable.from(data).where((t: any) => t.T_STAT == "1").average(t => t.MAXT).toFixed(1);

            var mintWeek = Enumerable.from(data).where((t: any) => t.T_STAT == "1").orderBy(t => t.MINT).first();
            var avgmintWeek = +Enumerable.from(data).where((t: any) => t.T_STAT == "1").average(t => t.MINT).toFixed(1);

            var r24hWeek = Enumerable.from(data).where((t: any) => t.R_STAT == "1").orderByDescending(t => t.R24H).first();
            var windWeek = Enumerable.from(data).where((t: any) => t.W_STAT == "1").orderByDescending(t => t.WD3SMAXDF).first();
            var content = `<div style="font-weight:bold;margin:8px">气象监测极值：过去一周全市最高温<b style="color:#f00">${maxtWeek.MAXT}</b>℃(${maxtWeek.OBTNAME})，
            最低温<b style="color:#f00">${mintWeek.MINT}</b>℃(${mintWeek.OBTNAME})，
            最大风速<b style="color:#f00">${windWeek.WD3SMAXDF}</b>m/s(${windWeek.OBTNAME}),
            最大日雨量<b style="color:#f00">${r24hWeek.R24H}</b>mm(${r24hWeek.OBTNAME})
            </div>`;
            content += `<div style="font-weight:bold;margin:8px">气象监测平均值：过去一周全市日最高温平均值<b style="color:#f00">${avgmaxtWeek}</b>℃，低温平均值<b style="color:#f00">${avgmintWeek}</b>℃
            </div>`;
            this.cardBodyLastWeek.current.innerHTML = content;
            var table = this.cardBodyLastWeek.current.appendElement("table");
            table.className = "table table-sm";
            table.style.color = "#000";
            var header = table.appendElement("thead", { "font-size": "12px" }).appendElement("tr", { "border-top": "solid 2px green", "border-bottom": "solid 2px green" });
            header.appendTd({ "min-width": "60px" }).appendText("过去一周气象要素一览表");
            header.appendTd().appendText("平均气温℃");
            header.appendTd().appendText("平均气温距平℃");
            header.appendTd().appendText("降水量mm");
            header.appendTd({ "min-width": "45px" }).appendText("降水量距平百分率");
            header.appendTd().appendText("最高气温℃");
            header.appendTd().appendText("最低气温℃");

            var body = table.appendElement("tbody", { "border-top": "solid 1px green" });
            var shenzhenAvgt = Enumerable.from(data).where(b => b.T_STAT == "1" && b.AVGT > -100).average(t => t.AVGT);
            //var shenzhenAvgRainDays = Enumerable.from(data).groupBy(b => b.DDATETIME.getDate()).where(b => b.sum(c => c.R24H) > 0).count();
            for (var area of Enumerable.from(data).groupBy(b => b.AREAID)) {
                var row = body.appendElement("tr", { "border": "none", "border-bottom": "solid 1px #727272" });
                row.appendTd({ "padding-right": "2px" }).appendText(backendMethod.areaId2name(area.key()));
                let areaAvgt = area.where(b => (b.AREAID == "5949312" || b.T_STAT == "1") && b.AVGT > -100).average(b => b.AVGT);
                row.appendTd().appendText(areaAvgt.toFixed(1));
                row.appendTd().appendText((areaAvgt - shenzhenAvgt).toFixed(1));
                let r24h = area.where(b => (b.AREAID == "5949312" || b.R_STAT == "1") && b.R24H > -100);//5949312: 深汕合作区
                var maxRain = r24h.groupBy(t => t.OBTID).select(obt => obt.sum(b => b.R24H)).orderByDescending(s => s).first();
                var avgRain = (r24h.sum(b => b.R24H)) / r24h.groupBy(t => t.OBTID).count();

                row.appendTd().appendText(maxRain.toFixed(1));
                row.appendTd().appendText((maxRain - avgRain).toFixed(1));
                // let cc = area.groupBy(b => b.DDATETIME.getDate()).where(d => d.sum(c => c.R24H) > 0).count();
                // row.appendTd().appendText(cc);
                // row.appendTd().appendText(cc - shenzhenAvgRainDays);
                row.appendTd().appendText(area.max(b => b.MAXT).toFixed(1));
                row.appendTd().appendText(area.where(b => b.MINT > -100).min(b => b.MINT).toFixed(1));
            }
            this.cardBodyLastWeek.current.appendElement("div").appendText("总降雨量计算：各站一周内日雨量相加，取最大值");
        });
    }
    public swiftForm() {
        let card = document.querySelector("#advice") as HTMLDivElement;
        let icon = document.querySelector("#swiftForm") as HTMLDivElement;
        if (card.style.display == "none") {
            card.style.display = "";
            icon.style.display = "none";
        } else {
            card.style.display = "none";
            icon.style.display = "";
        }
    }
    public render = () => {
        if (this.props.mobile)
            return <>
                <div style={{ position: "absolute", border: "2px solid #0E77D3", borderRadius: 5, zIndex: 520, top: 35, background: "#1F334A", padding: 5, color: "#fff" }}>
                    <ul className="nav nav-tabs" role="tablist" style={{ fontSize: 13 }}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home" style={{ padding: "5px 10px" }} data-bs-toggle="tab" data-bs-target="#homeTab" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">过去一周<br />天气回顾</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile" style={{ padding: "5px 10px" }} data-bs-toggle="tab" data-bs-target="#profileTab" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">未来48小时天气趋势<br />与农业生产建议</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div ref={this.cardBodyLastWeek} style={{ fontSize: 14 }} className="tab-pane fade show active" id="homeTab" role="tabpanel" aria-labelledby="home-tab">
                        </div>
                        <div className="tab-pane fade" id="profileTab" role="tabpanel" aria-labelledby="profile-tab">
                            <div ref={this.cardBodyForecast} style={{ fontSize: 14 }}></div>
                            <Recommand stylePosition={{}} />
                        </div>
                    </div>
                    <input type='button' value="关闭" onClick={function (e: any) { e.target.parentElement.style.display = "none" }} className='btn btn-primary' style={{ width: "100%", margin: 3 }}></input>
                </div>
            </>
        else return <>
            <i id="swiftForm" className="bi bi-front" onClick={(e) => this.swiftForm()} style={{ display: "none", position: "absolute", zIndex: 519, top: 62, right: 16, fontSize: 50, color: "#0E77D3" }}></i>
            <div id="advice" style={{ position: "absolute", border: "2px solid #0E77D3", borderRadius: 5, zIndex: 520, top: 52, right: 6, width: 500, background: "#CED4DAb8", padding: 5, color: "#000" }}>
                <ul className="nav nav-tabs" role="tablist" style={{ borderBottomColor: "gray" }}>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home" style={{ padding: 15 }} data-bs-toggle="tab" data-bs-target="#homeTab" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">过去一周天气回顾</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="profile" style={{ padding: 3 }} data-bs-toggle="tab" data-bs-target="#profileTab" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">未来48小时天气趋势<br />与农业生产建议</button>
                    </li>
                    <li>
                        <div style={{ padding: 20, width: 150 }}>
                            <span style={{ float: "right", fontSize: 25 }} className='bi bi-x' onClick={() => { query("#advice").hide() }}></span>
                            <span style={{ float: "right", fontSize: 25 }} className='bi bi-dash' onClick={() => { this.swiftForm() }}></span>
                        </div>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div ref={this.cardBodyLastWeek} className="tab-pane fade show active" style={{ fontSize: 14 }} id="homeTab" role="tabpanel" aria-labelledby="home-tab">
                    </div>
                    <div className="tab-pane fade" id="profileTab" role="tabpanel" aria-labelledby="profile-tab" style={{}}>
                        <div ref={this.cardBodyForecast} style={{ fontSize: 14, overflow: "scroll", maxHeight: 500 }}></div>
                        <Recommand stylePosition={{ width: "98%", marginTop: -18 }} />
                    </div>
                </div>
            </div >
        </>;

    }
}