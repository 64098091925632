export function ColorBarMobile() {
    return <>
        <table id="rasterColorTable_rain" style={{ display: "none" }}>
            <tr><td style={{ color: "#fff", width: 40 }}>降雨</td>
                <td style={{ height: 20, width: 60, background: "#A6F28E" }}>小雨</td>
                <td style={{ height: 20, width: 60, background: "#3DB93D" }}>中雨</td>
                <td style={{ height: 20, width: 60, background: "#61B8FF" }}>大雨</td>
                <td style={{ height: 20, width: 60, background: "#0000FE" }}>暴雨</td>
                <td style={{ height: 20, width: 60, background: "#FA00FA" }}>大暴雨</td>
                <td style={{ height: 20, width: 70, background: "#800243" }}>特大暴雨</td></tr>
        </table>
        <table id="rasterColorTable_wind" style={{ display: "none" }}>
            <tr><td style={{ color: "#000", width: 40 }}>风力</td>
                <td style={{ height: 20, width: 60, background: "#A6F28E" }}>1级</td>
                <td style={{ height: 20, width: 60, background: "#86BEF5" }}>6级</td>
                <td style={{ height: 20, width: 60, background: "#0783FD" }}>8级</td>
                <td style={{ height: 20, width: 60, background: "#072DFB" }}>9级</td>
                <td style={{ height: 20, width: 60, background: "#EF0FF6" }}>10级</td>
                <td style={{ height: 20, width: 60, background: "#F0500F" }}>12级</td></tr>
        </table>
        <table id="rasterColorTable_t" style={{ display: "none" }}>
            <tr><td style={{ color: "#000", width: 40 }}>温度</td>
                <td style={{ height: 20, width: 30, background: "#0000EE" }}>0℃</td>
                <td style={{ height: 20, width: 30, background: "#0000FF" }}>10</td>
                <td style={{ height: 20, width: 30, background: "#008BFF" }}>11</td>
                <td style={{ height: 20, width: 30, background: "#00BEFF" }}>12</td>
                <td style={{ height: 20, width: 30, background: "#00FFFF" }}>14</td>
                <td style={{ height: 20, width: 30, background: "#00E6CC" }}>16</td>
                <td style={{ height: 20, width: 30, background: "#00CC7E" }}>18</td>
                <td style={{ height: 20, width: 30, background: "#00B300" }}>20</td>
                <td style={{ height: 20, width: 30, background: "#7ECC00" }}>22</td>
                <td style={{ height: 20, width: 30, background: "#CCE600" }}>24</td>
                <td style={{ height: 20, width: 30, background: "#FFFF00" }}>26</td>
                <td style={{ height: 20, width: 30, background: "#FFDD00" }}>27</td>
                <td style={{ height: 20, width: 30, background: "#FFCC00" }}>28</td>
                <td style={{ height: 20, width: 30, background: "#FFAA00" }}>29</td>
                <td style={{ height: 20, width: 30, background: "#FF9900" }}>30</td>
                <td style={{ height: 20, width: 30, background: "#FF6600" }}>32</td>
                <td style={{ height: 20, width: 30, background: "#FF0000" }}>34</td>
                <td style={{ height: 20, width: 30, background: "#CC0000" }}>35</td>
                <td style={{ height: 20, width: 30, background: "#990000" }}>36</td>
                <td style={{ height: 20, width: 30, background: "#780000" }}>38</td>
            </tr>
        </table>
        <table id="rasterColorTable_h" style={{ display: "none" }}>
            <tr><td style={{ color: "#000", width: 40 }}>湿度</td>
                <td style={{ height: 20, width: 60, background: "#D95F30" }}>0%</td>
                <td style={{ height: 20, width: 60, background: "#F38838" }}>30</td>
                <td style={{ height: 20, width: 60, background: "#FBBA2A" }}>40</td>
                <td style={{ height: 20, width: 60, background: "#FDE77A" }}>50</td>
                <td style={{ height: 20, width: 60, background: "#FEF0B1" }}>60</td>
                <td style={{ height: 20, width: 60, background: "#B0EBF9" }}>70</td>
                <td style={{ height: 20, width: 60, background: "#63D6F1" }}>80</td>
                <td style={{ height: 20, width: 60, background: "#349AE4" }}>90</td>
                <td style={{ height: 20, width: 60, background: "#C5C5C7" }}>100</td>
            </tr>
        </table>
        <table id="rasterColorTable_p" style={{ display: "none" }}>
            <tr><td style={{ color: "#000", width: 40 }}>气压</td>
                <td style={{ height: 20, width: 60, background: "#D95F30" }}>800hPa</td>
                <td style={{ height: 20, width: 60, background: "#F38838" }}>850</td>
                <td style={{ height: 20, width: 60, background: "#FBBA2A" }}>900</td>
                <td style={{ height: 20, width: 60, background: "#FDE77A" }}>950</td>
                <td style={{ height: 20, width: 60, background: "#FEF0B1" }}>970</td>
                <td style={{ height: 20, width: 60, background: "#B0EBF9" }}>980</td>
                <td style={{ height: 20, width: 60, background: "#63D6F1" }}>990</td>
                <td style={{ height: 20, width: 60, background: "#349AE4" }}>1000</td>
                <td style={{ height: 20, width: 60, background: "#C5C5C7" }}>1010</td>
            </tr>
        </table>
    </>
}
export function ColorBarPC() {
    return <>
        <table id="rasterColorTable_rain" style={{ display: "none" }}>
            <tr style={{ color: "#000", fontSize: 11 }}><td style={{ width: 40 }}></td>
                <td>小雨&lt;10mm</td>
                <td>中雨&lt;25mm</td>
                <td>大雨&lt;50mm</td>
                <td>暴雨&lt;100mm</td>
                <td>大暴雨&lt;250mm</td>
                <td>特大暴雨&ge;250mm</td>
            </tr>
            <tr style={{ color: "#000", fontSize: 13 }}><td style={{ width: 40 }}>降雨</td>
                <td style={{ color: "#000", height: 20, width: 60, background: "#A6F28E" }}></td>
                <td style={{ color: "#000", height: 20, width: 60, background: "#3DB93D" }}></td>
                <td style={{ color: "#000", height: 20, width: 60, background: "#61B8FF" }}></td>
                <td style={{ height: 20, width: 60, background: "#0000FE" }}></td>
                <td style={{ height: 20, width: 60, background: "#FA00FA" }}></td>
                <td style={{ height: 20, width: 70, background: "#800243" }}></td>
            </tr>
        </table >
        <table id="rasterColorTable_wind" style={{ display: "none" }}>
            <tr style={{ color: "#000", fontSize: 12, textAlign: "center" }}><td></td>
                <td>1级</td>
                <td>6级</td>
                <td>8级</td>
                <td>9级</td>
                <td>10级</td>
                <td>12级</td>
            </tr>
            <tr><td style={{ color: "#000", width: 40 }}>风力</td>
                <td style={{ height: 20, width: 60, background: "#A6F28E" }}></td>
                <td style={{ height: 20, width: 60, background: "#86BEF5" }}></td>
                <td style={{ height: 20, width: 60, background: "#0783FD" }}></td>
                <td style={{ height: 20, width: 60, background: "#072DFB" }}></td>
                <td style={{ height: 20, width: 60, background: "#EF0FF6" }}></td>
                <td style={{ height: 20, width: 60, background: "#F0500F" }}></td>
            </tr>
        </table>
        <table id="rasterColorTable_t" style={{ display: "none" }}>
            <tr style={{ color: "#000", fontSize: 12 }}><td></td>
                <td>0℃</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>14</td>
                <td>16</td>
                <td>18</td>
                <td>20</td>
                <td>22</td>
                <td>24</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td>32</td>
                <td>34</td>
                <td>35</td>
                <td>36</td>
                <td>38</td>
            </tr>
            <tr><td style={{ color: "#000", width: 40 }}>温度</td>
                <td style={{ height: 20, width: 30, background: "#0000EE" }}></td>
                <td style={{ height: 20, width: 30, background: "#0000FF" }}></td>
                <td style={{ height: 20, width: 30, background: "#008BFF" }}></td>
                <td style={{ height: 20, width: 30, background: "#00BEFF" }}></td>
                <td style={{ height: 20, width: 30, background: "#00FFFF" }}></td>
                <td style={{ height: 20, width: 30, background: "#00E6CC" }}></td>
                <td style={{ height: 20, width: 30, background: "#00CC7E" }}></td>
                <td style={{ height: 20, width: 30, background: "#00B300" }}></td>
                <td style={{ height: 20, width: 30, background: "#7ECC00" }}></td>
                <td style={{ height: 20, width: 30, background: "#CCE600" }}></td>
                <td style={{ height: 20, width: 30, background: "#FFFF00" }}></td>
                <td style={{ height: 20, width: 30, background: "#FFDD00" }}></td>
                <td style={{ height: 20, width: 30, background: "#FFCC00" }}></td>
                <td style={{ height: 20, width: 30, background: "#FFAA00" }}></td>
                <td style={{ height: 20, width: 30, background: "#FF9900" }}></td>
                <td style={{ height: 20, width: 30, background: "#FF6600" }}></td>
                <td style={{ height: 20, width: 30, background: "#FF0000" }}></td>
                <td style={{ height: 20, width: 30, background: "#CC0000" }}></td>
                <td style={{ height: 20, width: 30, background: "#990000" }}></td>
                <td style={{ height: 20, width: 30, background: "#780000" }}></td>
            </tr>
        </table>
        <table id="rasterColorTable_h" style={{ display: "none" }}>
            <tr style={{ color: "#000", fontSize: 12 }}><td></td>
                <td>0%</td>
                <td>30</td>
                <td>40</td>
                <td>50</td>
                <td>60</td>
                <td>70</td>
                <td>80</td>
                <td>90</td>
                <td>100</td>
            </tr>
            <tr><td style={{ color: "#000", width: 40 }}>湿度</td>
                <td style={{ height: 20, width: 60, background: "#D95F30" }}></td>
                <td style={{ height: 20, width: 60, background: "#F38838" }}></td>
                <td style={{ height: 20, width: 60, background: "#FBBA2A" }}></td>
                <td style={{ height: 20, width: 60, background: "#FDE77A" }}></td>
                <td style={{ height: 20, width: 60, background: "#FEF0B1" }}></td>
                <td style={{ height: 20, width: 60, background: "#B0EBF9" }}></td>
                <td style={{ height: 20, width: 60, background: "#63D6F1" }}></td>
                <td style={{ height: 20, width: 60, background: "#349AE4" }}></td>
                <td style={{ height: 20, width: 60, background: "#C5C5C7" }}></td>
            </tr>
        </table>
        <table id="rasterColorTable_p" style={{ display: "none" }}>
            <tr style={{ color: "#000", fontSize: 12 }}><td></td>
                <td>800hPa</td>
                <td>850</td>
                <td>900</td>
                <td>950</td>
                <td>970</td>
                <td>980</td>
                <td>990</td>
                <td>1000</td>
                <td>1010</td>
            </tr>
            <tr><td style={{ color: "#000", width: 40 }}>气压</td>
                <td style={{ height: 20, width: 60, background: "#D95F30" }}></td>
                <td style={{ height: 20, width: 60, background: "#F38838" }}></td>
                <td style={{ height: 20, width: 60, background: "#FBBA2A" }}></td>
                <td style={{ height: 20, width: 60, background: "#FDE77A" }}></td>
                <td style={{ height: 20, width: 60, background: "#FEF0B1" }}></td>
                <td style={{ height: 20, width: 60, background: "#B0EBF9" }}></td>
                <td style={{ height: 20, width: 60, background: "#63D6F1" }}></td>
                <td style={{ height: 20, width: 60, background: "#349AE4" }}></td>
                <td style={{ height: 20, width: 60, background: "#C5C5C7" }}></td>
            </tr>
        </table></>
}