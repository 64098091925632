import { Component, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import Quill from "quill";
import "quill/dist/quill.snow.css"
import query from './util/myquery';
import backendMethod from './API/backend';
import { Tab } from "bootstrap";

export default class argoExpress extends Component {
    private catalogeditor = createRef<HTMLTextAreaElement>();
    private contenteditor = createRef<HTMLTextAreaElement>();
    private adviceeditor = createRef<HTMLTextAreaElement>();
    private hiddenArticleId = createRef<HTMLInputElement>();
    private classifySelect = createRef<HTMLInputElement>();
    private filename = createRef<HTMLInputElement>();
    private titleInput = createRef<HTMLLabelElement>();
    private acticleTime = createRef<HTMLInputElement>();
    private aTag = createRef<HTMLAnchorElement>();
    private tips = createRef<HTMLLabelElement>();
    //private catalogQuill: Quill;
    //private contentQuill: Quill;
    public componentDidMount(): void {
        this.setTimeSelect();
        var toolbarOptions: any = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', "video"],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        /*
        if (!this.catalogeditor.current.hasChildNodes()) {
            this.catalogQuill = new Quill(this.catalogeditor.current, {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: 'snow'
            });
        }
        if (!this.contenteditor.current.hasChildNodes()) {
            this.contentQuill = new Quill(this.contenteditor.current, {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: 'snow'
            });
        }*/
        query("#profile-tab").on("shown.bs.tab", this.loadArticleList.bind(this));
        this.loadArticleList();
        this.getAdvice();
    }

    public render() {
        return <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                        type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">文章列表</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="home-tab" onClick={e => this.hiddenArticleId.current.value = ""}
                        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">文章编辑</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="new-tab"
                        data-bs-toggle="tab" data-bs-target="#new" type="button" role="tab" aria-controls="new-tab-pane" aria-selected="true">手动下载</button>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" type="button" aria-selected="false" target="_blank"
                        href="http://10.148.8.18/ywwhome/vision/home.jsp?target=guangdong#/iframe/Iff808082016e588a588a9a80016e67f4f22639b7/Iff808082016e9b789b78dc25016e9b894e3c0039/Iff808082016e9b789b78dc25016e9b89a188003a">查看引用页</a>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="agroAdvice-tab"
                        data-bs-toggle="tab" data-bs-target="#advice" type="button" role="tab" aria-controls="new-tab-pane" aria-selected="true">农业生产建议</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <select className="classify">
                                        <option>气候影响评价</option>
                                        <option>气候公报</option>
                                        <option>农气情报预报</option>
                                        <option>灾害预警评估</option>
                                        <option>农用天气预报</option>
                                        <option>关键农事服务</option>
                                        <option>农气干旱监测</option>
                                    </select>
                                </th>
                                <th scope="col">标题</th>
                                <th scope="col">
                                    <select className="pubtime">
                                        <option value="">全部年份</option>
                                    </select>
                                </th>
                                <th scope="col">
                                    <select className="state">
                                        <option value="-1">全部</option>
                                        <option value="1">已发布</option>
                                        <option value="0" selected>未发布</option>
                                    </select>
                                </th>
                                <th scope="col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
                <div style={{ margin: 3 }} className="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab" >
                    <div style={{ margin: "10px 0" }}>
                        <b><label ref={this.titleInput}></label></b>&nbsp; &nbsp;
                        <input type='hidden' ref={this.hiddenArticleId} />
                        <input type='hidden' ref={this.classifySelect} />
                        <input type='hidden' ref={this.filename} />
                        <input type='hidden' ref={this.acticleTime} />

                        <button type="button" style={{ margin: "0 10px", padding: "0 5px" }} className="btn btn-primary" onClick={this.saveArticle.bind(this)}>保&nbsp; &nbsp;存</button>
                        <a ref={this.aTag} href="" target="_blank" style={{ margin: "0 10px", padding: "0 5px" }} className="btn btn-primary">&nbsp; pdf&nbsp; </a>

                        <b><label ref={this.tips} style={{ color: "red" }} ></label></b>&nbsp; &nbsp;
                    </div>
                    <textarea ref={this.catalogeditor} style={{ height: 300, width: "99%" }}></textarea>
                    <textarea ref={this.contenteditor} style={{ height: window.innerHeight - 300, width: "99%" }}></textarea>
                </div>
                <div className="tab-pane fade" id="new" role="tabpanel" aria-labelledby="new-tab" style={{ padding: "10px" }} >
                    &nbsp;&nbsp;分类：
                    <select className="classify">
                        <option>气候影响评价</option>
                        <option>气候公报</option>
                        <option>农气情报预报</option>
                        <option>灾害预警评估</option>
                        <option>农用天气预报</option>
                        <option>关键农事服务</option>
                        <option>农气干旱监测</option>
                    </select>
                    &nbsp;&nbsp;
                    文件名：<input type="text" id="txtFileName" style={{ width: 500 }} />

                    &nbsp;&nbsp;
                    <button type="button" style={{ margin: "0 10px", padding: "0 5px" }} className="btn btn-primary" onClick={this.loadFile.bind(this)}>下&nbsp; &nbsp;载</button>

                </div>
                <div style={{ margin: 3 }} className="tab-pane fade" id="advice" role="tabpanel" aria-labelledby="advice-tab" >
                    <div style={{ margin: "10px 0" }}>
                        <button type="button" style={{ margin: "0 10px", padding: "0 5px" }} className="btn btn-primary" onClick={this.saveAdvice.bind(this)}>保&nbsp; &nbsp;存</button>

                    </div>
                    <textarea ref={this.adviceeditor} style={{ height: 300, width: "99%" }}></textarea>
                </div>
            </div>
        </>;
    }
    private saveArticle() {
        var id = this.hiddenArticleId.current.value;
        var classify = this.classifySelect.current.value;
        var title = this.titleInput.current.innerText;
        var filename = this.filename.current.innerText;
        var catalog = this.catalogeditor.current.value;
        var content = this.contenteditor.current.value;
        var pubtime = this.acticleTime.current.value;

        //if (catalog.trim() == "") {
        //    alert("请输入目录"); return;
        //}
        if (content.trim() == "") {
            alert("请输入内容"); return;
        }
        backendMethod.saveNews(id, classify, filename, title, catalog, content, pubtime).then(t => {
            if (t) {
                alert("保存成功!");
            } else
                alert("保存失败!");
        });
    }
    private loadArticleList() {
        var clsfyObj = document.querySelector("#profile select.classify") as HTMLSelectElement;
        var stateObj = document.querySelector("#profile select.state") as HTMLSelectElement;
        backendMethod.GetSimpleNews(clsfyObj.value, -1).then(list => {
            this.hiddenArticleId.current.value = "";
            var timeObj = document.querySelector("#profile select.pubtime") as HTMLSelectElement;
            var showList = () => {
                var tbody = document.querySelector("#profile tbody") as HTMLElement;
                while (tbody.lastChild) tbody.removeChild(tbody.lastChild);
                if (list)
                    for (var l of list) {
                        if (l.classify != clsfyObj.value) continue;
                        if (timeObj.value != "" && l.pubtime.toStr("yyyy") != timeObj.value) continue;
                        if (+stateObj.value > -1 && l.state != +stateObj.value) continue;
                        var row = tbody.appendElement("tr");
                        row.appendTd().appendText(l.classify);
                        row.appendTd().appendText(l.filename);
                        row.appendTd().appendText(l.pubtime.toStr("yyyy-MM-dd"));
                        row.appendTd().appendText((l.state == 1 ? "已发布" : "未发布"));
                        var td = row.appendTd();
                        var editor = td.appendButton("编辑");
                        var delbtn = td.appendButton("删除", { margin: "0 5px" });
                        var modify = td.appendButton(l.state == 1 ? "设置不可见" : "设置可见", { margin: "0 5px" });
                        var viewbtn = td.appendButton("pdf", { margin: "0 5px" });
                        ((a) => {
                            delbtn.onclick = () => {
                                if (window.confirm("确定删除文章《" + a.title + "》可见状态？")) {
                                    backendMethod.DelNews(a.id).then(delcount => {
                                        alert("成功删除文章《" + a.title + "》");
                                        if (delcount) this.loadArticleList();
                                    });
                                }
                            }
                            modify.onclick = () => {
                                if (window.confirm("确定修改文章《" + a.title + "》可见状态？")) {
                                    backendMethod.ChangeState(a.id, a.state == 1 ? 0 : 1).then(delcount => {
                                        alert("成功修改记录条数" + delcount);
                                        if (delcount) this.loadArticleList();
                                    });
                                }
                            }
                            editor.onclick = () => {
                                backendMethod.GetNewsById("all", a.id).then(newsObj => {
                                    const bsTab = new Tab('#home-tab')
                                    bsTab.show();
                                    this.classifySelect.current.value = newsObj.classify;
                                    this.titleInput.current.innerText = newsObj.title;
                                    this.filename.current.innerText = newsObj.filename;
                                    this.hiddenArticleId.current.value = newsObj.id;
                                    this.acticleTime.current.value = newsObj.pubtime.toStr("yyyy-MM-dd HH:mm")
                                    this.catalogeditor.current.value = newsObj.catalog
                                    this.contenteditor.current.value = newsObj.content
                                    this.aTag.current.href = "/News/" + newsObj.classify + "/" + newsObj.filename + "/" + newsObj.filename + ".pdf";

                                    this.tips.current.innerText = "";
                                    if (newsObj.classify == "气候影响评价" || newsObj.classify == "气候公报")
                                        this.tips.current.innerText = "**表格开头为【表 】** **摘要和内容开头为【摘要】**";
                                    else// if (newsObj.classify == "关键农事服务" || l.classify == "农气干旱监测")
                                        this.tips.current.innerText = "**表格开头为【表 】** **有摘要内容，则摘要开头为【摘要】**";

                                });
                            }
                            viewbtn.onclick = () => {
                                var uri = "/News/" + a.classify + "/" + a.filename + "/" + a.filename + ".pdf";
                                window.open(uri, "_blank")
                            }
                        })(l);
                    }
            }
            showList();
            clsfyObj.onchange = this.loadArticleList.bind(this);
            timeObj.onchange = showList.bind(this);
            stateObj.onchange = showList.bind(this);
        });
    }
    private setTimeSelect() {
        var timeObj = document.querySelector("#profile select.pubtime") as HTMLSelectElement;
        for (var yyyy = new Date().getFullYear(); yyyy > 2015; yyyy--) {
            var flag = false;
            for (var i = 0; i < timeObj.options.length; i++) {
                flag = timeObj.options[i].value == yyyy + "";
                if (flag) break;
            }
            if (!flag)
                timeObj.options.add(new Option(yyyy + "年", yyyy + ""));
        }
        timeObj.selectedIndex = 1;
    }

    private loadFile() {
        var clsfyObj = document.querySelector("#new select.classify") as HTMLSelectElement;
        var txtFileName = document.querySelector("#txtFileName") as HTMLInputElement;

        var classify = clsfyObj.value;
        var filename = txtFileName.value;
        if (filename.trim() == "") {
            alert("请输入文件名"); return;
        }
        backendMethod.loadNews(classify, filename).then(t => {
            if (t) {
                alert("下载成功!");
            } else
                alert("下载失败!");
        });
    }

    private saveAdvice() {
        var content = this.adviceeditor.current.value;
        if (content.trim() == "") {
            alert("请输入内容"); return;
        }
        backendMethod.saveAgroAdvice(content).then(t => {
            if (t) {
                alert("保存成功!");
            } else
                alert("保存失败!");
        });
    }
    private getAdvice() {
        backendMethod.getAgroAdvice().then(advice => {
            this.adviceeditor.current.value = advice.content;
        });
    }
}