import { useEffect, createRef } from 'react';
export default function (props: { getMap: () => L.Map, position: { left?: number, right?: number, bottom: number } }) {
    var gotoShenshan = createRef<HTMLDivElement>();
    useEffect(function () {
        gotoShenshan.current.onclick = function () {
            let caption = gotoShenshan.current.querySelector(".figure-caption");
            let icon = gotoShenshan.current.querySelector("i");
            if (caption.innerHTML.indexOf("深汕") != -1) {
                caption.innerHTML = "返回深圳";
                icon.className = "bi bi-reply";
                props.getMap().flyTo([22.87618, 115.04745]);
            }
            else {
                caption.innerHTML = "去深汕";
                icon.className = "bi bi-cursor-fill";
                props.getMap().flyTo([22.6, 114.2]);
            }
        }
    })
    let _style: any = { cursor: 'pointer', position: "absolute", zIndex: 520, textAlign: 'center', height: 90, width: 80, borderRadius: 10, border: "2px solid green" };
    for (var p in props.position)
        _style[p] = (props.position as any)[p];
    return <div className="figure" ref={gotoShenshan} style={_style}>
        <figcaption className="figure-caption" style={{ color: "", padding: "3px 0 0 0", fontSize: 16 }}>去深汕</figcaption>
        <i className="bi bi-cursor-fill" style={{ color: "#0091FF", fontSize: 40 }}></i>
    </div>
}