import { useEffect, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import SZWarnning from "./comonent/szWarnning";
import MetTower from "./comonent/mettower";
import Wstation from "./comonent/wstation";
import DisasterRecognize from "./comonent/disasterRecognize";
import Reproduction from "./comonent/reproduction";
import leafletMap from "./comonent/leafletMap";
import WeekWeather from "./comonent/weekWeather";
import Toshenshan from "./comonent/toShenshan";

export default function Home(props: { date?: Date }) {
    var mapContent = createRef<HTMLDivElement>();
    var mapfeture = createRef<HTMLDivElement>();
    var disasterRecognize = createRef<DisasterRecognize>();
    var warningData: any[] = [];
    useEffect(() => {
        var map = leafletMap(mapContent.current);
        var circleLayerGroup: L.FeatureGroup;
        function changeDate(date: Date) {
            disasterRecognize.current.changeDate(date);
        }
        function drawWarnCircleInMap(warning?: any[]) {
            if (warning) {
                warningData = warning;
                if (!circleLayerGroup) {
                    circleLayerGroup = L.featureGroup().addTo(map);
                    circleLayerGroup.on("mouseover", (evt) => {
                        if (!(map as any).fixedPopwindow) {
                            var message = evt.layer.options.message; 
                            L.popup({ offset: [0, -5] }).setLatLng(evt.latlng).setContent(message).openOn(map);
                        }
                    });
                    map.off("zoomend");
                    map.on("zoomend", () => {
                        drawWarnCircleInMap(warningData);
                    });
                }
                circleLayerGroup.clearLayers();

                for (var obj of warning) {

                    var color = obj.color, message = obj.obtName + ":" + obj.warnMsg + "," + obj.fruitName + obj.warnName;
                    if (color) {
                        let options = {
                            fill: true, color: "#000", fillColor: color,
                            weight: 1, fillOpacity: 1, radius: 7,
                            message: message
                        };
                        L.circleMarker(L.latLng(obj.LAT, obj.LON), options).addTo(circleLayerGroup);
                    }
                }
            } else if (circleLayerGroup) {
                map.closePopup();
                map.removeLayer(circleLayerGroup);
            }
        }

        ReactDOM.createRoot(mapfeture.current).render(<>
            <Reproduction map={map} />
            <WeekWeather />
            <DisasterRecognize ref={disasterRecognize} onCallback={(data) => { drawWarnCircleInMap(data) }} />
            <Toshenshan getMap={() => map} position={{ right: 80, bottom: 10 }} />
            <div style={{ position: "absolute", left: 8, top: 55 }}>
                <MetTower map={map} />
                <Wstation map={map} onChangeDate={(date) => { changeDate(date) }} />
            </div>
        </>);
    });
    return <>
        <SZWarnning stylePosition={{ position: "absolute", left: 260, top: 55 }} />
        <div ref={mapContent} style={{ height: window.innerHeight - 41, zIndex: 0 }}></div>
        <div ref={mapfeture} style={{ zIndex: 1 }}></div>
    </>;
}