import { Component, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import "quill/dist/quill.snow.css"
import "./css/index.css";
import query from './util/myquery';
import backendMethod from './API/backend';
import { Tab } from "bootstrap";
import ArticleList from './comonent/articleList';
import "./css/express.css";
import Enumerable from 'linq';

export default class argoExpress extends Component {
    private articleDiv = createRef<HTMLDivElement>();
    private breadcrumb = createRef<HTMLOListElement>();
    private allarcticleH5 = createRef<HTMLHRElement>();
    public componentDidMount(): void {
        backendMethod.getAllArticleWithoutContent().then(articles => {
            if (articles.length) {
                var homefiles = Enumerable.from(articles).where(t => t.classify == "1").orderByDescending(t => t.creatime).toArray();
                this.showArcticleList(homefiles);
                document.querySelectorAll("#arcticleCalotag a").forEach((a: any, i) => {
                    a.onclick = (e: any) => {
                        if (e.target.id == "arcticle01") this.showArcticleList(homefiles);
                        else if (e.target.id == "arcticle02") this.showArcticleList(Enumerable.from(articles).where(t => t.classify == "2").orderBy(t => t.creatime).toArray());
                        else if (e.target.id == "arcticle03") this.showArcticleList(Enumerable.from(articles).where(t => t.classify == "3").orderBy(t => t.creatime).toArray());
                    }
                });
            }
        });
    }
    private showArcticleList(subarticles: iAgroArticle[]) {
        this.breadcrumb.current.parentElement.style.display = "none";
        this.allarcticleH5.current.style.display = "";
        this.allarcticleH5.current.querySelector("span").innerText = ["深农有品", "农业气象资讯", "农业科普天地"][+subarticles[0].classify - 1];
        var tab = this.articleDiv.current;
        while (tab.lastChild) tab.removeChild(tab.lastChild);
        var table = tab.appendElement("table", { width: "100%", color: "#000" });
        table.className = "table";
        var tbody = table.appendElement("tbody");
        for (var obj of subarticles) {
            var row = tbody.appendElement("tr", { cursor: "pointer" });
            var td = row.appendTd({ "border-color": "#aaa", "border-style": 'dashed' });
            td.appendImage(obj.icon, { width: "100px", margin: "5px 10px 5px 5px" }).className = "rounded float-start";
            var titleDiv = td.appendElement("div", { "font-size": "20px", "font-weight": "bolder" });
            titleDiv.appendText(obj.title);
            var div = td.appendElement("div", { margin: "5px 0 0 0", color: "#000" });
            div.appendText(obj.summary + "...");
            var div = td.appendElement("div", { margin: "5px 0 0 0", color: "gray" });
            div.appendText(obj.creatime.toStr("yyyy年MM月dd日"));
            ((a) => {
                row.onclick = () => {
                    this.showArticle(a.id);
                }
            })(obj);
        }
    }
    private showArticle(id: string) {
        backendMethod.getArticle(id).then(b => {
            while (this.breadcrumb.current.lastChild)
                this.breadcrumb.current.removeChild(this.breadcrumb.current.lastChild);
            this.breadcrumb.current.parentElement.style.display = "";
            this.allarcticleH5.current.style.display = "none";
            let li = this.breadcrumb.current.appendElement("li"), a: HTMLAnchorElement;
            li.className = "breadcrumb-item"; a = li.appendElement("a") as HTMLAnchorElement; a.appendText("气象助农直通车");
            li = this.breadcrumb.current.appendElement("li"); li.className = "breadcrumb-item";
            a = li.appendElement("a") as HTMLAnchorElement;
            a.appendText(backendMethod.classify2name(b.classify));
            li = this.breadcrumb.current.appendElement("li"); li.className = "breadcrumb-item";
            var span = li.appendElement("span", { color: "#000" });
            span.className = "d-inline-block text-truncate";
            span.style.maxWidth = "300px"
            span.appendText(b.title);
            this.articleDiv.current.innerHTML = b.html;
        });
    }
    public render() {
        return <>
            <table style={{ width: "100%", height: window.innerHeight }}>
                <tr>
                    <td style={{ verticalAlign: "top", width: 250 }}>
                        <h5 style={{ borderBottom: "1px solid #e8f3ec", marginLeft: 20, marginTop: 20, padding: 10 }} className='font-weight-bold'>
                            <span style={{ borderBottom: "1px solid #000", padding: 10 }}>分类</span></h5>
                        <ol className='list-featured' id="arcticleCalotag" style={{ marginTop: 30 }}>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="arcticle01" className="text-dark">深农有品</a>
                                    </h4>
                                    <p className="text-muted">
                                        深圳农业的明星产品
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="arcticle02" className="text-dark">农业气象资讯</a>
                                    </h4>
                                    <p className="text-muted">
                                        介绍气象在农业方面的资讯
                                    </p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <h4 className="font-weight-bold">
                                        <a href="#" id="arcticle03" className="text-dark">农业科普天地</a>
                                    </h4>
                                    <p className="text-muted">
                                        科普农业生产知识
                                    </p>
                                </span>
                            </li>
                        </ol>
                    </td>
                    <td style={{ verticalAlign: "top" }}>
                        <h5 ref={this.allarcticleH5} style={{ borderBottom: "1px solid #e8f3ec", marginLeft: 0, marginTop: 20, padding: 10 }} className='font-weight-bold'>
                            <span style={{ borderBottom: "1px solid #000", padding: 10 }}>所有文章</span></h5>
                        <nav aria-label="breadcrumb" style={{ fontWeight: 'bold' }}>
                            <ol className="breadcrumb" ref={this.breadcrumb} style={{ marginLeft: 10 }}>
                            </ol>
                        </nav>
                        <div ref={this.articleDiv} className='ql-editor' style={{ padding: 10, overflow: "visible" }}></div>
                    </td></tr>
            </table>
        </>;
    }
}