import { Component, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import Quill from "quill";
import "quill/dist/quill.snow.css"
import query from './util/myquery';
import backendMethod from './API/backend';
import { Tab } from "bootstrap";

export default class argoExpress extends Component {
    private myeditor = createRef<HTMLDivElement>();
    private hiddenArticleId = createRef<HTMLInputElement>();
    private classifySelect = createRef<HTMLSelectElement>();
    private iconImage = createRef<HTMLImageElement>();
    private iconInput = createRef<HTMLInputElement>();
    private titleInput = createRef<HTMLInputElement>();
    private acticleTime = createRef<HTMLInputElement>();
    private myQuill: Quill;
    public componentDidMount(): void {
        var toolbarOptions: any = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', "video"],

            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];

        if (!this.myeditor.current.hasChildNodes()) {
            this.myQuill = new Quill(this.myeditor.current, {
                modules: {
                    toolbar: toolbarOptions
                },
                theme: 'snow'
            });
        }
        query("#profile-tab").on("shown.bs.tab", this.loadArticleList.bind(this));
        this.loadArticleList();
        this.iconInput.current.onchange = (evt) => {
            var target = evt.target as HTMLInputElement;
            var file = target.files[0];
            if (!/image\/\w+/.test(file.type))
                alert("文件必须为图片！");
            else {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    let iconStr = reader.result as string;
                    if (iconStr.length > 500000)
                        alert("错误！图标文件大于400k");
                    else
                        this.iconImage.current.src = iconStr;
                }
            }
        }
    }

    public render() {
        return <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="home-tab" onClick={e => this.hiddenArticleId.current.value = ""} data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">文章编辑</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">文章列表</button>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" href='/page/express' type="button" aria-selected="false">查看前端</a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div style={{ margin: 3 }} className="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab" >
                    <div style={{ margin: "10px 0" }}>
                        &nbsp;<b>文章编辑</b>
                        <input type='hidden' ref={this.hiddenArticleId}></input>
                        &nbsp; &nbsp;分类：<select ref={this.classifySelect}>
                            <option value={0}>请选择……</option>
                            <option value={1}>深农有品</option>
                            <option value={2}>农气资讯</option>
                            <option value={3}>农普天地</option>
                        </select>
                        &nbsp; &nbsp;  标题：<input ref={this.titleInput} type='text' style={{ width: 300 }}></input>
                        &nbsp; &nbsp;  图标: <img ref={this.iconImage} style={{ height: 30, margin: "0 5px" }}></img><input ref={this.iconInput} type='file'></input>
                        时间: <input ref={this.acticleTime} type="datetime-local"></input>

                        &nbsp; &nbsp; <button type="button" style={{ margin: "5px 0" }} className="btn btn-primary" onClick={this.addArticle.bind(this)}>保存</button>
                    </div>
                    <div ref={this.myeditor} style={{ height: window.innerHeight - 300 }}></div>
                </div>
                <div className="tab-pane fade  show active" id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col"><select>
                                    <option value={1}>深农有品</option>
                                    <option value={2}>农气资讯</option>
                                    <option value={3}>农普天地</option>
                                </select></th>
                                <th scope="col">图标</th>
                                <th scope="col">标题</th>
                                <th scope="col">创建时间</th>
                                <th scope="col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }
    private addArticle() {
        var id = this.hiddenArticleId.current.value;
        var classify = this.classifySelect.current.value;
        var title = this.titleInput.current.value;
        var delta = this.myQuill.getContents();
        var html = this.myQuill.root.innerHTML;
        var ddatetime = this.acticleTime.current.value;

        if (!classify) {
            alert("请选择分类"); return;
        }
        if (!title || delta.ops.length == 1 && delta.ops[0].insert == "\n") {
            alert("请输入标题或内容"); return;
        }
        var contents = JSON.stringify(delta);
        backendMethod.saveArticle(id, classify, title, this.iconImage.current.src, contents, html, ddatetime).then(t => {
            if (t) {
                alert("保存成功!");
                this.classifySelect.current.value = "0";
                this.titleInput.current.value = "";
                this.iconInput.current.value = "";
                this.iconImage.current.src = "";
                this.acticleTime.current.value = "";
                this.myQuill.setContents(null);
            } else
                alert("保存失败!");
        });
    }
    private loadArticleList() {
        backendMethod.getAllArticleWithoutContent().then(list => {
            this.hiddenArticleId.current.value = "";
            var selectClass = document.querySelector("#profile select") as HTMLSelectElement;
            var showList = () => {
                var tbody = document.querySelector("#profile tbody") as HTMLElement;
                while (tbody.lastChild) tbody.removeChild(tbody.lastChild);
                if (list) for (var l of list) {
                    if (l.classify != selectClass.value)
                        continue;
                    var row = tbody.appendElement("tr");
                    row.appendTd().appendText(backendMethod.classify2name(l.classify));
                    row.appendTd().appendImage(l.icon, { width: "50px" });
                    row.appendTd().appendText(l.title);
                    row.appendTd().appendText(l.creatime.toStr("yyyy-MM-dd HH:mm"));
                    var td = row.appendTd();
                    var editor = td.appendButton("编辑");
                    var delbtn = td.appendButton("删除", { margin: "0 3px" });
                    ((a) => {
                        delbtn.onclick = () => {
                            if (window.confirm("确定删除文章《" + a.title + "》？")) {
                                backendMethod.delArticle(a.id).then(delcount => {
                                    alert("成功删除记录条数" + delcount);
                                    if (delcount) this.loadArticleList();
                                });
                            }
                        }
                        editor.onclick = () => {
                            backendMethod.getArticle(a.id).then(article => {
                                const bsTab = new Tab('#home-tab')
                                bsTab.show();
                                this.classifySelect.current.value = article.classify;
                                this.titleInput.current.value = article.title;
                                this.myQuill.setContents(article.content);
                                this.hiddenArticleId.current.value = article.id;
                                this.iconImage.current.src = article.icon;
                                this.acticleTime.current.value = article.creatime.toStr("yyyy-MM-dd HH:mm")
                            });
                        }
                    })(l);
                }
            }
            showList();
            selectClass.onchange = showList.bind(this);
        });
    }
}