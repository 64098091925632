import { useState } from "react";
//import { DatePicker } from "react-datepicker";
import { DatePicker } from "antd";
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css'; // ���� antd.less
//import 'react-datepicker/dist/react-datepicker.css';
import local from 'antd/es/date-picker/locale/zh_CN';


export default function DdatePicker(props: { 
    onChange: (date: Date) => void,
    startDate: Date,
    className?: string,
    hourStep?: number,
    minStep?: number,
    setDateValue?: { set?: (d: Date) => void }
}) {    
    const [startDate, setStartDate] = useState(props.startDate);
    let format_ = (props.hourStep > 0 || props.minStep > 0) ? "yyyy-MM-DD HH:mm" :"yyyy-MM-DD HH:mm"
    function _setStartDate(date: Date) {
        setStartDate(date);
        props.onChange(date);
    }
    if (props.setDateValue)
        props.setDateValue.set = function (date: Date) {
            setStartDate(date);
        }
    
    return (
        //<DatePicker selected={startDate} onChange={_setStartDate} className={props.className}
        //    showTimeSelect={props.showTimeSelect||true}
        //    minSteps={props.minStep||1}
        //    timeInputLabel="Time:"
        //    locale={zhCN}
        //    timeFormat="p"
        //    dateFormat="yyyy/MM/dd HH:mm" /> 

        <DatePicker placeholder="��ѡ��" className={props.className}
            showTime={(props.hourStep > 0 || props.minStep > 0)}
            showHour={props.hourStep > 0}
            showMinute={props.minStep > 0}
            showSecond={false}
            hourStep={props.hourStep||1}
            minuteStep={props.minStep||5}
            locale={local} 
            defaultValue={moment(startDate)}
            format="yyyy-MM-DD HH:mm"
            onChange={(date, dateString) => _setStartDate(date.toDate())} />
    );
};