import { Component, createRef } from 'react';
import backendMethod from '../API/backend';
import Enumerable from 'linq';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.min.css"
import { getStreetDisaster2 } from "../API/disarter";

export default class DisasterRecognize extends Component<{ mobile?: boolean, onCallback?: (data: any[]) => void }>{
    private warningDiv = createRef<HTMLDivElement>();
    private warningDiv2 = createRef<HTMLDivElement>();
    private warningCount = createRef<HTMLSpanElement>();
    //private onCallback: (data: any[]) => void;


    public constructor(props: { mobile?: boolean, onCallback?: (data: any[]) => void }) {
        super(props);
    }
    public componentDidMount(): void {
        //个例：
        //2024-11-13 10:35
        let endt: Date = new Date();//"2024-11-12 18:35"
        let start: Date = new Date(endt);
        start.setHours(start.getHours() - 240, 0, 0, 0);
        this.showWarning(start, endt);
    }
    public changeDate(date: Date) {
        let start: Date = new Date(date);
        start.setHours(start.getHours() - 240, 0, 0, 0);
        this.showWarning(start, date);
    }
    public showWarning(start: Date, endt:Date) {

        backendMethod.getSZObtHourds(start, endt).then(items => {
            backendMethod.getObtDayDataByD30s(endt).then(items1 => {
                var warnings = getStreetDisaster2(endt, items, items1);
                var html = ""
                if (warnings.length === 0) {
                    html = "<span style='color: #000; margin: 10px'>当前没有气象灾害</span>"
                    this.swiftForm()
                }
                else {
                    html = "<table class='table table-sm table-bordered' style='margin: 5px 0px;'>"
                    html += "<tr><th style='width: 60px'>站点</th><th style='width: 80px'>灾种</th><th>影响作物</th><th>天气状况</th></tr>"
                    for (let item of warnings) {
                        var obt = Enumerable.from(items).firstOrDefault(p => p.OBTID == item.obtid)
                        item.LAT = obt.LAT
                        item.LON = obt.LON
                        html += "<tr><td>" + item.obtName + "</td><td>" + item.warnName + "</td><td>" + item.fruitName + "</td><td>" + item.warnMsg + "</td></td>"
                    }
                    html += "</table>"
                }
                this.warningCount.current.innerHTML = " (" + warnings.length + ")";
                this.warningCount.current.style.color = warnings.length > 0 ? "#f00" : "#fff";
                this.warningDiv2.current.innerHTML = html;


                if (this.props.onCallback)
                    this.props.onCallback(warnings);
                
            });

        });


    }
    public render = () => {
        if (this.props.mobile)
            return <>                
            </>
        else return <>
            <div ref={this.warningDiv} className="card mb-3" style={{
                position: "absolute", backgroundColor: "#ffffffb6", width: 400, zIndex: 520, top: 55, right: 400, color: "#fff", border: "2px solid #0E77D3"
            }} >
                <div className="card-header" style={{ padding: "3px", backgroundColor: "#0E77D3" }}>
                    灾害识别<span ref={this.warningCount}></span>
                    <span style={{ float: "right", fontSize: 18, display: '' }} className='bi bi-x' onClick={this.close.bind(this)}></span>
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-dash' onClick={this.swiftForm.bind(this)}></span>
                </div>
                <div ref={this.warningDiv2} className="card-body" style={{ maxHeight: window.innerHeight - 200, background: "", padding: 0, overflowY: 'auto' }}>
                    
                </div>
            </div>            
        </>
    }
    private close() {
        this.warningDiv.current.style.display = "none";
    }
    private swiftForm() {
        let cardbody = this.warningDiv.current.querySelector(".card-body") as HTMLDivElement;
        let dash = this.warningDiv.current.querySelector(".card-header .bi-dash") as HTMLSpanElement;
        let xbtn = this.warningDiv.current.querySelector(".bi.bi-x") as HTMLSpanElement;
        if (dash) {
            cardbody.style.display = "none";
            dash.className = "bi bi-app";
            this.warningDiv.current.style.width = "120px";
            xbtn.style.display = "none";
        } else {
            dash = this.warningDiv.current.querySelector(".card-header .bi-app") as HTMLSpanElement;
            cardbody.style.display = "";
            dash.className = "bi bi-dash";
            this.warningDiv.current.style.width = "400px";
            xbtn.style.display = "";
        }
    }
    
}