class myquery {
    private target: HTMLElement;
    public constructor(selector: string | HTMLElement) {
        if (typeof selector == "string")
            this.target = document.querySelector(selector);
        else this.target = selector;
    }
    public text(text: string) {
        let tNode = document.createTextNode(text);
        while (this.target.lastChild) this.target.removeChild(this.target.lastChild);
        this.target.appendChild(tNode);
        return this;
    }
    public html(html: string) {
        return this.target.innerHTML = html;
    }
    public hasChildNodes() {
        return this.target.hasChildNodes();
    }
    public parent() {
        return this.target.parentElement;
    }
    public hide() {
        this.target.style.display = "none";
        return this.target;
    }
    public show(display = "") {
        this.target.style.display = display;
        return this.target;
    }
    public on(eventType: string, callback: (e: Event) => void) {
        this.target.addEventListener(eventType, callback);
    }
    public off(eventType: string, callback: (e: Event) => void) {
        this.target.removeEventListener(eventType, callback);
    }
}
export default function query(selector: string): myquery {
    return new myquery(selector);
}