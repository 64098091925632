import { useEffect, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import SZWarnning from "../comonent/szWarnning";
import MetTower from "../comonent/mettower";
import Wstation from "../comonent/wstation";
import Reproduction from "../comonent/reproduction";
import leafletMap from "../comonent/leafletMap";
import WeekWeather from "../comonent/weekWeather";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.min.css"
import "../css/express.css";
import "../css/mobile.css";
import { Modal } from "bootstrap";
import Advice from "../comonent/Advice";
import StreetGrid from '../comonent/streetGrid';
import ArticleList from '../comonent/articleList';
import NewsList from '../comonent/newsList-mobile';
import backendMethod from '../API/backend';
import StreetForecast from '../comonent/streetForecasat';

export default function Home() {
    var mapContent = createRef<HTMLDivElement>();
    var mapfeture = createRef<HTMLDivElement>();
    var articleDiv = createRef<HTMLDivElement>();
    var breadcrumb = createRef<HTMLOListElement>();
    var articleContainer = createRef<HTMLDivElement>();
    var AgroWeatherDiv = createRef<HTMLDivElement>();
    var AgroAdviceDiv = createRef<HTMLDivElement>();
    var map: L.Map;
    var packRepThis: { instance?: Reproduction } = {}
    useEffect(() => {
        //map = leafletMap(mapContent.current, true, 10, "bottomleft", "topleft");
        //ReactDOM.createRoot(mapfeture.current).render(<>
        //    <Reproduction map={map} mobile={true} packInstance={packRepThis} />
        //</>);
        showNews()
    });

    function showNews() {
        ReactDOM.createRoot(mapfeture.current).render(<NewsList />);
    }
  
    

    return <div className="mobile">
        <div style={{ height: 35, backgroundColor: "#0a58ca", textAlign: 'center', color: "#fff", lineHeight: "35px", fontSize: 18 }}>
            <i style={{ fontSize: 25 }} className="bi bi-brightness-high"></i><span style={{ verticalAlign: "center", fontSize: 20, fontStyle: "italic" }}>农业气象服务</span>
        </div>

        <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="tab1" data-bs-toggle="tab"
                    data-bs-target="#pane1" type="button" role="tab" aria-controls="tab-pane-1" aria-selected="true"
                    onClick={showNews}>为农服务</button>
            </li>
            <li className="nav-item None" role="presentation">
                <button className="nav-link" id="tab2" data-bs-toggle="tab"
                    data-bs-target="#pane2" type="button" role="tab" aria-controls="tab-pane-2" aria-selected="true">为农服务2</button>
            </li>
            <li className="nav-item None" role="presentation">
                <button className="nav-link" id="tab3" data-bs-toggle="tab"
                    data-bs-target="#pane3" type="button" role="tab" aria-controls="tab-pane-3" aria-selected="true">为农服务3</button>
            </li>
        </ul>
        <div className="tab-content None">
            <div className="tab-pane fade show active" id="pane1" role="tabpanel" aria-labelledby="tab-pane-1">

            </div>
            <div className="tab-pane fade" id="pane2" role="tabpanel" aria-labelledby="tab-pane-2">为农服务2...</div>
            <div className="tab-pane fade" id="pane3" role="tabpanel" aria-labelledby="tab-pane-3">为农服务3...</div>
        </div>

        <div ref={mapfeture} style={{ zIndex: 1 }}></div>
        

        <div ref={articleContainer} style={{ position: 'absolute', zIndex: 520, background: "#fff", top: 35, display: "none" }}>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb" ref={breadcrumb} style={{ marginLeft: 10 }}>
                </ol>
            </nav>
            <input type='button' onClick={e => articleContainer.current.style.display = "none"} value="关闭" className='btn btn-primary'
                style={{ width: window.innerWidth - 10, zIndex: 521, margin: 5, position: "fixed", bottom: 0 }}></input>
            <div ref={articleDiv} id="mobile_ql_editor" className='ql-editor' style={{ padding: "0 3px 50px 3px", overflow: "visible" }}></div>
            
        </div>
        
        
    </div>;
}