import { useEffect } from "react"
import Enumerable from 'linq';
import backendMethod from '../API/backend';
import "../css/express.css";

export default function (props: {  }) {
    useEffect(() => {
    });

    var attachTable = (e: any) => {
        var tag = e.target.tagName;
        var classify = tag == "I" ? e.target.parentElement.innerText : e.target.innerText;
        classify = classify.trim();

        backendMethod.GetSimpleNews(classify).then(news => {

            var tab = document.getElementById("NewsTitle")
            while (tab.lastChild) tab.removeChild(tab.lastChild);

            var accordion = tab.appendElement("div");
            accordion.id = "list-group";

            if (classify == "气候公报") {
                var collapse = accordion.appendElement("ul");
                collapse.id = "collapse" + new Date().getFullYear();
                collapse.className = "panel-collapse collapse show";
                for (var obj of news) {
                    var li = collapse.appendElement("li");

                    var titleDiv = li.appendElement("div");
                    titleDiv.className = "title"
                    titleDiv.appendText(obj.title);

                    var dateDiv = li.appendElement("div");
                    dateDiv.className = "date1"
                    dateDiv.appendText(obj.pubtime.toStr("yyyy年M月d日"));

                    ((a) => {
                        li.onclick = () => {
                            onShowNews(a.classify, a.id);
                        }
                    })(obj);

                }
            }
            else {

                var years = Enumerable.from(news).select(t => t.pubtime.getFullYear()).distinct().orderByDescending(y => y).toArray();
                years.forEach(function (year: number, idx: number) {

                    let subnews_ = Enumerable.from(news)
                        .where(t => t.pubtime.getFullYear() == year)
                        .orderByDescending(t => t.pubtime).toArray();
                    var panel = accordion.appendElement("div");
                    panel.id = "card" + year;
                    panel.className = "list-group-item list-group-item-action card-header";
                    panel.setAttribute("data-bs-toggle", "collapse");
                    panel.setAttribute("data-bs-target", "#collapse" + year);
                    panel.innerHTML = `<h4><a href="#collapse${year}">${year}年</a></h4>`

                    var collapse = accordion.appendElement("ul");
                    collapse.id = "collapse" + year;
                    collapse.className = "panel-collapse collapse";
                    if (idx == 0) {
                        collapse.className += " show"
                        collapse.setAttribute("active", "true")
                    }

                    for (var obj of subnews_) {
                        var li = collapse.appendElement("li");

                        var titleDiv = li.appendElement("div");
                        titleDiv.className = "title"
                        if (obj.classify == "气候影响评价" || obj.classify == "气候公报")
                            titleDiv.appendText(obj.title);
                        else
                            titleDiv.appendText(obj.filename);
                        var dateDiv = li.appendElement("div");
                        dateDiv.className = "date1"
                        dateDiv.appendText(obj.pubtime.toStr("yyyy年M月d日"));

                        ((a) => {
                            li.onclick = () => {
                                onShowNews(a.classify, a.id);
                            }
                        })(obj);

                    }

                    ((b: any) => {
                        panel.onclick = () => {
                            showAccordion(year)
                        }
                    })(year);
                });
            }

            toggleCategoryDiv(null);
        });

    }

    var toggleCategoryDiv = (e: any) => {
        if (e!=null) {
            document.getElementById("NewsCatetory").style.display = ""
            e.target.parentElement.style.display = "none"
        } else {
            document.getElementById("NewsCatetory").style.display = "none"
            document.getElementById("NewsTitle0").style.display = ""
        }
    }

    var showAccordion = (year: number) => {
        //query("#accordion .panel-collapse.collapse").hide()
        var collapse = document.querySelector("#collapse" + year);
        var flag = collapse.hasAttribute("active")
        document.querySelectorAll(".panel-collapse.collapse").forEach(div => {
            div.className = "panel-collapse collapse"
            div.removeAttribute("active")
        });
        if (!flag) {
            collapse.setAttribute("active", "true")
        }

    }

    var toggleContentDiv = (e: any) => {
        if (e != null) {
            document.getElementById("NewsTitle0").style.display = ""
            e.target.parentElement.style.display = "none"
        } else {
            document.getElementById("NewsTitle0").style.display = "none"
            document.getElementById("NewsContent0").style.display = ""
        }
    }

    var onShowNews = (classify: string, id: string) => {
        backendMethod.GetNewsById(classify, id).then(b => {
            toggleContentDiv(null);
            var NewsContent = document.getElementById("NewsContent");
            if (b.classify == "气候影响评价" || b.classify == "气候公报")
                NewsContent.innerHTML = getClimateImpactAssessmentContent(b.classify, b.filename, b.title, b.content, b.pubtime);
            else //if (b.classify == "农用天气预报" || b.classify == "农气情报预报")
                NewsContent.innerHTML = getAgroWeatherContent(b.filename, b.title, b.content, b.pubtime);
            //else if (b.classify == "关键农事服务" || b.classify == "农气干旱监测")
            //    articleDiv.current.innerHTML = getKeyAgriculturalContent(b.filename, b.title, b.content, b.pubtime);
            document.querySelectorAll(".title11div").forEach(div => {
                var title11 = div.children[1]
                var w0 = 78
                var w = title11.clientWidth + w0 * 2 + 1;
                var h = title11.clientHeight;
                var w0 = div.parentElement.clientWidth;
                if (w0 >= w)
                    div.setAttribute('style', 'margin: 0 auto; height: ' + h + 'px; width: ' + w + 'px')
                else {
                    h = 63
                    w0 = 67
                    div.children[0].setAttribute('style', 'height: ' + h + 'px; width: ' + w0 + 'px')
                    div.children[2].setAttribute('style', 'height: ' + h + 'px; width: ' + w0 + 'px')
                    title11.setAttribute('style', 'height: ' + h + 'px; font-size: 17px; padding: 10px 0px 19px 0px')
                    var w = title11.clientWidth + w0 * 2 + 1;

                    div.setAttribute('style', 'margin: 0 auto; height: ' + h + 'px; width: ' + w + 'px')

                }
            });
            document.querySelectorAll(".title21div").forEach(div => {
                var title11 = div.children[1]
                var w = title11.clientWidth+40+30+1;
                var h = title11.clientHeight;

                div.setAttribute('style', 'height: ' + h + 'px; width: ' + w + 'px')

            });

            var btnClose1 = document.getElementById('btnClose1');
            btnClose1.style.width = document.querySelector("#NewsContent").clientWidth + 'px';

        })
    }

    var getClimateImpactAssessmentContent = (classify: string, filename: string, title: string, content: string, date: Date) => {

        var html = "";
        var json = JSON.parse(content)
        var numbers = filename.match(/\d+/g);

        //console.log(json)
        html += "<div id='mobile' class='ClimateImpactAssessment'>"
        html += "<div style='height:10px'>&nbsp;</div><div class='title'><div class='titleborder0'><div class='titleborder'>"
        if (classify == "气候影响评价") {
            if (filename.indexOf('月') > -1) {
                html += "<div class='title0'>广东省气候影响评价</div>"
                html += "<div class='no'>" + numbers[0] + " 年 第" + numbers[1] + "期</div>"
            } else {
                html += "<div class='title0'>广东省气候影响评价</div>"
                html += "<div class='no'>" + numbers[0] + " 年</div>"
            }
        }
        else if (classify == "气候公报") {
            html += "<div class='title0'>广东省气候公报</div>"
            html += "<div class='no'>" + numbers[0] + " 年</div>"
        }
        html += "</div></div></div>"
        html += "<div class='unit_date'><div class='unit'>广东省气候中心</div><div class='date'>" + date.toStr("yyyy年M月d日") + "</div></div>"
        if (json.Summary.length > 0) {
            html += "<div id='summary'><div id='summary1'>"
            html += "<div class='title1'><div class='title11div'><img src='/images/title_left.png'/><div class='title11'>天气概况和气候趋势预报</div><img src='/images/title_right.png'/></div></div>"

            html += "<div class='content'><div class='start'><img src='/images/comma_left.png'/></div>"
            json.Summary.forEach(function (line: any, idx: number) {
                if (line.Type == "title")
                    html += "<div class='title" + (idx + 1) + "'>" + line.Text + "</div>"
                else
                    html += "<div class='text'>" + line.Text + "</div>"
            });
            html += "<div>&nbsp;</div><div class='end'><img src='/images/comma_right.png'/></div></div></div></div>"
        }

        json.Paragraphs.forEach(function (para1: any) {
            html += "<div class='paragraph1'>"
            if (para1.Title) {
                html += "<div class='title1'><div class='title11div'><img src='/images/title_left.png'/><div class='title11'>" + para1.Title + "</div><img src='/images/title_right.png'/></div></div>"
            }

            para1.Paragraphs.forEach(function (para2: any) {
                html += "<div class='paragraph2'><div class='content'>"
                if (para2.Title)
                    html += "<div class='title2'><div class='title21div'><img src='/images/title2_left.png'/><div class='title21'>" + para2.Title + "</div><img src='/images/title2_right.png'/></div></div>"

                para2.ParagraphLines.forEach(function (line: any) {
                    if (line.Type == "string")
                        html += "<div class='text'>" + line.Text + "</div>"
                    else if (line.Type == "table") {
                        html += "<div class='ql-align-center clear'>"
                        html += "<table class='mytable'>"

                        let arr = line.Text.split('|');
                        html += "<legend>" + arr[0] + "</legend>"
                        for (let r = 1; r < arr.length; r++) {

                            let td = r == 1 ? "th" : "td";
                            html += "<tr>"
                            let tds = arr[r].split(',');
                            tds.forEach(function (td_: any, ii: number) {
                                td = ii == 0 ? "th" : "td";
                                let td__ = td_.split('_');
                                if (td__.length > 1) {
                                    html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                        "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                }
                                else
                                    html += "<" + td + ">" + td_ + "</" + td + ">"
                            })

                            html += "</tr>"
                        }

                        html += "</table><div>&nbsp;</div></div>"
                    }
                    else {
                        html += "<div class='ql-align-center clear'>"

                        let arr = line.Text.split('|');
                        let img = arr[0].split(',');
                        let tip = arr[1].split(',');
                        html += ""
                        if (img.length == tip.length) {
                            img.forEach(function (img_: any, idx: number) {
                                html += "<div class='img'><img src='" + img_ + "' />"
                                html += "<div class='img-tip'>" + tip[idx] + "</div></div>"
                            })
                            html += "</div>"
                        }
                        else {
                            img.forEach(function (img_: any) {
                                html += "<div class='img'><img src='" + img_ + "' /></div>"
                            })
                            html += "<div class='clear'>"
                            tip.forEach(function (tip_: any) {
                                html += "<div class='img-tip'>" + tip_ + "</div>"
                            })
                            html += "</div></div>"
                        }

                    }
                });
                para2.Paragraphs.forEach(function (para3: any) {
                    html += "<div class='title3'>" + para3.Title + "</div>"
                    para3.ParagraphLines.forEach(function (line: any) {
                        if (line.Type == "string")
                            html += "<div class='text'>" + line.Text + "</div>"
                        else if (line.Type == "table") {
                            html += "<div class='ql-align-center clear'>"
                            html += "<table class='mytable'>"

                            let arr = line.Text.split('|');
                            html += "<legend>" + arr[0] + "</legend>"
                            for (let r = 1; r < arr.length; r++) {

                                let td = r == 1 ? "th" : "td";
                                html += "<tr>"
                                let tds = arr[r].split(',');
                                tds.forEach(function (td_: any, ii: number) {
                                    td = ii == 0 ? "th" : "td";
                                    let td__ = td_.split('_');
                                    if (td__.length > 1) {
                                        html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                            "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                    }
                                    else
                                        html += "<" + td + ">" + td_ + "</" + td + ">"
                                })


                                html += "</tr>"
                            }

                            html += "</table><div>&nbsp;</div></div>"
                        }
                        else {
                            html += "<div class='ql-align-center clear'>"

                            let arr = line.Text.split('|');
                            let img = arr[0].split(',');
                            let tip = arr[1].split(',');
                            html += ""
                            if (img.length == tip.length) {
                                img.forEach(function (img_: any, idx: number) {
                                    html += "<div class='img'><img src='" + img_ + "' />"
                                    html += "<div class='img-tip'>" + tip[idx] + "</div></div>"
                                })
                                html += "</div>"
                            }
                            else {
                                img.forEach(function (img_: any) {
                                    html += "<div class='img'><img src='" + img_ + "' /></div>"
                                })
                                html += "<div class='clear'>"
                                tip.forEach(function (tip_: any) {
                                    html += "<div class='img-tip'>" + tip_ + "</div>"
                                })
                                html += "</div></div>"
                            }
                        }
                    });
                });

                html += "</div><div>&nbsp;</div></div>"
            });
            html += "</div>"
        });

        html += "</div>"

        return html;
    }

    var getAgroWeatherContent = (filename: string, title: string, content: string, date: Date) => {

        var html = "";
        var json = JSON.parse(content)
        var numbers = filename.match(/\d+/g);

        //console.log(json)
        html += "<div id='mobile' class='AgroWeather'>"
        html += "<div style='height:10px'>&nbsp;</div><div class='title'><div class='titleborder0'><div class='titleborder'>"

        html += "<div class='title0'>" + title + "</div>"
        html += "<div class='no'>" + numbers[0] + " 年 第" + numbers[1] + "期</div>"

        html += "</div></div></div>"
        html += "<div class='unit_date'><div class='unit'>广东省气候中心</div><div class='date'>" + date.toStr("yyyy年M月d日") + "</div></div>"
        if (json.Summary.length > 0) {
            html += "<div id='summary'><div id='summary1'>"
            var SummaryTitle = ""
            json.Summary.forEach(function (line: any, idx: number) {
                if (line.Type == "title")
                    SummaryTitle += "<div class='title" + (idx + 1) + "'>" + line.Text + "</div>"
            });


            if (SummaryTitle != "") {
                html += "<div class='summary-titles'>" + SummaryTitle + "</div>"
            }
            var SummaryContent = ""
            var flag = false
            SummaryContent += "<div class='title1'><div class='title11div'><img src='/images/title_left.png'/><div class='title11'>内容提要</div><img src='/images/title_right.png'/></div></div>"

            SummaryContent += "<div class='content'><div class='start'><img src='/images/comma_left.png'/></div>"
            json.Summary.forEach(function (line: any, idx: number) {
                if (line.Type == "string") {
                    flag = true;
                    SummaryContent += "<div class='text'>" + line.Text + "</div>"
                }
            });
            SummaryContent += "<div>&nbsp;</div><div class='end'><img src='/images/comma_right.png'/></div></div></div></div>"
            if (flag) html += SummaryContent
        }

        json.Paragraphs.forEach(function (para1: any) {
            html += "<div class='paragraph1'>"
            if (para1.Title) {
                html += "<div class='title1'><div class='title11div'><img src='/images/title_left.png'/><div class='title11'>" + para1.Title + "</div><img src='/images/title_right.png'/></div></div>"
            }

            para1.Paragraphs.forEach(function (para2: any) {
                html += "<div class='paragraph2'><div class='content'>"
                if (para2.Title)
                    html += "<div class='title1'><div class='title11div'><img src='/images/title_left.png'/><div class='title11'>" + para2.Title + "</div><img src='/images/title_right.png'/></div></div>"
                //html += "<div class='title1'><img src='/images/title2_left.png'/><div class='title21'>" + para2.Title + "</div><img src='/images/title2_right.png'/></div>"

                para2.ParagraphLines.forEach(function (line: any) {
                    if (line.Type == "string")
                        html += "<div class='text'>" + line.Text + "</div>"
                    else if (line.Type == "table") {
                        html += "<div class='ql-align-center clear'>"
                        html += "<table class='mytable'>"

                        let arr = line.Text.split('|');
                        html += "<legend>" + arr[0] + "</legend>"
                        for (let r = 1; r < arr.length; r++) {

                            let td = r == 1 ? "th" : "td";
                            html += "<tr>"
                            let tds = arr[r].split(',');
                            tds.forEach(function (td_: any, ii: number) {
                                td = ii == 0 ? "th" : "td";
                                let td__ = td_.split('_');
                                if (td__.length > 1) {
                                    html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                        "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                }
                                else
                                    html += "<" + td + ">" + td_ + "</" + td + ">"
                            })

                            html += "</tr>"
                        }

                        html += "</table><div>&nbsp;</div></div>"
                    }
                    else {
                        html += "<div class='ql-align-center clear'>"

                        let arr = line.Text.split('|');
                        let img = arr[0].split(',');
                        let tip = arr[1].split(',');
                        html += ""
                        if (img.length == tip.length) {
                            img.forEach(function (img_: any, idx: number) {
                                html += "<div class='img'><img src='" + img_ + "' />"
                                html += "<div class='img-tip'>" + tip[idx] + "</div></div>"
                            })
                            html += "</div>"
                        }
                        else {
                            img.forEach(function (img_: any) {
                                html += "<div class='img'><img src='" + img_ + "' /></div>"
                            })
                            html += "<div class='clear'>"
                            tip.forEach(function (tip_: any) {
                                html += "<div class='img-tip'>" + tip_ + "</div>"
                            })
                            html += "</div></div>"
                        }

                    }
                });
                para2.Paragraphs.forEach(function (para3: any) {
                    html += "<div class='title3'>" + para3.Title + "</div>"
                    para3.ParagraphLines.forEach(function (line: any) {
                        if (line.Type == "string")
                            html += "<div class='text'>" + line.Text + "</div>"
                        else if (line.Type == "table") {
                            html += "<div class='ql-align-center clear'>"
                            html += "<table class='mytable'>"

                            let arr = line.Text.split('|');
                            html += "<legend>" + arr[0] + "</legend>"
                            for (let r = 1; r < arr.length; r++) {

                                let td = r == 1 ? "th" : "td";
                                html += "<tr>"
                                let tds = arr[r].split(',');
                                tds.forEach(function (td_: any, ii: number) {
                                    td = ii == 0 ? "th" : "td";
                                    let td__ = td_.split('_');
                                    if (td__.length > 1) {
                                        html += "<" + td + " id='lineTd'><span style='float: left; margin-top: 10px;'>" + td__[0] +
                                            "</span><span style='float:right;margin-top:3px;'>" + td__[1] + "</span></" + td + ">";
                                    }
                                    else
                                        html += "<" + td + ">" + td_ + "</" + td + ">"
                                })


                                html += "</tr>"
                            }

                            html += "</table><div>&nbsp;</div></div>"
                        }
                        else {
                            html += "<div class='ql-align-center clear'>"

                            let arr = line.Text.split('|');
                            let img = arr[0].split(',');
                            let tip = arr[1].split(',');
                            html += ""
                            if (img.length == tip.length) {
                                img.forEach(function (img_: any, idx: number) {
                                    html += "<div class='img'><img src='" + img_ + "' />"
                                    html += "<div class='img-tip'>" + tip[idx] + "</div></div>"
                                })
                                html += "</div>"
                            }
                            else {
                                img.forEach(function (img_: any) {
                                    html += "<div class='img'><img src='" + img_ + "' /></div>"
                                })
                                html += "<div class='clear'>"
                                tip.forEach(function (tip_: any) {
                                    html += "<div class='img-tip'>" + tip_ + "</div>"
                                })
                                html += "</div></div>"
                            }
                        }
                    });
                });

                html += "</div><div>&nbsp;</div></div>"
            });
            html += "</div>"
        });

        html += "</div>"

        return html;
    }


    return <div className="mobile" style={{ zIndex: 520, width: "100%", minHeight: window.innerHeight }}>
        <div id="NewsCatetory" className="row">
            <div className="col-sm col-6">
                <div className="news-category" onClick={e => { attachTable(e) }}>
                    <i className="bi bi-grid"></i><br />
                    <span>气候影响评价</span>
                </div>
            </div>
            <div className="col-sm col-6">
                <div className="news-category" onClick={e => { attachTable(e) }}>
                    <i className="bi bi-grid-fill"></i><br />
                    <span>气候公报</span>
                </div>
            </div>
            <div className="col-sm col-6">
                <div className="news-category" onClick={e => { attachTable(e) }}>
                    <i className="bi bi-grid-3x2"></i><br />
                    <span>农气情报预报</span>
                </div>
            </div>
            <div className="col-sm col-6">
                <div className="news-category" onClick={e => { attachTable(e) }}>
                    <i className="bi bi-grid-3x2-gap"></i><br />
                    <span>灾害预警评估</span>
                </div>
            </div>
            <div className="col-sm col-6">
                <div className="news-category" onClick={e => { attachTable(e) }}>
                    <i className="bi bi-grid-3x2-gap-fill"></i><br />
                    <span>农用天气预报</span>
                </div>
            </div>
            <div className="col-sm col-6" onClick={e => { attachTable(e) }}>
                <div className="news-category">
                    <i className="bi bi-grid-3x3-gap"></i><br />
                    <span>关键农事服务</span>
                </div>
            </div>
            <div className="col-sm col-6">
                <div className="news-category" onClick={e => { attachTable(e) }}>
                    <i className="bi bi-grid-3x3-gap-fill"></i><br />
                    <span>农气干旱监测</span>
                </div>
            </div>
        </div>
        <div className="tab-content" role="tabpanel" id="NewsTitle0" style={{ display: 'none' }}>
            <div id="NewsTitle"></div>
            <input type='button' onClick={e => toggleCategoryDiv(e)} value="关闭" className='btn btn-primary' style={{ width: "98%", margin: 5 }} />
        </div>
        <div className="tab-content" role="tabpanel" id="NewsContent0" style={{ display: 'none' }}>
            <div id="NewsContent"></div>
            <input id="btnClose1" type='button' onClick={e => toggleContentDiv(e)} value="关闭" className='btn btn-primary' style={{ top: window.innerHeight - 50, zIndex: 521, position: "fixed", bottom: 0, height: 38 }} />
        </div>
    </div>

}