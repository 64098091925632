import { Component, createRef } from 'react';
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import leafletMap from "./comonent/leafletMap";
import SZWarnning from "./comonent/szWarnning";
import Enumerable from 'linq';
import StreetGrid from './comonent/streetGrid';
import StreetForecast from './comonent/streetForecasat';
import Toshenshan from "./comonent/toShenshan";
import DdatePicker from "./comonent/ddatePicker";
import { disaster2Plant, getStreetDisaster } from "./API/disarter";

//灾害预警
export default class warning extends Component {
    constructor(props:any) {
        super(props);
        this.forecastStartTime = new Date();
        this.forecastStartTime.setDate(this.forecastStartTime.getDate() - 1);
        this.forecastStartTime.setHours(20, 0, 0, 0);
    }
    private mapContent = createRef<HTMLDivElement>();
    private streetShowButton = createRef<HTMLButtonElement>();
    private fruitRadioInput = createRef<HTMLDivElement>();
    private cardDiv = createRef<HTMLDivElement>();
    private circleLayerGroup: L.FeatureGroup;
    private selectFruitCode = "";
    private streetList: iShenzhenStreet[];
    private selectWarnCode = "";
    private map: L.Map;
    private streetGrid: StreetGrid;
    private forecastStartTime: Date;

    private changeWarningType(type: string) {
        this.selectWarnCode = type;
        this.changeWarningRadio();
        this.drawWarnCircleInMap(this.streetList);
        this.streetGrid.changeWarnCode(type);
        let cdiv: HTMLDivElement[] = [];
        document.querySelectorAll("#commonWarnTd div").forEach((t: any) => { t.style.display = "none"; cdiv.push(t) });
        if (type == "cold") cdiv[0].style.display = "";
        else if (type == "hot") cdiv[1].style.display = "";
        else if (type == "wind") cdiv[2].style.display = "";

    }
    private changeWarningRadio() {
        document.querySelectorAll("#btnGroupRadio label").forEach((t: any) => { t.style.display = "none";  });
        document.querySelectorAll("#btnGroupRadio input").forEach((t: any) => { t.style.display = "none";  });

        var code_ = this.selectWarnCode == "" ? "cold" : this.selectWarnCode;
        disaster2Plant.forEach(item => {
            if (code_ == item.disaster) {
                item.plants.forEach(item1 => {
                    if (code_ == item.disaster) {
                        document.querySelectorAll("#btnGroupRadio input[value='" + item1 + "']").forEach((t: any) => {
                            t.style.display = "";
                        });
                        document.querySelectorAll("#btnGroupRadio label[data-value='" + item1 + "']").forEach((t: any) => {
                            t.style.display = "";
                        });
                    }
                })
            }
        })
    }
    private changeFruitCode(code: string) {
        this.selectFruitCode = code;
        this.drawWarnCircleInMap(this.streetList);
        this.streetGrid.changeFruitCode(code);
    }
    public componentDidMount() {
        var color = "FFFFFF"//b2c6be
        this.map = leafletMap(this.mapContent.current, false, 11, "bottomright", "bottomleft", color);
        this.streetShowButton.current.onclick = () => {
            this.popStreetForecast(0);
        }
        this.changeWarningRadio() 
        //const div = ReactClient.createRoot(document.body.appendElement("div"));
    }
    private streetListOnload(streetList: iShenzhenStreet[]) {
        this.streetList = streetList;
        this.fruitRadioInput.current.querySelectorAll("input[name='fruitRadios323']").forEach(input => {
            var frult = input as HTMLInputElement;
            if (frult.value == "") frult.checked = true;
            frult.onchange = (e) => {
                this.changeFruitCode((e.target as HTMLInputElement).value);
            }
        })
        this.showTitle();
        this.drawWarnCircleInMap(streetList);
    }
    private changePickerDate(date: Date) {
        this.forecastStartTime = date;
        this.streetGrid.changeDate(date);
    }
    private selectHistoryCase(date: string) {
        if (date === "") {
            this.forecastStartTime = new Date();
            this.forecastStartTime.setDate(this.forecastStartTime.getDate() - 1);
            this.forecastStartTime.setHours(20, 0, 0, 0);
        }
        else
            this.forecastStartTime = new Date(date);
        this.streetGrid.changeDate(this.forecastStartTime);
        //alert(this.forecastStartTime)
    }
    private popStreetForecast(stringId: number, latlng?: L.LatLng) {
        if (latlng) {
            let popcontent = document.createElement("div") as HTMLElement;
            L.popup({ keepInView: true, maxWidth: 1000, className: "customDarkPopup" }).setLatLng(latlng)
                .setContent(popcontent).openOn(this.map).on("remove", () => { (this.map as any).fixedPopwindow = false; });
            new StreetForecast(stringId, this.streetList).showContainer(popcontent, () => { this.map.closePopup() });
            this.map.setView(latlng)
        } else {
            new StreetForecast(stringId, this.streetList).showModal();
        }
    }

    private drawWarnCircleInMap(streetList?: iShenzhenStreet[]) {
        if (streetList) {
            if (!this.circleLayerGroup) {
                this.circleLayerGroup = L.featureGroup().addTo(this.map);
                this.circleLayerGroup.on("mouseover", (evt) => {
                    if (!(this.map as any).fixedPopwindow) {
                        var message = evt.layer.options.message;
                        L.popup({ offset: [0, -5] }).setLatLng(evt.latlng).setContent(message).openOn(this.map);
                    }
                }).on("click", (evt) => {
                    (this.map as any).fixedPopwindow = true;
                    var streetInfo = evt.layer.options.streetInfo;
                    this.popStreetForecast(streetInfo.ID, evt.latlng);
                });
            }
            this.circleLayerGroup.clearLayers();
            let areaRain: any = []
            for (let street of streetList) {
                for (let i = 0; i < 10; i++) {
                    let rain_ = street.Rain.slice(i * 24, (i + 1) * 24);
                    if (rain_.length > 0) {
                        let r24h = +Enumerable.from(rain_).sum().toFixed(1);
                        areaRain.push({ Area: street.Area, OBTID: street.OBTID, OBTName: street.Name, R24H: r24h, Day: i });
                    }
                }
            }
            let storm: any[] = [];
            for (let area of Enumerable.from(streetList).groupBy(t => t.Area)) {
                let arr: any[] = Enumerable.from(areaRain).where((p: any) => p.Area == area.key()).toArray();
                let totalSt = arr.length / 2
                let stormSt = 0;
                let stormDay = 0;
                for (let day of Enumerable.from(arr).groupBy((t: any) => t.Day).orderBy(d => d.key())) {
                    let stormDay_ = 0;
                    let arr1 = Enumerable.from(arr).where((p: any) => p.Day == day.key()).toArray();
                    for (let item of arr1) {
                        if (item.R24H >= 50) {
                            stormDay_ = 1;
                            if (item.Day == 0) stormSt++;
                        }
                    }
                    if (stormDay_ == 0) break
                    else stormDay += stormDay_
                }
                let RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
                RD = RD * 0.25;
                let RA = stormSt / totalSt
                RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
                RA = RA * 0.25;
                storm.push({ Area: area.key(), R: (RA + RD) / 2, Day: 0, StormDay: stormDay, StormSt: stormSt });


                for (let day of Enumerable.from(arr).where((p: any) => p.Day > 0).groupBy((t: any) => t.Day).orderBy(d => d.key())) {
                    let stormDay_ = 0;
                    let arr1 = Enumerable.from(arr).where((p: any) => p.Day == day.key()).toArray();
                    for (let item of arr1) {
                        if (item.R24H >= 50) {
                            stormDay_ = 1;
                            if (item.Day == 1) stormSt++;
                        }
                    }
                    if (stormDay_ == 0) break
                    else stormDay += stormDay_
                }
                RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
                RD = RD * 0.25;
                RA = stormSt / totalSt
                RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
                RA = RA * 0.25;
                storm.push({ Area: area.key(), R: (RA + RD) / 2, Day: 1, StormDay: stormDay, StormSt: stormSt });
            }

            for (var street of streetList) {
                let storm_ = Enumerable.from(storm).where((p: any) => p.Day == 0 && p.Area == street.Area).toArray();
                var warnings = getStreetDisaster(this.selectFruitCode, 48, street.Temprature.slice(0, 48), street.Wind, street.Rain.slice(0, 48), storm_,
                    street.TP0, street.TP1, street.AVGT_30, street.AVGT_0, street.AVGT_1);
                var normal = Enumerable.from(warnings).where(t => t.warnCode == "normal").toArray();
                var cold = Enumerable.from(warnings).where(t => t.warnCode == "cold").toArray();
                var hot = Enumerable.from(warnings).where(t => t.warnCode == "hot").toArray();
                var wind = Enumerable.from(warnings).where(t => t.warnCode == "wind").toArray();
                var coldwind = Enumerable.from(warnings).where(t => t.warnCode == "coldwind").toArray();
                var drought = Enumerable.from(warnings).where(t => t.warnCode == "drought").toArray();
                var stormrisk = Enumerable.from(warnings).where(t => t.warnCode == "storm").toArray();

                var color: string, message = street.Area + street.Name;
                if (!this.selectWarnCode) {
                    if (warnings.length == 0 || normal.length == warnings.length) {
                        color = "green"; message += "正常";
                    } else if (cold.length > 0) {
                        let item = Enumerable.from(cold).orderBy(t => t.forValue).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    } else if (hot.length > 0) {
                        let item = Enumerable.from(cold).orderByDescending(t => t.forValue).first();
                        color = item.color; message += "预测气温" + item.forValue + "℃<br/>" + item.fruitName + item.warnName;
                    } else if (wind.length > 0) {
                        let item = Enumerable.from(wind).orderByDescending(t => t.forValue).first();
                        color = item.color; message += "预测风力" + item.forValue + "m/s<br/>" + item.fruitName + item.warnName;
                    }
                    else if (coldwind.length > 0) {
                        let item = Enumerable.from(coldwind).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    }
                    else if (drought.length > 0) {
                        let item = Enumerable.from(drought).orderByDescending(t => t.forValue).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    }
                    else if (stormrisk.length > 0) {
                        let item = Enumerable.from(stormrisk).orderByDescending(t => t.forValue).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    }
                }
                else if (this.selectWarnCode == "cold") {
                    if (cold.length > 0) {
                        let item = Enumerable.from(cold).orderBy(t => t.forValue).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    } else {
                        color = "green"; message += "正常";
                    }
                } else if (this.selectWarnCode == "hot") {
                    if (hot.length > 0) {
                        let item = Enumerable.from(cold).orderByDescending(t => t.forValue).first();
                        color = item.color; message += "预测气温" + item.forValue + "℃<br/>" + item.fruitName + item.warnName;
                    } else {
                        color = "green"; message += "正常";
                    }
                } else if (this.selectWarnCode == "wind") {
                    if (wind.length > 0) {
                        let item = Enumerable.from(wind).orderByDescending(t => t.forValue).first();
                        color = item.color; message += "预测风力" + item.forValue + "m/s<br/>" + item.fruitName + item.warnName;
                    } else {
                        color = "green"; message += "正常";
                    }
                }
                else if (this.selectWarnCode == "coldwind") {
                    if (coldwind.length > 0) {
                        let item = Enumerable.from(coldwind).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    } else {
                        color = "green"; message += "正常";
                    }
                }
                else if (this.selectWarnCode == "drought") {
                    if (drought.length > 0) {
                        let item = Enumerable.from(drought).orderByDescending(t => t.forValue).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    } else {
                        color = "green"; message += "正常";
                    }
                }
                else if (this.selectWarnCode == "storm") {
                    if (stormrisk.length > 0) {
                        let item = Enumerable.from(stormrisk).orderByDescending(t => t.forValue).first();
                        color = item.color; message += item.warnMsg + item.warnName;
                    } else {
                        color = "green"; message += "正常";
                    }
                }
                if (color) {
                    let options = {
                        fill: true, color: "#000", fillColor: color,
                        weight: 1, fillOpacity: 1, radius: 10,
                        message: message, streetInfo: street
                    };
                    L.circleMarker(L.latLng(street.Latitude, street.Longitude), options).addTo(this.circleLayerGroup);
                }
            }
        } else if (this.circleLayerGroup) {
            this.map.closePopup();
            this.map.removeLayer(this.circleLayerGroup);
            delete this.circleLayerGroup;
        }
    }
    private swiftForm() {
        let carbody = this.cardDiv.current.querySelector(".card-body") as HTMLDivElement;
        let carheader = this.cardDiv.current.querySelector(".card-header") as HTMLDivElement;
        let dash = this.cardDiv.current.querySelector(".card-header .bi-dash") as HTMLSpanElement;
        let xbtn = this.cardDiv.current.querySelector(".bi.bi-x") as HTMLSpanElement;
        var open = () => {
            dash = this.cardDiv.current.querySelector(".card-header .bi-app") as HTMLSpanElement;
            carbody.style.display = "";
            dash.className = "bi bi-dash";
            xbtn.style.display = "";
            dash.style.display = "";
            this.cardDiv.current.style.width = "";
            carheader.onclick = function () { }
        }
        if (dash) {
            carbody.style.display = "none";
            dash.className = "bi bi-app";
            xbtn.style.display = "none";
            dash.style.display = "none";
            this.cardDiv.current.style.width = "100px";
            carheader.onclick = () => {
                open();
            }
        } else open();
    }

    private showTitle() {
        let awsTitleDiv = document.getElementById("awsTitleDiv");
        awsTitleDiv.style.display = "";
        while (awsTitleDiv.lastChild)
            awsTitleDiv.removeChild(awsTitleDiv.lastChild);

        let div = awsTitleDiv.appendElement("div", { "font-size": "15px" }).appendText("起报时间：" + this.forecastStartTime.toStr("yyyy年MM月dd日20时"));
        div.style.border = "1px solid green";
        div.style.padding = "4px";
        let close = div.appendElement("i", { color: "red" });
        close.className = "bi bi-x-lg";
        close.onclick = () => {
            awsTitleDiv.removeChild(div);
        }
    }

    public render() {
        return <>
            <SZWarnning stylePosition={{ position: "absolute", left: 6, top: 51 }} />
            <div id="awsTitleDiv" style={{ position: 'fixed', fontSize: 20, fontWeight: "bold", top: 52, left: window.innerWidth / 2 - 100, color: "#000", zIndex: 520 }}></div>

            <div ref={this.cardDiv} className="card" style={{ position: 'absolute', top: 45, right: 5, zIndex: 520, width: 605, background: "#ffffffb7", border: "1px solid #1164CB", color: "" }}>
                <div className="card-header" style={{ background: "#1164CB", color: "#fff" }}>
                    农业灾害街道预警
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => { this.cardDiv.current.style.display = "none" }}></span>
                    <span style={{ float: "right", fontSize: 18, margin: "0 10px" }} className='bi bi-dash' onClick={() => { this.swiftForm() }}></span></div>
                <div className="card-body">
                    起报时间：<DdatePicker startDate={this.forecastStartTime} onChange={this.changePickerDate.bind(this)} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select className="form-select" style={{ width: 170, padding: 4, display: "inline-block" }} onChange={(e) => { this.selectHistoryCase(e.target.value)}}>
                        <option value="">历史个例</option>
                        <option value="2024-11-14 20:00">2024-11-14 20:00</option>
                        <option value="2024-11-17 20:00">2024-11-17 20:00</option>
                    </select>
                    <div>
                        <h6 style={{ display: "none" }}>农业灾害类型:</h6>
                        &nbsp;
                        <label className="form-check-label">
                            <input className="form-check-input" style={{ verticalAlign: "initial" }} value="cold" type="radio" name="strningRaido" onChange={(e) => this.changeWarningType(e.target.value)} />&nbsp;
                            <i className="bi bi-thermometer-low" style={{ fontSize: 20 }}></i>
                            低温</label>&nbsp;&nbsp;
                        <label className="form-check-label">
                            <input className="form-check-input" style={{ verticalAlign: "initial" }} value="hot" type="radio" name="strningRaido" onChange={(e) => this.changeWarningType(e.target.value)} />&nbsp;
                            <i className="bi bi-fire" style={{ fontSize: 20 }}></i>
                            高温</label>&nbsp;&nbsp;
                        <label className="form-check-label">
                            <input className="form-check-input" style={{ verticalAlign: "initial" }} value="wind" type="radio" name="strningRaido" onChange={(e) => this.changeWarningType(e.target.value)} />&nbsp;
                            <i className="bi bi-tropical-storm" style={{ fontSize: 20 }}></i>
                            大风</label>
                        <label className="form-check-label">
                            <input className="form-check-input" style={{ verticalAlign: "initial" }} value="coldwind" type="radio" name="strningRaido" onChange={(e) => this.changeWarningType(e.target.value)} />&nbsp;
                            <i className="bi bi-wind" style={{ fontSize: 20 }}></i>
                            寒露风</label>
                        <label className="form-check-label">
                            <input className="form-check-input" style={{ verticalAlign: "initial" }} value="drought" type="radio" name="strningRaido" onChange={(e) => this.changeWarningType(e.target.value)} />&nbsp;
                            <i className="bi bi-brightness-high" style={{ fontSize: 20 }}></i>
                            干旱</label>
                        <label className="form-check-label">
                            <input className="form-check-input" style={{ verticalAlign: "initial" }} value="storm" type="radio" name="strningRaido" onChange={(e) => this.changeWarningType(e.target.value)} />&nbsp;
                            <i className="bi bi-cloud-rain-heavy-fill" style={{ fontSize: 20 }}></i>
                            暴雨</label>
                        <div id="commonWarnTd" style={{ fontSize: 13, zIndex: 520, padding: "5px 0 0 0", borderRadius: 5 }}>
                            <div style={{ display: 'none' }}>
                                <p style={{ margin: 0, display: "none" }}>低温冷害</p>
                                <ul style={{ marginBottom: 0 }}>
                                    <li>荔枝：0~3℃会受到不同程度的寒害，0℃以下荔枝生长停滞，<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-2℃是荔枝冻害的临界温度，-3~-4℃冻害致死温度</li>
                                    <li>水稻：粳稻10℃以下，籼稻12℃以下停止抽芽</li>
                                    <li>龙眼：0℃时幼苗受冻害，-1.0~-3.0时大树受冻害，-4.0℃时严重受冻。</li>
                                    <li>油菜：0℃以下叶片冻伤、植株死亡或不能正常开花授粉</li>
                                    <li style={{ display: 'none' }}>柑桔：-7℃以下出现枝条因冻于或植株死亡</li>
                                </ul></div>
                            <div style={{ display: 'none' }}><p style={{ margin: 0, display: "none" }}>高温热害</p>
                                <ul style={{ marginBottom: 0 }}>
                                    <li>水稻：日最高气温35℃作为危害水稻正常生长的临界温度</li>
                                    <li>龙眼：土温34℃为根系生长温度的最高点</li>
                                </ul></div>
                            <div style={{ display: 'none' }}> <p style={{ margin: 0, display: "none" }}>大风灾害</p>
                                <ul style={{ marginBottom: 0 }}>
                                    <li>阵风5-10级，轻度风灾</li>
                                    <li>阵风6-11级，中度风灾</li>
                                    <li>阵风8-12级以上，重度风灾</li>
                                </ul></div>
                        </div>
                    </div>
                    <hr style={{ margin: "10px auto" }} />
                    <div ref={this.fruitRadioInput} style={{ lineHeight:0 }}>
                        <h6 style={{ display: "none" }}>农作物选择:</h6>
                        <div id="btnGroupRadio" className="btn-group" role="group" style={{ margin: "0 10px" }} aria-label="Basic radio toggle button group">
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox1" value="lichee" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox1" data-value="lichee"><img src='/images/lichee.png' alt='荔枝' /> 荔枝</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox2" value="rice" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox2" data-value="rice"><img src='/images/rice.png' alt='水稻' /> 水稻</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox3" value="longan" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox3" data-value="longan"><img src='/images/longan.png' alt='龙眼' /> 龙眼</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox4" value="canola" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox4" data-value="canola"><img src='/images/canola.png' alt='油菜' /> 油菜</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox5" value="orange" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox5" data-value="orange"><img src='/images/orange.png' alt='柑桔' /> 柑桔</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox6" value="tomato" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox6" data-value="tomato"><img src='/images/tomato.png' alt='番茄' /> 番茄</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox7" value="cucumber" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox7" data-value="cucumber"><img src='/images/cucumber.png' alt='黄瓜' /> 黄瓜</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox8" value="sweetpepper" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox8" data-value="sweetpepper"><img src='/images/sweetpepper.png' alt='甜椒' /> 甜椒</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox9" value="vegetable" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox9" data-value="vegetable"><img src='/images/vegetable.png' alt='蔬菜' /> 蔬菜</label>

                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox10" value="greenhouse0" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox10" data-value="greenhouse0">塑料中棚、单体钢架大棚</label>
                            <input className="btn-check" style={{ display: "none" }} name='fruitRadios323' type="radio" id="inlineCheckbox11" value="greenhouse1" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox11" data-value="greenhouse1">连栋钢架大棚</label>
                            <input className="btn-check" style={{display: "none"}} name='fruitRadios323' type="radio" id="inlineCheckbox12" value="greenhouse2" />
                            <label style={{ color: "", borderColor: "", display: "none" }} className="btn btn-outline-primary" htmlFor="inlineCheckbox12" data-value="greenhouse2">玻璃温室</label>
                        </div>

                        <button ref={this.streetShowButton} style={{ borderRadius: 3, display: "none" }}>街道预报</button>
                    </div>
                    <StreetGrid ref={foo => this.streetGrid = foo}
                        foreDate={this.forecastStartTime}
                        oncallback={this.streetListOnload.bind(this)}
                        onChangeWarnCode={this.changeWarningType.bind(this)}
                        onChangeStreet={(id) => { this.popStreetForecast(id) }}
                    />
                </div>
            </div>
            <div ref={this.mapContent} style={{ height: window.innerHeight - 43 }}></div>

            <Toshenshan getMap={() => this.map} position={{ right: 80, bottom: 10 }} />
        </>
    }
}