import { Component, createRef } from 'react';
import { Tooltip } from 'react-tooltip'
import * as ReactDOM from 'react-dom';
import { Modal } from "bootstrap";
import Enumerable from 'linq';
import backendMethod from '../API/backend';
import { fruitDanger, getStreetDisaster } from "../API/disarter";

export default class fruitWarning extends Component<{
    mobile?: boolean,
    foreDate: Date,
    oncallback?: (streetList: iShenzhenStreet[]) => void,
    onChangeWarnCode?: (type: string) => void,
    onChangeStreet?: (streetId: number, streetList: iShenzhenStreet[]) => void}> {
    private gridDisasterTitle = createRef<HTMLHeadingElement>();
    private streetForecatGrid = createRef<HTMLTableSectionElement>();
    private forecastDatatimeTD1 = createRef<HTMLTableCellElement>();
    private forecastDatatimeTD2 = createRef<HTMLTableCellElement>();
    private selectFruitName = createRef<HTMLBaseElement>();
    private selectWarnName = createRef<HTMLBaseElement>();
    private warningTypeDiv = createRef<HTMLDivElement>();
    private cardDiv = createRef<HTMLDivElement>();
    private forecastStartTime: Date;
    private selectWarnCode = "";
    private selectFruitCode = "";
    private streetList: iShenzhenStreet[];

    public constructor(props: {
        mobile?: boolean,
        foreDate: Date,
        oncallback?: (streetList: iShenzhenStreet[]) => void,
        onChangeWarnCode?: (type: string) => void,
        onChangeStreet?: (streetId: number, streetList: iShenzhenStreet[]) => void}) {
        super(props);
        this.forecastStartTime = props.foreDate;
        if (this.forecastStartTime == null || this.forecastStartTime == undefined) {
            this.forecastStartTime = new Date();
            this.forecastStartTime.setDate(this.forecastStartTime.getDate() - 1);
            this.forecastStartTime.setHours(20, 0, 0, 0);
        }
    }
    public changeWarnCode(warn: string) {
        this.selectWarnCode = warn;
        this.showStreeForecastGrid(this.streetList);
    }
    public changeFruitCode(fruit: string) {
        this.selectFruitCode = fruit;
        this.showStreeForecastGrid(this.streetList);
    }
    public changeDate(date: Date) {
        this.forecastStartTime = date; 
        this.getStreetWarningInfo();
    }
    public componentDidMount() {
        this.getStreetWarningInfo();
    }
    private getStreetWarningInfo() {
        backendMethod.getStreetWarningInfo(this.forecastStartTime).then(streetList => {
            this.streetList = streetList;
            if (this.warningTypeDiv.current)
                this.warningTypeDiv.current.querySelectorAll("input[type='radio']").forEach(t => {
                    var input = t as HTMLInputElement;
                    if (input.value == this.selectWarnCode)
                        input.checked = true;
                    input.onchange = (e) => {
                        this.selectWarnCode = (e.target as HTMLInputElement).value;
                        this.showStreeForecastGrid(streetList);
                        if (this.props.onChangeWarnCode)
                            this.props.onChangeWarnCode(this.selectWarnCode);
                    }
                });
            this.showStreeForecastGrid(streetList);
            if (this.props.oncallback)
                this.props.oncallback(streetList);
        });
    }
    private showStreeForecastGrid(streetList: iShenzhenStreet[]) {
        this.gridDisasterTitle.current.innerHTML = "街道农业气象灾害预报";
        if (this.selectFruitCode) {
            
            var fruit = Enumerable.from(fruitDanger()).first(t => t.code == this.selectFruitCode);
            if (this.props.mobile)
                this.gridDisasterTitle.current.innerHTML += "【" + fruit.name + "】";
            else if (this.selectFruitName.current)
                this.selectFruitName.current.innerText = fruit.name + "    (灾害类型：" + this.WarnCode2Name(this.selectWarnCode) + "）";
        } else if (this.selectWarnCode) {
            if (this.selectFruitName.current)
                this.selectFruitName.current.innerText = "不限    (灾害类型：" + this.WarnCode2Name(this.selectWarnCode) + "）";
        }
        while (this.streetForecatGrid.current.lastChild)
            this.streetForecatGrid.current.removeChild(this.streetForecatGrid.current.lastChild);
        this.forecastDatatimeTD1.current.innerText = new Date(this.forecastStartTime.getTime() + 24 * 3600000).toStr("yy-MM-dd");
        this.forecastDatatimeTD2.current.innerText = new Date(this.forecastStartTime.getTime() + 48 * 3600000).toStr("yy-MM-dd");
        var addCircle = function (td: HTMLTableCellElement, color: string, text: string, message?: string) {
            var block = td.appendElement("div");
            block.style.display = "inline-block";
            var i1 = block.appendElement("i");
            i1.className = "bi bi-circle-fill";
            i1.style.color = color;
            block.appendText(text);
            if (message) {
                block.setAttribute("data-tooltip-id", "forecast_tooltip");
                block.setAttribute("data-tooltip-html", message);
                //block.setAttribute("data-tooltip-content", message);
            }
        }
        var fillTD = (td: HTMLTableCellElement, warnings: iFruitWarning[]) => {
            var normal = Enumerable.from(warnings).where(t => t.warnCode == "normal").toArray();
            var cold = Enumerable.from(warnings).where(t => t.warnCode == "cold").toArray();
            var hot = Enumerable.from(warnings).where(t => t.warnCode == "hot").toArray();
            var wind = Enumerable.from(warnings).where(t => t.warnCode == "wind").toArray();
            var coldwind = Enumerable.from(warnings).where(t => t.warnCode == "coldwind").toArray();
            var drought = Enumerable.from(warnings).where(t => t.warnCode == "drought").toArray();
            var storm = Enumerable.from(warnings).where(t => t.warnCode == "storm").toArray();

            if (this.selectWarnCode == "cold") {
                if (cold.length) {
                    let item = Enumerable.from(cold).orderBy(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, item.warnMsg + item.warnName);
                } else
                    addCircle(td, "green", "正常");
            }
            else if (this.selectWarnCode == "hot") {
                if (hot.length) {
                    let item = Enumerable.from(cold).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, "预测气温" + item.forValue + "℃<br/>" + item.fruitName + item.warnName);
                } else
                    addCircle(td, "green", "正常");
            }
            else if (this.selectWarnCode == "wind") {
                if (wind.length) {
                    let item = Enumerable.from(wind).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, "预测风力" + item.forValue + "m/s<br/>" + item.warnName);
                } else
                    addCircle(td, "green", "正常");
            }
            else if (this.selectWarnCode == "coldwind") {
                if (coldwind.length) {
                    let item = Enumerable.from(coldwind).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, item.warnMsg + item.warnName);
                } else
                    addCircle(td, "green", "正常");
            }
            else if (this.selectWarnCode == "drought") {
                if (drought.length) {
                    let item = Enumerable.from(drought).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, item.warnMsg + item.warnName);
                } else
                    addCircle(td, "green", "正常");
            }
            else if (this.selectWarnCode == "storm") {
                if (storm.length) {
                    let item = Enumerable.from(storm).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.warnName, item.warnMsg + item.warnName);
                } else
                    addCircle(td, "green", "正常");
            }
            else if (!this.selectWarnCode) {
                if (warnings.length == 0 || normal.length == warnings.length) {
                    addCircle(td, "green", "正常");
                }
                else if (cold.length) {
                    let item = Enumerable.from(cold).orderBy(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, item.warnMsg + item.warnName);
                }
                else if (hot.length) {
                    let item = Enumerable.from(hot).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, "预测气温" + item.forValue + "℃<br/>" + item.fruitName + item.warnName);
                }
                else if (wind.length) {
                    let item = Enumerable.from(wind).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, "预测风力" + item.forValue + "m/s<br/>" + item.warnName);
                }
                else if (coldwind.length) {
                    let item = Enumerable.from(coldwind).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, item.warnMsg + item.warnName);
                }
                else if (drought.length) {
                    let item = Enumerable.from(drought).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.fruitName + item.warnName, item.warnMsg + item.warnName);
                }
                else if (storm.length) {
                    let item = Enumerable.from(storm).orderByDescending(t => t.forValue).first();
                    addCircle(td, item.color, item.warnName, item.warnMsg + item.warnName);
                }
            }
        }

        let areaRain: any = []
        for (let street of streetList) {
            for (let i = 0; i < 10; i++) {
                let rain_ = street.Rain.slice(i * 24, (i + 1) * 24);
                if (rain_.length > 0) {
                    let r24h = +Enumerable.from(rain_).sum().toFixed(1);
                    areaRain.push({ Area: street.Area, OBTID: street.OBTID, OBTName: street.Name, R24H: r24h, Day: i });
                }
            }
        }
        let storm:any[]=[];
        for (let area of Enumerable.from(streetList).groupBy(t => t.Area)) {
            let arr: any[] = Enumerable.from(areaRain).where((p: any) => p.Area == area.key()).toArray();
            let totalSt = Enumerable.from(arr).select(p => p.OBTID).length
            let stormSt = 0;
            let stormDay = 0;
            for (let day of Enumerable.from(arr).groupBy((t: any) => t.Day).orderBy(d => d.key())) {
                let stormDay_ = 0;
                let arr1 = Enumerable.from(arr).where((p: any) => p.Day == day.key()).toArray();
                for (let item of arr1) {
                    if (item.R24H >= 50) {
                        stormDay_ = 1;
                        if (item.Day == 0) stormSt++;
                    }
                }
                if (stormDay_ == 0) break
                else stormDay += stormDay_
            }
            let RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
            RD = RD * 0.25;
            let RA = stormSt / totalSt
            RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
            RA = RA * 0.25;
            storm.push({ Area: area.key(), R: (RA + RD) / 2, Day: 0, StormDay: stormDay, StormSt: stormSt });


            for (let day of Enumerable.from(arr).where((p: any) => p.Day > 0).groupBy((t: any) => t.Day).orderBy(d => d.key())) {
                let stormDay_ = 0;
                let arr1 = Enumerable.from(arr).where((p: any) => p.Day == day.key()).toArray();
                for (let item of arr1) {
                    if (item.R24H >= 50) {
                        stormDay_ = 1;
                        if (item.Day == 1) stormSt++;
                    }
                }
                if (stormDay_ == 0) break
                else stormDay += stormDay_
            }
            RD = stormDay >= 8 ? 4 : stormDay >= 5 ? 3 : stormDay >= 3 ? 2 : stormDay >= 1 ? 1 : 0;
            RD = RD * 0.25;
            RA = stormSt / totalSt
            RA = RA >= 0.6 ? 4 : RA >= 0.3 ? 3 : RA >= 0.1 ? 2 : RA >= 0.01 ? 1 : 0;
            RA = RA * 0.25;
            storm.push({ Area: area.key(), R: (RA + RD) / 2, Day: 1, StormDay: stormDay, StormSt: stormSt });
        }

        for (var area of Enumerable.from(streetList).groupBy(t => t.Area)) {
            for (var street of area) {
                var row = this.streetForecatGrid.current.appendElement("tr");
                row.style.cursor = "pointer";
                if (this.props.onChangeStreet) ((id: number) => {
                    row.onclick = (e) => {
                        this.props.onChangeStreet(id, this.streetList);
                    }
                })(street.ID);
                row.appendTd().appendText(street.Name);
                row.appendTd().appendText(street.OBTName);
                if (!this.props.mobile) row.appendTd().appendText(street.OBTID);
                var td1 = row.appendTd();
                let storm_ = Enumerable.from(storm).where((p: any) => p.Day == 0 && p.Area == street.Area).toArray();
                var day1Warning = getStreetDisaster(this.selectFruitCode, 24, street.Temprature.slice(0), street.Wind.slice(0, 24), street.Rain.slice(0), storm_,
                    street.TP0, street.TP1, street.AVGT_30, street.AVGT_0, street.AVGT_1);
                fillTD(td1, day1Warning);

                var td2 = row.appendTd();
                storm_ = Enumerable.from(storm).where((p: any) => p.Day == 1 && p.Area == street.Area).toArray();
                var day2Warning = getStreetDisaster(this.selectFruitCode, 24, street.Temprature.slice(24), street.Wind.slice(24), street.Rain.slice(24), storm_,
                    street.TP0, street.TP1, street.AVGT_30, street.AVGT_0, street.AVGT_1);
                fillTD(td2, day2Warning);
            }
        }

    }
    private WarnCode2Name(type: string) {
        return type == "cold" ? "低温冷害" : type == "hot" ? "高温热害" : type == "wind" ? "大风灾害" : type == "coldwind" ? "寒露风" : type == "drought" ? "干旱灾害" : type == "storm" ? "暴雨灾害" : "不限";
    }
    private swiftForm() {
        let cardbody = this.cardDiv.current.querySelector(".card-body") as HTMLDivElement;
        let cardfooter = this.cardDiv.current.querySelector(".card-footer") as HTMLDivElement;
        let dash = this.cardDiv.current.querySelector(".card-header .bi-dash") as HTMLSpanElement;
        if (dash) {
            cardfooter.style.display = cardbody.style.display = "none";
            dash.className = "bi bi-app";
        } else {
            dash = this.cardDiv.current.querySelector(".card-header .bi-app") as HTMLSpanElement;
            cardfooter.style.display = cardbody.style.display = "";
            dash.className = "bi bi-dash";
        }
    }

    public render() {
        if (this.props.mobile) {
            return <>
                <div className="card" ref={this.cardDiv} style={{ position: "absolute", width: "100%", zIndex: 520, top: 35, height: window.innerHeight - 36, color: "#fff" }}>
                    <div className="card-header" style={{ padding: 5, border: "none", backgroundColor: "#144373" }}>
                        <h6 className="modal-title" ref={this.gridDisasterTitle}>街道农业气象灾害预报</h6>
                    </div>
                    <div className="card-body" style={{ background: "#132A49", padding: 10, overflow: "scroll", overflowX: "hidden" }}>

                        <div ref={this.warningTypeDiv} style={{}}>
                            要素选择:&nbsp;
                            <label className="form-check-label" >
                                <input className="form-check-input" style={{ verticalAlign: "initial" }} value="cold" type="radio" name="streetWarningRaido" />&nbsp;
                                低温</label>&nbsp;
                            <label className="form-check-label">
                                <input className="form-check-input" style={{ verticalAlign: "initial" }} value="hot" type="radio" name="streetWarningRaido" />&nbsp;
                                高温</label>&nbsp;
                            <label className="form-check-label">
                                <input className="form-check-input" style={{ verticalAlign: "initial" }} value="wind" type="radio" name="streetWarningRaido" />&nbsp;
                                大风</label>&nbsp;
                            <label className="form-check-label">
                                <input className="form-check-input" style={{ verticalAlign: "initial" }} value="coldwind" type="radio" name="streetWarningRaido" />&nbsp;
                                寒露风</label>&nbsp;
                            <label className="form-check-label">
                                <input className="form-check-input" style={{ verticalAlign: "initial" }} value="drought" type="radio" name="streetWarningRaido" />&nbsp;
                                干旱</label>&nbsp;
                            <label className="form-check-label">
                                <input className="form-check-input" style={{ verticalAlign: "initial" }} value="storm" type="radio" name="streetWarningRaido" />&nbsp;
                                暴雨</label>
                        </div>
                        <table style={{ margin: "5px 0" }} className='table table-dark table-sm table-striped table-hover table-bordered'>
                            <thead>
                                <tr><
                                    td style={{ width: 60 }}>街道</td>
                                    <td style={{ minWidth: 100 }}>站名</td>
                                    <td style={{ width: 60, display: this.props.mobile ? "none" : "" }}>站号</td>
                                    <td style={{ width: 80, display: this.props.mobile ? "none" : "" }}>类别</td>
                                    <td style={{}} ref={this.forecastDatatimeTD1}></td>
                                    <td style={{}} ref={this.forecastDatatimeTD2}></td>
                                </tr>
                            </thead>
                            <tbody ref={this.streetForecatGrid}>
                            </tbody>
                            <tbody style={{ display: '' }}>
                                <tr style={{ fontSize: 12 }}><td colSpan={10}>

                                </td></tr>
                            </tbody>
                        </table>
                        <Tooltip id="forecast_tooltip" style={{ backgroundColor: "#FAE6B1", color: "#000", width: "200px" }} />
                    </div>
                    <div className="card-footer" style={{ backgroundColor: "#144373", fontSize: 12, margin: 0, border: "none", padding: 5 }}>
                        <button type="button" style={{ width: "98%", margin: 5 }} onClick={() => this.cardDiv.current.style.display = "none"} className="btn btn-primary btn-sm">关闭</button>
                    </div>
                </div ></>
        }
        else return <>
            <div className="card" ref={this.cardDiv} style={{ maxHeight: window.innerHeight - 402, color: "", background: "#ffffffb7", margin: "10px 0" }}>
                <div className="card-header" style={{ display: 'none', padding: 5, border: "none", backgroundColor: "#144373" }}>
                    <span className="modal-title" style={{}} ref={this.gridDisasterTitle}>各街道农业气象灾害预报</span>
                    <span style={{ float: "right", fontSize: 18 }} className='bi bi-x' onClick={() => { this.cardDiv.current.style.display = "none" }}></span>
                    <span style={{ float: "right", fontSize: 18, margin: "0 5px" }} className='bi bi-dash' onClick={() => { this.swiftForm() }}></span>
                </div>
                <div className="card-body" style={{ background: "", padding: 0, overflow: "scroll", overflowX: "hidden" }}>
                    <div style={{ margin: "5px" }}> 作物选择：<b ref={this.selectFruitName}>不限</b>&nbsp;&nbsp;</div>
                    <table style={{ margin: "5px 0" }} className='table table-sm table-hover table-bordered'>
                        <thead style={{ color: "" }}>
                            <tr>
                                <td style={{ width: 60 }}>街道</td>
                                <td style={{ width: 110 }}>站名</td>
                                <td style={{ width: 60 }}>站号</td>
                                <td style={{}} ref={this.forecastDatatimeTD1}></td>
                                <td style={{}} ref={this.forecastDatatimeTD2}></td>
                            </tr>
                        </thead>
                        <tbody ref={this.streetForecatGrid} style={{ color: "" }}></tbody>
                        <tbody style={{ display: '' }}>
                            <tr style={{ fontSize: 12 }}><td colSpan={10}>

                            </td></tr>
                        </tbody>
                    </table>
                    <Tooltip id="forecast_tooltip" style={{ backgroundColor: "#FAE6B1", color: "#000", width: "200px" }} />
                </div>
            </div ></>
    }
}