import React from 'react';
import ReactDOM from 'react-dom/client';
import Headbar from "./comonent/headbar";
import Express from "./express";
import ExpressAdmin from "./expressEdit";
import Warning from "./warning";
import Forecast from "./forecast";
import HomeMap from "./home";
import MobileHome from "./mobile/home-mobile";
import NavLeft from "./comonent/navLeft";
import NavTop from "./comonent/navTop";
import News from "./news";
import NewsAdmin from "./newsEdit";
import Contacts from "./contacts";
import "./util/objExtend";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
if (document.location.pathname === "/mobile" )
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path='/mobile' Component={MobileHome}></Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
else if (document.location.pathname === "/phone")
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path='/phone' Component={MobileHome}></Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
else
    root.render(
        <React.StrictMode>
            <table style={{ width: "100%" }}><tbody><tr><td style={{ padding: 0, verticalAlign: "top" }}>
                <NavTop />
                <BrowserRouter>
                    <Routes>
                        <Route path='/' Component={HomeMap}></Route>
                        <Route path='/page/forecast' Component={Forecast}></Route>
                        <Route path='/page/warning' Component={Warning}></Route>
                        <Route path='/page/express' Component={Express}></Route>
                        <Route path='/page/expressEdit' Component={ExpressAdmin}></Route>
                        <Route path='/page/news' Component={News}></Route>
                        <Route path='/page/newsEdit' Component={NewsAdmin}></Route>
                        <Route path='/page/contacts' Component={Contacts}></Route>
                    </Routes>
                </BrowserRouter>
            </td>
            </tr></tbody></table>
        </React.StrictMode>
    );
